import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { SEARCH_TYPES } from '../../constants';
import { SavedSearchesContainer } from '../../containers/SavedSearches';
import { SearchBoxContainer } from '../../containers/SearchBox.container';
import { getVisibleSavedSearches } from '../../store/selectors';

const useStyles = makeStyles(theme => ({
    searchTypeContentContainer: {
        padding: theme.spacing(1.5),
        minHeight: 'var(--searchTypeContentHeight)',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
}));

const SearchTypeContent = ({ scrollableContainerRef, setSearchTypeContentHeight, selectedSearchTypeId }) => {
    const classes = useStyles();

    const savedSearches = useSelector(getVisibleSavedSearches) || [];
    const searchTypeContentRef = useRef();

    const searchTypeContentSwitcher = () => {
        switch (selectedSearchTypeId) {
            case SEARCH_TYPES.savedSearches.id:
                return <SavedSearchesContainer scrollableContainerRef={scrollableContainerRef} />;
            default:
                return <SearchBoxContainer scrollableContainerRef={scrollableContainerRef} />;
        }
    };

    // In case of window resize, the height of SearchResult have to be changed.
    window.addEventListener('resize', () => {
        clearTimeout(window.resizedFinished);
        window.resizedFinished = setTimeout(() => {
            setSearchTypeContentHeight(window.innerHeight - 550); // 550 is the minimum height of app
        }, 250);
    });

    // Opening the saved searches panel, the height will change. Therefore we have to update.
    useEffect(() => {
        if (searchTypeContentRef.current) {
            setSearchTypeContentHeight(searchTypeContentRef.current.clientHeight);
        }
    }, [savedSearches.length, selectedSearchTypeId, setSearchTypeContentHeight]);

    return (
        <Box
            ref={searchTypeContentRef}
            alignItems="center"
            className={classes.searchTypeContentContainer}
            data-testid="searchTypeContentContainer"
            display="flex"
        >
            {searchTypeContentSwitcher()}
        </Box>
    );
};

SearchTypeContent.propTypes = {
    scrollableContainerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    selectedSearchTypeId: PropTypes.number.isRequired,
    setSearchTypeContentHeight: PropTypes.func,
};

SearchTypeContent.defaultProps = {
    scrollableContainerRef: () => null,
    setSearchTypeContentHeight: () => null,
};

export { SearchTypeContent };
