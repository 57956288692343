import color from 'color';

export const rgba2rgb = (background, second, alpha) => {
    const bg = color(background).rgb().array();
    const rgb = color(second).rgb().array();
    return color
        .rgb(
            (1 - alpha) * bg[0] + alpha * rgb[0],
            (1 - alpha) * bg[1] + alpha * rgb[1],
            (1 - alpha) * bg[2] + alpha * rgb[2]
        )
        .string();
};

export const makeColors = baseColor =>
    [0.02, 0.05, 0.08, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.5, 0.6, 0.7, 0.75, 0.8, 0.85, 0.9].reduce(
        (acc, o) => ({
            ...acc,
            [o]: color(baseColor)
                .fade(1 - o)
                .rgb()
                .string(),
        }),
        { 1: baseColor }
    );
