import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '@mediabank/assets/icons/close-tab.svg';
import PropTypes from 'prop-types';

import ScrollableContainer from '../ScrollableContainer';
import { useOverlayPanelStyles } from './styles';

const OverlayPanel = ({ style, title, scrollBarPos, onClickClose, children, active }) => {
    const classes = useOverlayPanelStyles({ active });

    return (
        active && (
            <Box className={classes.root} pt={2.5} px={2.5} style={{ ...style }}>
                <Grid alignItems="center" container={true} justify="space-between">
                    <Grid item={true}>
                        <Typography className={classes.title} variant="h5">
                            {title}
                        </Typography>
                    </Grid>
                    {onClickClose && (
                        <Grid item={true}>
                            <CloseIcon
                                className={classes.icon}
                                data-cy="closeIcon"
                                height={20}
                                width={16}
                                onClick={onClickClose}
                            />
                        </Grid>
                    )}
                </Grid>
                <ScrollableContainer scrollBarPos={scrollBarPos}>{children}</ScrollableContainer>
            </Box>
        )
    );
};

OverlayPanel.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    scrollBarPos: PropTypes.number,
    onClickClose: PropTypes.func,
    active: PropTypes.bool.isRequired,
};

OverlayPanel.defaultProps = {
    style: {},
    title: '',
    scrollBarPos: 0,
    onClickClose: null,
};

export default OverlayPanel;
