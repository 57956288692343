import axios from 'axios';
import errorResponseInterceptor from '../interceptors/errorResponseInterceptor';

export const contentTypeList = {
    form: 'application/x-www-form-urlencoded',
    json: 'application/json',
};

class httpClient {
    constructor(baseURL, timeout) {
        this.http = this.createInstance(baseURL, timeout);
        this.contentTypeList = contentTypeList;
        this.http.interceptors.response.use(res => res, errorResponseInterceptor);
    }

    createInstance(baseURL, timeout) {
        return axios.create({
            baseURL: baseURL,
            timeout: timeout,
            validateStatus: status => status >= 200 && status < 300,
        });
    }

    headerBuilder({ contentType, ...rest } = {}) {
        const headers = {
            'Content-Type': contentType || this.contentTypeList.json,
            ...rest,
        };
        return headers;
    }

    get(path, { urlParams, headers, auth }) {
        return this.http.get(path, { params: urlParams, headers: this.headerBuilder(headers), auth: auth });
    }

    post(path, { data, urlParams, headers, auth }) {
        return this.http.post(path, data, { params: urlParams, headers: this.headerBuilder(headers), auth: auth });
    }

    patch(path, { data, urlParams, headers, auth }) {
        return this.http.patch(path, data, { params: urlParams, headers: this.headerBuilder(headers), auth: auth });
    }

    put(path, { data, urlParams, headers, auth }) {
        return this.http.put(path, data, { params: urlParams, headers: this.headerBuilder(headers), auth: auth });
    }

    delete(path, { urlParams, headers, auth }) {
        return this.http.delete(path, { params: urlParams, headers: this.headerBuilder(headers), auth: auth });
    }
}

export default httpClient;
