import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';

import { No_Assets_Header } from '../../messages';

const useStyles = makeStyles(theme => ({
    noAssetsFoundContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: theme.spacing(10),
    },
    paper: {
        backgroundColor: theme.custom.accentDark[0.3],
        borderRadius: theme.spacing(0.75),
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    title: {
        marginBottom: theme.spacing(3),
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '3rem',
    },
    info: {
        color: theme.custom.accentLight[0.8],
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
}));

const NoAssetsFound = () => {
    const classes = useStyles();

    const activeSearchType = useSelector(state => state.ui.searchAssets.activeSearchType);
    const savedSearches = useSelector(state => state.config.savedSearches);
    const searchTypes = useSelector(state => state.ui.searchAssets.searchTypes) || {};
    const quickFilters = useSelector(state => state.config.quickFilters);

    const notFoundText = () => {
        if (activeSearchType === 'quickFilter') {
            return quickFilters.find(quickFilter => quickFilter.id === searchTypes[activeSearchType])?.attributes
                ?.smartsearchname;
        }
        if (activeSearchType === 'savedSearch') {
            return savedSearches.find(savedSearch => savedSearch.smartsearchid === searchTypes[activeSearchType])?.Name;
        }

        return searchTypes[activeSearchType];
    };

    return (
        <Box className={classes.noAssetsFoundContainer}>
            <Box className={classes.paper}>
                <Typography className={classes.title} component="h2" data-testid="NoAssetsFound__header" variant="h1">
                    {No_Assets_Header} "{notFoundText()}"
                </Typography>
                <Typography className={classes.info} component="p" data-testid="NoAssetsFound__info">
                    Please make sure your words are spelled correctly <br />
                    or use less or different keywords.
                </Typography>
            </Box>
        </Box>
    );
};

export { NoAssetsFound };
