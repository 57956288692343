import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import PropTypes from 'prop-types';

/**
 * Just a good ol' button.
 * Accepts all props that an [MuiButton](https://material-ui.com/api/button/) would accept.
 *
 * The styling of this component is overriden in the theme.
 */
const Button = props => <MuiButton {...props} />;

Button.defaultProps = {
    variant: 'contained',
    color: 'default',
    size: 'medium',
    disabled: false,
    onClick: () => {},
};

Button.propTypes = {
    variant: PropTypes.oneOf(['contained', 'text']),
    color: PropTypes.oneOf(['primary', 'default']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Button;
