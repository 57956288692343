/**
 * Define constants here
 */

import { getCurrentApiVersion, getExtensionVersion } from '../utils/cep';

// used for Sentry error reporting & the footer of the login screen
// EXAMPLE: "1.1.0-528"
export const APP_VERSION = (() => {
    const FALLBACK = '[dev]';

    return `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_GITHUB_RUN_NUMBER || FALLBACK}`;
})();

export const CEP_API_VERSION = (() => {
    // render a fallback when running the app outside of Adobe Premiere,
    // i.e. local development in Chrome
    const FALLBACK = '[dev]';

    const currentApiVersionObj = getCurrentApiVersion();

    if (!currentApiVersionObj || typeof currentApiVersionObj !== 'object') {
        return FALLBACK;
    }

    const {
        // in 2021, our goal is to only support
        // CEP versions 10+
        major,

        minor,
        micro,
    } = currentApiVersionObj;

    // EXAMPLE: 10.1.0
    return `${major}.${minor}.${micro}`;
})();

export const CEP_EXT_VERSION = (() => {
    // render a fallback when running the app outside of Adobe Premiere,
    // i.e. local development in Chrome
    const FALLBACK = '[dev]';

    return getExtensionVersion() || FALLBACK;
})();

// when the user loads this app on a version of `premiere-extension`
// that is not 2.x or later, or is not on Chrome, we'll use this to show a toast
export const isSupportedExtensionVersion = CEP_EXT_VERSION === '[dev]' || !CEP_EXT_VERSION.startsWith('1');

// whether to apply the 'x-api-dev' request header when making a POST to the JWT endpoint
export const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true';

// endpoints / URLs
export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
export const SSO_VENDORS_URL = process.env.REACT_APP_SSO_VENDORS_URL || 'https://sso.mediabank.me/vendors';

// the "/ajax/...php" endpoint used by `api.fetchLegacy`
export const LEGACY_MEDIABANK_ENDPOINT = process.env.REACT_APP_LEGACY_MEDIABANK_ENDPOINT;

export const MAP_ENDPOINT = process.env.REACT_APP_MAP_ENDPOINT;
export const PARTNER_LOGIN_URL =
    process.env.REACT_APP_PARTNER_LOGIN_URL || 'https://sso.mediabank.me?vendor={VENDOR}&redirect_to={ORIGIN}/callback';

// Sentry error monitoring
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// TODO: the Sentry environment should be based on a URL or expression
// https://mbnepms.atlassian.net/browse/MBX-191
export const SENTRY_ENVIRONMENT = process.env.NODE_ENV;

export const SENTRY_IS_ACTIVE = !DEV_MODE || process.env.REACT_APP_SENTRY_IS_ACTIVE === 'true';
export const SENTRY_RELEASE = `mediabank-premiere@${APP_VERSION}`;

// if our JWT expiry falls below this number,
// we'll call the refresh endpoint
export const TOKEN_REFRESH_BUFFER_IN_MINUTES = 30;

// `localStorage` key used by the JWT util
export const TOKEN_STORAGE_KEY = 'MEDIABANK_TOKEN';

export const DEFAULT_SEARCH_PARAMS = {
    application: 'library',
    page: 1,
    limit: 20,
    keyword: '',
};

export const SEARCH_TYPES = {
    keywordSearch: {
        id: 1,
        text: 'Keyword search',
    },
    savedSearches: {
        id: 3,
        text: 'Saved searches',
    },
};

export const TABLE_CONFIG = [
    {
        key: 'Title',
        label: 'Title',
    },
    {
        key: 'SubTitle',
        label: 'SubTitle',
    },
    {
        key: 'ContentType',
        label: 'Content Type',
    },
    {
        key: 'VideoFrameRate',
        label: 'FrameRate',
    },
    {
        key: 'Id',
        label: 'Asset ID',
    },
];

export const TABLE_DISPLAY_TYPES = {
    thumbnail: 'thumbnail',
    row: 'row',
};
