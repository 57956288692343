import React from 'react';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { makeStyles, NoSsr } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
    SelectClearIndicator as ClearIndicator,
    SelectControl as Control,
    SelectDropdownIndicator as DropdownIndicator,
    SelectInput as Input,
    SelectLabel as Label,
    SelectLoadingIndicator as LoadingIndicator,
    SelectMenu as Menu,
    SelectNoOptionsMessage as NoOptionsMessage,
    SelectOption as Option,
    SelectPlaceholder as Placeholder,
    SelectSingleValue as SingleValue,
    SelectValueContainer as ValueContainer,
} from './components';
import { useCustomSelectStyles } from './useCustomSelectStyles';

const findValueObj = (options, value) =>
    options.find(o => o.value === value) || (value ? { value, label: value } : null);

const createComponentOverrides = customComponents => ({
    NoOptionsMessage,
    Placeholder,
    SingleValue,
    ClearIndicator,
    LoadingIndicator,
    IndicatorSeparator: () => <></>,
    Input,
    ...customComponents,
});

const createMenuList = ({ menuListBottomContent }) => ({ ...props }) => (
    <components.MenuList {...props}>
        {
            // eslint-disable-next-line react/prop-types
            props.children
        }
        {menuListBottomContent}
    </components.MenuList>
);

const SelectComponent = React.forwardRef(({ creatable, ...props }, ref) =>
    creatable ? <CreatableSelect ref={ref} {...props} /> : <ReactSelect ref={ref} {...props} />
);
SelectComponent.defaultProps = { creatable: false };
SelectComponent.propTypes = { creatable: PropTypes.bool };

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

const Select = React.forwardRef(
    (
        {
            disableOverrides,
            error,
            isDisabled,
            isMulti,
            isSearchable,
            isShowingDropDown,
            label,
            maxInputWidth,
            menuListBottomContent,
            name,
            onChange,
            onMenuOpen,
            options,
            placeholder,
            value,
            ...props
        },
        ref
    ) => {
        const customSelectStyles = useCustomSelectStyles();

        const handleMultipleValueChange = option => {
            onChange(option ? option.map(o => o.value) : []);
        };

        const handleSingleValueChange = option => {
            onChange(option ? option.value : '');
        };

        const currentValue = isMulti ? (value || []).map(v => findValueObj(options, v)) : findValueObj(options, value);

        // When `disableOverrides` is set to true that means we want to stick to the
        // default functional behavior of the react-select component
        // therefore we don't want to override the components which provide
        // the arrow key navigation and select with enter
        const componentOverrides = disableOverrides
            ? createComponentOverrides({
                  Control: props => <Control error={error} {...props} />,
                  Menu,
                  MenuList: createMenuList({ menuListBottomContent }),
                  DropdownIndicator: isShowingDropDown ? DropdownIndicator : null,
                  ValueContainer,
              })
            : createComponentOverrides({
                  Control: props => <Control error={error} {...props} />,
                  Menu,
                  MenuList: createMenuList({ menuListBottomContent }),
                  DropdownIndicator: isShowingDropDown ? DropdownIndicator : null,
                  Option: props => <Option {...props} />,
                  ValueContainer,
              });

        const searchableProps = isSearchable ? { isClearable: true } : {};

        const classes = useStyles();

        return (
            <NoSsr>
                {label && (
                    <Label disabled={isDisabled} htmlFor={name}>
                        {label}
                    </Label>
                )}
                <SelectComponent
                    ref={ref}
                    className={customSelectStyles.container}
                    controlShouldRenderValue={isMulti ? false : true}
                    {...props}
                    {...{
                        name,
                        classes,
                        isSearchable,
                        isMulti,
                        isDisabled,
                        maxInputWidth,
                        error,
                        options,
                        placeholder,
                    }}
                    {...searchableProps}
                    classNamePrefix={'customSelectStyles'}
                    closeMenuOnSelect={!isMulti}
                    components={componentOverrides}
                    data-test="Select"
                    inputId={name}
                    maxMenuHeight={200}
                    value={currentValue ? currentValue : isMulti ? [] : null}
                    onChange={isMulti ? handleMultipleValueChange : handleSingleValueChange}
                    onMenuOpen={onMenuOpen}
                />
            </NoSsr>
        );
    }
);

Select.defaultProps = {
    disableOverrides: false,
    error: null,
    isDisabled: false,
    isMulti: false,
    isSearchable: false,
    isShowingDropDown: true,
    label: undefined,
    maxInputWidth: undefined,
    menuListBottomContent: undefined,
    name: '',
    onChange: () => null,
    onMenuOpen: () => null,
    placeholder: '',
    value: '',
};

Select.propTypes = {
    disableOverrides: PropTypes.bool,
    error: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isShowingDropDown: PropTypes.bool,
    label: PropTypes.string,
    maxInputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    menuListBottomContent: PropTypes.node,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onMenuOpen: PropTypes.func,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
};

export default Select;
