import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { AssetThumbnail, makeStyles } from '@mediabank/uikit';
import cx from 'clsx';
import PropTypes from 'prop-types';

export const useStyles = makeStyles(theme => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(22)}px, 1fr));`,
        gridTemplateRows: `repeat(3, minmax(${theme.spacing(26.5)}px, auto))`,
        gridGap: `${theme.spacing(2)}px`,
        paddingTop: theme.spacing(1.5),
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.background.default}`,
        borderRadius: theme.spacing(0.75),
        cursor: 'pointer',
        backgroundColor: theme.custom.accentDark[0.3],
    },
    metadataContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: theme.spacing(0.75),
        borderRadius: theme.spacing(0, 0, 0.75, 0.75),
    },
    metaDataTitle: {
        wordBreak: 'break-word',
    },
    metadataItem: {
        display: 'inline-block',
        marginBottom: theme.spacing(0.5),
    },
    selected: {
        border: `1px solid ${theme.custom.accentBright[1]}`,
    },
}));

const SearchResultListGrid = ({ importSelectedAssets, toggleSelectedAsset }) => {
    const classes = useStyles();

    const assets = useSelector(state => state.assetList.assets);
    const selectedAssetsById = useSelector(state => state.assetList.selectedAssetsById);

    return (
        <Box className={classes.gridContainer} data-testid="SearchResultListGrid">
            {assets.map(({ attributes }, idx) => {
                const { assetid: assetId, assetmeta: assetMeta, map_data } = attributes;

                const isAssetSelected = !!selectedAssetsById[assetId];

                return (
                    <Box
                        key={`${assetId}-${idx}`}
                        className={cx([classes.gridItem, isAssetSelected && classes.selected])}
                        draggable="true"
                        onClick={() => toggleSelectedAsset(assetId)}
                        onDragEnd={() => importSelectedAssets(assetId)}
                    >
                        <AssetThumbnail
                            containerProps={{
                                style: { borderTopLeftRadius: 6, borderTopRightRadius: 6, overflow: 'hidden' },
                            }}
                            imgSrc={assetMeta.Image || ''}
                            mimeType={assetMeta.MimeType}
                            mimeTypeFull={assetMeta.MimeTypeFull}
                            title={assetMeta.Title}
                        />
                        <Box className={classes.metadataContainer} data-testid="metadataContainer">
                            <Typography className={cx([classes.metadataItem, classes.metaDataTitle])} variant="body2">
                                {assetMeta.Title}
                            </Typography>
                            <Typography
                                className={classes.metadataItem}
                                noWrap={true}
                                style={{ opacity: 0.4 }}
                                variant="body2"
                            >
                                {assetMeta.SubTitle}
                            </Typography>
                            <Typography
                                className={classes.metadataItem}
                                noWrap={true}
                                style={{ opacity: 0.4 }}
                                variant="body2"
                            >
                                {assetMeta.ContentType}
                            </Typography>
                            <Typography
                                className={classes.metadataItem}
                                noWrap={true}
                                style={{ opacity: 0.4 }}
                                variant="body2"
                            >
                                {assetMeta.VideoFrameRate}
                            </Typography>
                            <Box display="flex" justifyContent="space-between">
                                <Typography noWrap={true} style={{ fontWeight: 500, opacity: 0.7 }} variant="body2">
                                    {assetMeta.Id}
                                </Typography>
                                <Typography noWrap={true} variant="body2">
                                    {map_data.duration}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

SearchResultListGrid.propTypes = {
    importSelectedAssets: PropTypes.func.isRequired,
    toggleSelectedAsset: PropTypes.func.isRequired,
};

export { SearchResultListGrid };
