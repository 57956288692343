export default (theme, colors) => ({
    root: {
        padding: theme.spacing(0.5),
        fontSize: '1.4rem',
        lineHeight: 1,
        border: 'solid 1px transparent',
        fill: colors.accentLight[0.7],
        backgroundColor: 'transparent',
        cursor: 'pointer',
        color: 'currentColor',
        '&:active, &:hover': {
            backgroundColor: 'transparent',
            fill: colors.accentLight[0.9],
        },
        '&:focus': {
            fill: colors.accentLight[0.9],
        },
        '&:disabled': {
            backgroundColor: 'transparent',
            cursor: 'not-allowed',
        },
    },
    colorPrimary: {
        backgroundColor: colors.accentDark[0.2],
        '&:disabled': {
            backgroundColor: colors.accentDark[0.15],
            '&:hover': {
                backgroundColor: colors.accentDark[0.15],
            },
        },
    },
    colorSecondary: {
        padding: theme.spacing(0),
        fontSize: '1.8rem',
        background: 'transparent',
        '&:disabled': {
            color: colors.accentLight[0.4],
        },
    },
});
