import { makeStyles as materialUiMakeStyles } from '@material-ui/styles';
import { defaultTheme } from '@mediabank/theme/src';

const mockBreakpoints = {
    down: key => key,
};

// const mockMakeStyles = () => () => ({});
const mockMakeStyles = cb => {
    const classNamesObj = cb({ ...defaultTheme(), breakpoints: mockBreakpoints, spacing: n => n, shape: {} });

    const keys = Object.keys(classNamesObj);

    const classNames = keys.reduce((acc, key) => {
        // eslint-disable-next-line no-param-reassign
        acc[key] = `makeStyles-${key}`;

        return acc;
    }, {});

    return () => classNames;
};

// when performing Jest snapshot tests, we supply `<ThemeProvider />` with a mocked `makeStyles`
// otherwise, we supply the original `makeStyles` from Material-UI
const makeStyles = process.env.JEST_WORKER_ID ? mockMakeStyles : materialUiMakeStyles;

export { makeStyles };
