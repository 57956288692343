import './styles.css'; // main css file
import './default.css'; // theme css file

import React from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import PropTypes from 'prop-types';

const DateRangePickerWrapper = ({ handleSelect, preDefinedRanges, selectionRange }) => {
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(preDefinedRanges),
    ];

    return (
        <DateRangePicker
            className={'dateRangeWrapper'}
            direction="horizontal"
            inputRanges={[]}
            months={2}
            moveRangeOnFirstSelection={false}
            rangeColors={['rgba(0, 0, 0, 0.25)']}
            ranges={selectionRange}
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            showSelectionPreview={true}
            staticRanges={staticRanges}
            onChange={handleSelect}
        />
    );
};

DateRangePickerWrapper.propTypes = {
    selectionRange: PropTypes.array,
    preDefinedRanges: PropTypes.array,
    handleSelect: PropTypes.func,
};

DateRangePickerWrapper.defaultProps = {
    selectionRange: [],
    preDefinedRanges: [],
    handleSelect: undefined,
};

export default DateRangePickerWrapper;
