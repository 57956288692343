// TODO: https://mbnepms.atlassian.net/browse/MBX-65

import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { Back_To_Login, No_Auth_SSO } from '../../messages';
import { setTokenInLocalStorage } from '../../utils/jwt';

const ImplicitCallback = ({ location }) => {
    /**
     * When a user completes the partner sign-in process,
     * they will be redirected here.
     *
     * Their token will be appended to the URL
     * as a query-string value.
     */
    const { token } = queryString.parse(location.search);

    if (token) {
        setTokenInLocalStorage(token);

        return <Redirect to="/" />;
    }

    return (
        <>
            <div>{No_Auth_SSO}</div>
            <div>
                <Link data-testid="implicitCallback-link" to="/login">
                    {Back_To_Login}
                </Link>
            </div>
        </>
    );
};

ImplicitCallback.propTypes = {
    // https://reactrouter.com/web/api/location
    location: PropTypes.object.isRequired,
};

export default ImplicitCallback;
