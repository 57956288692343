import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, ClickAwayListener, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { ReactComponent as MoreIcon } from '@mediabank/assets/icons/more-horizontal.svg';
import { makeStyles, Popper, QuickFilter } from '@mediabank/uikit';
import PropTypes from 'prop-types';

const ESCAPE_KEY_CODE = 27;

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
    },
    moreIcon: {
        width: theme.spacing(1.75),
        height: theme.spacing(1.75),
        fontSize: 'inherit',
    },
    paper: {
        width: theme.spacing(44.25),
    },
    popperHeader: {
        padding: theme.spacing(1.5, 2),
    },
    scrollable: {
        maxHeight: theme.spacing(32),
        overflowY: 'scroll',
    },
    quickFilterItem: {
        width: '100%',
        justifyContent: 'flex-start',
    },
}));

const useIconButtonStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0.75),
        backgroundColor: theme.custom.accentDark[0.6],
        border: 'solid 1px transparent',
        fill: theme.custom.accentLight[0.7],
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            fill: theme.custom.accentLight[0.8],
            backgroundColor: theme.custom.accentDark[0.6],
            border: `1px ${theme.custom.accentLight[0.2]} solid`,
        },
        '&:focus': {
            fill: theme.custom.accentLight[1],
            border: `1px ${theme.custom.accentBright[1]} solid`,
        },
    },
}));

const QuickFilterPopper = ({ activateFilter, quickFilters }) => {
    const classes = useStyles();
    const iconButtonClasses = useIconButtonStyles();

    const buttonRef = useRef(null);

    const [open, setOpen] = useState(false);

    const activeQuickFilterId = useSelector(state => state.ui.searchAssets.searchTypes.quickFilter);
    const activeSearchType = useSelector(state => state.ui.searchAssets.activeSearchType);

    const handleAutoCloseOnEscButtonPress = event => {
        if (event.keyCode === ESCAPE_KEY_CODE) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleAutoCloseOnEscButtonPress, false);

        return () => document.removeEventListener('keydown', handleAutoCloseOnEscButtonPress, false);
    }, []);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box className={classes.root}>
                <IconButton
                    ref={buttonRef}
                    classes={iconButtonClasses}
                    data-testid="meatballs-icon"
                    onClick={() => setOpen(!open)}
                >
                    <MoreIcon className={classes.moreIcon} />
                </IconButton>
                {buttonRef.current && (
                    <Popper
                        anchorEl={buttonRef.current}
                        disablePortal={true}
                        offset="0, 14"
                        open={open}
                        placement="bottom-end"
                    >
                        <Paper className={classes.paper}>
                            <Grid
                                alignItems="center"
                                className={classes.popperHeader}
                                container={true}
                                data-testid="popperHeader"
                                justify="space-between"
                            >
                                <Grid item={true}>
                                    <Typography variant="h6">QuickFilters</Typography>
                                </Grid>
                            </Grid>
                            <Divider light={true} />
                            <Box className={classes.scrollable} data-testid="quickfilters-vertical-list" p={2}>
                                {quickFilters.map((quickFilter, index) => {
                                    const isActive =
                                        quickFilter.id === activeQuickFilterId && activeSearchType === 'quickFilter';

                                    return (
                                        <Box
                                            key={`popper-quickFilter-item-${index}`}
                                            data-testid="quickFilter-list-item"
                                            mb={1}
                                        >
                                            <QuickFilter
                                                buttonClassName={classes.quickFilterItem}
                                                label={quickFilter.attributes.smartsearchname}
                                                selected={isActive}
                                                onClick={() => activateFilter(quickFilter, isActive)}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Paper>
                    </Popper>
                )}
            </Box>
        </ClickAwayListener>
    );
};

QuickFilterPopper.propTypes = {
    activateFilter: PropTypes.func.isRequired,
    quickFilters: PropTypes.array.isRequired,
};

export { QuickFilterPopper };
