export default (theme, colors) => ({
    '@global': {
        '*': {
            userSelect: 'none',
        },
        html: {
            fontSize: 10,
        },
        '*::-webkit-scrollbar': {
            width: theme.spacing(1),
            height: theme.spacing(1),
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: colors.accentDark[0.5],
            borderRadius: theme.spacing(0.8),
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: colors.scrollbarThumb,
            borderRadius: theme.spacing(0.8),
        },
        'body ::-webkit-scrollbar-track': {
            backgroundColor: colors.accentDark[0.5],
            borderRadius: '6px',
        },
        'body ::-webkit-scrollbar-thumb': {
            backgroundColor: colors.scrollbarThumb,
            borderRadius: '6px',
        },
    },
});
