import React, { useState } from 'react';
import { Fade, Popper as MuiPopper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { arrowGenerator } from '../Tooltip/Tooltip';

const useStylesArrow = makeStyles(theme => ({
    root: {
        ...arrowGenerator(theme.palette.background.paper),
        zIndex: 1300,
    },
    arrow: {
        position: 'absolute',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
}));

const Popper = ({ children, open, anchorEl, offset, disableArrow, ...forwardProps }) => {
    const { arrow, ...classes } = useStylesArrow();
    const [arrowRef, setArrowRef] = useState(null);

    return (
        <>
            <MuiPopper
                anchorEl={anchorEl}
                className={classes.root}
                modifiers={{
                    arrow: {
                        enabled: Boolean(arrowRef),
                        element: arrowRef,
                    },
                    offset: {
                        offset,
                    },
                }}
                open={open}
                transition={true}
                {...forwardProps}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={100}>
                        <div>
                            {children}
                            {!disableArrow && <span ref={setArrowRef} className={arrow} />}
                        </div>
                    </Fade>
                )}
            </MuiPopper>
        </>
    );
};

Popper.propTypes = {
    open: PropTypes.bool.isRequired,
    disableArrow: PropTypes.bool,
    children: PropTypes.node.isRequired,
    offset: PropTypes.string,
    anchorEl: PropTypes.object,
};

Popper.defaultProps = {
    anchorEl: null,
    offset: '0',
    disableArrow: false,
};

export default Popper;
