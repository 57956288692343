import React from 'react';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import PropTypes from 'prop-types';

/**
 * The styling of this component is overridden in the theme
 */
const Checkbox = ({ onClick, checked, disabled, ...rest }) => {
    const handleClick = event => {
        onClick(event.target.checked, event);
    };

    return (
        <MuiCheckbox
            checked={checked}
            data-cy="checkboxElement"
            data-test="checkboxElement"
            disabled={disabled}
            disableRipple={true}
            onClick={handleClick}
            {...rest}
        />
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
    disabled: false,
};

export default Checkbox;
