import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import {
    DatePicker as MuiDatePicker,
    DateTimePicker as MuiDateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';

const useStyles = breadCrumb =>
    makeStyles(theme => ({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: breadCrumb ? 'none' : '1',
                    borderColor: theme.custom.accentLight[0.2],
                },
            },
            '& .MuiButtonBase-root': {
                color: theme.palette.text.primary,
            },
            '& .MuiOutlinedInput-input': {
                padding: breadCrumb ? theme.spacing(0.5, 0.25) : theme.spacing(0.5, 2),
            },
        },
    }));

const DatePicker = ({ views, isDateTime, breadCrumb, ...forwardProps }) => {
    const classes = useStyles(breadCrumb)();

    const PickerComponent = isDateTime ? MuiDateTimePicker : MuiDatePicker;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PickerComponent
                ampm={false}
                className={classes.root}
                data-cy="datePickerElement"
                data-test="datePickerElement"
                inputVariant="outlined"
                minutesStep={5}
                variant="inline"
                views={views}
                {...forwardProps}
            />
        </MuiPickersUtilsProvider>
    );
};

DatePicker.propTypes = {
    breadCrumb: PropTypes.bool,
    views: PropTypes.arrayOf(PropTypes.oneOf(['date', 'year', 'month', 'hours', 'minutes'])),
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    format: PropTypes.string,
    isDateTime: PropTypes.bool,
};

DatePicker.defaultProps = {
    breadCrumb: false,
    views: ['date'],
    value: undefined,
    onChange: undefined,
    format: 'yyyy-MM-dd',
    isDateTime: false,
};

export default DatePicker;
