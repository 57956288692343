import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';
import cx from 'clsx';
import PropTypes from 'prop-types';

import { TABLE_CONFIG } from '../../constants';
import { useSearchResultListCommonStyles } from './searchResultListCommonStyles';

export const useStyles = makeStyles(theme => ({
    selected: {
        border: `1px solid ${theme.custom.accentBright[1]}`,
        color: theme.custom.accentBright[1],
    },
}));

const SearchResultListRows = ({ importSelectedAssets, toggleSelectedAsset }) => {
    const classes = useStyles();
    const searchResultListCommonClasses = useSearchResultListCommonStyles();

    const assets = useSelector(state => state.assetList.assets);
    const selectedAssetsById = useSelector(state => state.assetList.selectedAssetsById);

    return (
        <>
            {assets.map(({ attributes }, idx) => {
                const { assetid: assetId, assetmeta: assetMeta } = attributes;

                const isAssetSelected = !!selectedAssetsById[assetId];

                return (
                    <Box
                        key={`${assetId}-${idx}`}
                        className={cx([searchResultListCommonClasses.row, isAssetSelected && classes.selected])}
                        data-testid="assetTable-table-body-row"
                        draggable="true"
                        onClick={() => toggleSelectedAsset(assetId)}
                        onDragEnd={() => importSelectedAssets(assetId)}
                    >
                        {TABLE_CONFIG.map(({ key }) => (
                            <Typography key={key} component="span">
                                {assetMeta[key]}
                            </Typography>
                        ))}
                    </Box>
                );
            })}
        </>
    );
};

SearchResultListRows.propTypes = {
    importSelectedAssets: PropTypes.func.isRequired,
    toggleSelectedAsset: PropTypes.func.isRequired,
};

export { SearchResultListRows };
