import React from 'react';
import { FormControlLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        marginLeft: 'initial',
    },
    label: {
        fontSize: '1rem',
        '&$disabled': {
            opacity: 0.25,
        },
    },
    disabled: {},
});

const CheckboxLabel = ({ label, children, disabled }) => {
    const classes = useStyles();

    return <FormControlLabel classes={classes} control={children} disabled={disabled} label={label} />;
};

CheckboxLabel.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

CheckboxLabel.defaultProps = {
    disabled: false,
};

export default CheckboxLabel;
