import React, { useImperativeHandle, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRight from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useMenuItemStyles = makeStyles(theme => ({
    root: props => ({
        backgroundColor: props.isOpen ? theme.palette.action.hover : 'rgba(0,0,0,0)',
    }),
}));

// This component is just usable in the @Obelix/uikit
const NestedMenuItem = React.forwardRef(
    (
        {
            isParentMenuOpen,
            label,
            rightIcon,
            children,
            className,
            tabIndex: tabIndexProp,
            disabled,
            ContainerProps: ContainerPropsProp,
            ...restProps
        },
        ref
    ) => {
        const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

        const menuItemRef = useRef(null);
        useImperativeHandle(ref, () => menuItemRef.current);

        const containerRef = useRef(null);
        useImperativeHandle(containerRefProp, () => containerRef.current);

        const menuContainerRef = useRef(null);

        const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

        const handleMouseEnter = event => {
            setIsSubMenuOpen(true);

            if (ContainerProps && ContainerProps.onMouseEnter) {
                ContainerProps.onMouseEnter(event);
            }
        };

        const handleMouseLeave = event => {
            setIsSubMenuOpen(false);
            if (ContainerProps && ContainerProps.onMouseLeave) {
                ContainerProps.onMouseLeave(event);
            }
        };

        const isSubmenuFocused = () => {
            const active = containerRef.current?.ownerDocument?.activeElement;
            for (const child of menuContainerRef.current?.children || []) {
                if (child === active) {
                    return true;
                }
            }

            return false;
        };

        const handleFocus = event => {
            if (event.target === containerRef.current) {
                setIsSubMenuOpen(true);
            }
            const { onFocus } = ContainerProps || {};
            if (onFocus) {
                ContainerProps.onFocus(event);
            }
        };

        const handleKeyDown = event => {
            console.log('EVENT.KEY: ', event.key);
            if (event.key === 'Escape') {
                return;
            }

            if (isSubmenuFocused()) {
                event.stopPropagation();
            }

            const active = containerRef.current?.ownerDocument?.activeElement;

            if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
                // eslint-disable-next-line no-unused-expressions
                containerRef?.current?.focus();
            }

            // You are able to navigate with arrow keys. If its a ArrowRight, then you can jump to the sub menus
            if (event.key === 'ArrowRight' && event.target === containerRef.current && event.target === active) {
                const firstChild = menuContainerRef.current?.children[0] || undefined;

                if (firstChild) {
                    firstChild.focus();
                }
            }
        };

        const isOpen = isSubMenuOpen && isParentMenuOpen;
        const menuItemClasses = useMenuItemStyles({ isOpen });

        // Root element must have a `tabIndex` attribute for keyboard navigation
        let tabIndex;
        if (!disabled) {
            tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
        }

        return (
            <Box
                data-cy="nestedMenuItem"
                {...ContainerProps}
                ref={containerRef}
                tabIndex={tabIndex}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <MenuItem {...restProps} ref={menuItemRef} className={clsx(menuItemClasses.root, className)}>
                    {label}
                    {rightIcon}
                </MenuItem>
                <Menu
                    anchorEl={menuItemRef.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    autoFocus={false}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    open={isOpen}
                    style={{ pointerEvents: 'none' }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => {
                        setIsSubMenuOpen(false);
                    }}
                >
                    <Box ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
                        {children}
                    </Box>
                </Menu>
            </Box>
        );
    }
);

NestedMenuItem.defaultProps = {
    children: null,
    className: '',
    ContainerProps: {},
    disabled: false,
    rightIcon: <ArrowRight />,
    tabIndex: -1,
};

NestedMenuItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    ContainerProps: PropTypes.object,
    disabled: PropTypes.bool,
    isParentMenuOpen: PropTypes.bool.isRequired,
    label: PropTypes.node.isRequired,
    rightIcon: PropTypes.node,
    tabIndex: PropTypes.number,
};

export { NestedMenuItem };
