import { smartSearchService } from '@mediabank/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api';
import { checkShouldForceLogout } from '../../utils/checkShouldForceLogout';
import {
    fetchCustomerConfigFailed,
    fetchCustomerConfigLoading,
    fetchCustomerConfigSucceeded,
    fetchQuickFiltersFailed,
    fetchQuickFiltersLoading,
    fetchQuickFiltersSucceeded,
    fetchSavedSearchesFailed,
    fetchSavedSearchesLoading,
    fetchSavedSearchesSucceeded,
} from '../actions/config';
import { setActiveForm } from '../actions/ui';
import { getFields } from '../selectors';
import { fetchFormByKey } from './form';

const fetchSavedSearches = createAsyncThunk('config/fetchSavedSearches', async (params, { dispatch }) => {
    dispatch(fetchSavedSearchesLoading(true));

    try {
        const { smartsearches = [] } = await api.fetchSavedSearches();

        const activeSavedSearches = smartsearches.filter(({ hidden }) => !hidden);

        dispatch(fetchSavedSearchesSucceeded(activeSavedSearches));
        dispatch(fetchSavedSearchesLoading(false));

        return smartsearches;
    } catch (axiosError) {
        if (checkShouldForceLogout(axiosError)) {
            api.logout();

            return;
        }

        const { message } = axiosError || {};

        dispatch(fetchSavedSearchesFailed(message));
        dispatch(fetchSavedSearchesLoading(false));
    }
});

const fetchCustomerConfig = createAsyncThunk('config/fetchCustomerConfig', async (params, { dispatch, getState }) => {
    dispatch(fetchCustomerConfigLoading(true));

    try {
        const { customerconfig = {} } = await api.fetchCustomerConfig();

        dispatch(fetchCustomerConfigSucceeded(customerconfig));
        dispatch(fetchCustomerConfigLoading(false));

        const premiereConfig = JSON.parse(customerconfig.PremiereProPanelConfig);

        // Legacy config data will have an `adex_form` property
        // Only for legacy config, since we do not need a select in this case, set active form and fetch it
        if (premiereConfig && premiereConfig.adex_form) {
            const state = getState();
            const fields = getFields(state);

            dispatch(setActiveForm(premiereConfig.adex_form));

            if (!fields.length) {
                await dispatch(fetchFormByKey(premiereConfig.adex_form));
            }
        }

        return customerconfig;
    } catch (axiosError) {
        if (checkShouldForceLogout(axiosError)) {
            api.logout();

            return;
        }

        const { message } = axiosError || {};

        dispatch(fetchCustomerConfigFailed(message));
        dispatch(fetchCustomerConfigLoading(false));
    }
});

const fetchQuickFilters = createAsyncThunk('config/fetchQuickFilters', async (params, { dispatch }) => {
    dispatch(fetchQuickFiltersLoading(true));

    try {
        const {
            data: { data: quickFilters = [] },
        } = await smartSearchService.getAllQuickfilters();

        dispatch(fetchQuickFiltersSucceeded(quickFilters));
        dispatch(fetchQuickFiltersLoading(false));

        return quickFilters;
    } catch (axiosError) {
        if (checkShouldForceLogout(axiosError)) {
            api.logout();

            return;
        }

        const { message } = axiosError || {};

        dispatch(fetchQuickFiltersFailed(message));
        dispatch(fetchQuickFiltersLoading(false));
    }
});

export { fetchCustomerConfig, fetchSavedSearches, fetchQuickFilters };
