import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, InputLabel, Typography } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from '@mediabank/assets/icons/error-circle.svg';
import { makeStyles, Select } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import api from '../../api';
import { mapMetaValuesToSemantic } from '../../utils/form';
import { RequiredFieldIndicator } from './RequiredFieldIndicator';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        '& .MuiFormLabel-root': {
            fontSize: '12px',
            color: 'white',
            opacity: '0.7',
            marginBottom: theme.spacing(0.5),
        },
        '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.custom.accentBright[1],
        },
        '& .MuiTypography-root': {
            opacity: '0.9',
        },
        '& .MuiChip-root': {
            backgroundColor: '#008094 !important',
        },
    },
    helperTextRow: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    helperText: {
        marginLeft: theme.spacing(1),
        color: theme.custom.accentLight[0.8],
    },
}));

const MetaSelect = ({
    clearable,
    endpoint,
    formInputError,
    label,
    multiple,
    name,
    options,
    requestType,
    required,
    setFormValues,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [localOptions, setLocalOptions] = useState(options);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        setErrorMessage(formInputError.filter(item => item.field === name));
    }, [formInputError, name]);

    const handleChange = event => {
        setSelectedValue(event);
        if (isErrorMessage && event) {
            setErrorMessage(null);
        }
        dispatch(setFormValues({ key: name, value: event }));
    };

    const handleOpen = () => {
        if (endpoint && !options.length) {
            setIsLoading(true);
            const params = {
                key: name,
                application: 'library',
                action: 'values',
            };

            api.fetchLegacy(endpoint, params, requestType)
                .then(response => {
                    if (response[name]) {
                        const options = mapMetaValuesToSemantic(response[name]);
                        setLocalOptions(options);
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const isErrorMessage = errorMessage !== null && errorMessage.length > 0;
    const transformedLocalOptions = localOptions.map(({ text: label, ...rest }) => ({ label, ...rest }));

    return (
        <Box className={classes.root}>
            <InputLabel>
                {label}
                {!!required && <RequiredFieldIndicator />}
            </InputLabel>
            <Select
                data-testid="metaSelect-export-form-selectItem"
                disableOverrides={true}
                error={isErrorMessage}
                isLoading={isLoading}
                isMulti={multiple}
                isSearchable={true}
                name={name}
                options={transformedLocalOptions}
                placeholder="..Please select.."
                required={required}
                value={selectedValue}
                onChange={handleChange}
                onMenuOpen={handleOpen}
            />
            {isErrorMessage && (
                <Box className={classes.helperTextRow}>
                    <ErrorIcon />
                    <Typography className={classes.helperText} variant="body2">
                        {errorMessage[0].message}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

MetaSelect.defaultProps = {
    formInputError: [],
    requestType: '',
};

MetaSelect.propTypes = {
    clearable: PropTypes.bool.isRequired,
    endpoint: PropTypes.string.isRequired,
    formInputError: PropTypes.array,
    label: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    requestType: PropTypes.string,
    required: PropTypes.bool.isRequired,
    setFormValues: PropTypes.func.isRequired,
};

export { MetaSelect };
