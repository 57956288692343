const defaultValues = {
    API_TIMEOUT: process.env.REACT_APP_API_TIMEOUT,
    API_URL: process.env.REACT_APP_API_URL,
    DEPLOYMENT_ENV: process.env.REACT_APP_DEPLOYMENT_ENV,
    JWT_URL: process.env.REACT_APP_JWT_URL,
    TAGS_API_URL: process.env.REACT_APP_TAGS_API_URL,
};

const clientConfig = {
    ...defaultValues,
    INITIALIZED: false,
};

const configureClient = config => {
    if (clientConfig.INITIALIZED) return;

    clientConfig.INITIALIZED = true;

    const availableKeyUpdates = Object.keys(defaultValues);

    // At the app lvl you are only able to configure the values, what has fallback values.
    for (const [key] of Object.entries(config)) {
        const canUpdate = availableKeyUpdates.includes(key);
        if (canUpdate) {
            clientConfig[key] = config[key];
        }
    }

    Object.freeze(clientConfig);
};

export { clientConfig, configureClient };

/**
 * @USAGE
 * // App.js
 * import { configureClient } from '@mediabank/client';
 *
 * const App = () => {
 *
 *      configureClient({
 *          DEPLOYMENT_ENV: process.env.REACT_APP_DEPLOYMENT_ENV,
 *          API_URL: process.env.REACT_APP_API_URL,
 *          API_TIMEOUT: process.env.REACT_APP_API_TIMEOUT,
 *          JWT_URL: process.env.REACT_APP_JWT_URL,
 *          TAGS_API_URL: process.env.REACT_APP_TAGS_API_URL,
 *      });
 *
 *      return <div>App</div>
 * }
 */
