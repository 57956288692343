import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';

const store = configureStore({
    reducer: rootReducer,

    // additional configuration for Redux Toolkit
    // https://redux-toolkit.js.org/api/getDefaultMiddleware
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            // we disable the immutability safety-check
            // that comes with Redux Toolkit
            // because it's slow
            immutableCheck: false,
        }),
});

export default store;
