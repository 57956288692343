import React from 'react';
import PropTypes from 'prop-types';

const CHIP_MAX_WIDTH = 250;

const EllipsisText = props => {
    const { children } = props;

    return (
        <div
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: CHIP_MAX_WIDTH,
            }}
        >
            {children}
        </div>
    );
};

EllipsisText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default EllipsisText;
