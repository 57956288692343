import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import PropTypes from 'prop-types';

import FormContext from './FormContext';

const Form = ({ onSubmit, onValidate, children, initialValues, ...rest }) => (
    <FinalForm
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values, invalid }) => (
            <form onSubmit={handleSubmit} {...rest}>
                <FormContext.Provider value={{ form, submitting, handleSubmit, pristine, values, invalid }}>
                    {children}
                </FormContext.Provider>
            </form>
        )}
        validate={onValidate}
        onSubmit={onSubmit}
    />
);

Form.propTypes = {
    onSubmit: PropTypes.func,
    onValidate: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    initialValues: PropTypes.object,
};

Form.defaultProps = {
    onSubmit: () => undefined,
    onValidate: () => undefined,
    initialValues: undefined,
};

export default Form;
