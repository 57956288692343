import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ReactComponent as LeftArrow } from '@mediabank/assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '@mediabank/assets/icons/right-arrow.svg';
import cx from 'clsx';
import PropTypes from 'prop-types';

import { IconButton } from '../index';

const useStyles = makeStyles(theme => ({
    navButtonContainer: {
        display: 'flex',
    },
    iconButton: {
        height: theme.spacing(12.5),
        width: theme.spacing(2.5),
        margin: 'auto',
        padding: theme.spacing(0),
        '&:hover': {
            background: 'red',
        },
        '&:active': {
            backgroundColor: `${theme.custom.accentMedium[0.5]} !important`,
        },
        '&:focus': {
            borderColor: 'transparent !important',
        },
        '& svg': {
            fill: 'none',
            stroke: theme.palette.text.primary,
        },
    },
    navButtonLeft: {
        borderRadius: '0px 10px 10px 0px',
    },
    navButtonRight: {
        borderRadius: '10px 0px 0px 10px',
    },
}));

const NavButtonLeft = ({ onClick = e => e, wrapperClassName = '', iconButtonClassName = '' }) => {
    const classes = useStyles();

    return (
        <Box className={cx(classes.navButtonContainer, wrapperClassName)}>
            <IconButton
                className={cx(classes.iconButton, classes.navButtonLeft, iconButtonClassName)}
                onClick={onClick}
            >
                <LeftArrow />
            </IconButton>
        </Box>
    );
};

NavButtonLeft.defaultProps = {
    wrapperClassName: '',
    iconButtonClassName: '',
};

NavButtonLeft.propTypes = {
    wrapperClassName: PropTypes.string,
    iconButtonClassName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

const NavButtonRight = ({ onClick = e => e, wrapperClassName = '', iconButtonClassName = '' }) => {
    const classes = useStyles();

    return (
        <Box className={cx(classes.navButtonContainer, wrapperClassName)}>
            <IconButton
                className={cx(classes.iconButton, classes.navButtonRight, iconButtonClassName)}
                onClick={onClick}
            >
                <RightArrow />
            </IconButton>
        </Box>
    );
};

NavButtonRight.defaultProps = {
    wrapperClassName: '',
    iconButtonClassName: '',
};
NavButtonRight.propTypes = {
    wrapperClassName: PropTypes.string,
    iconButtonClassName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export { NavButtonLeft, NavButtonRight };
