import React, { useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, QuickFilter } from '@mediabank/uikit';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    pill: {
        paddingRight: theme.spacing(1),
        float: 'left',
    },
    hidden: {
        opacity: 0,
    },
    filter: {
        paddingTop: theme.spacing(0.9),
        paddingBottom: theme.spacing(0.9),
    },
}));

const QuickFilterItem = ({ quickFilter, hidden, storedWidth, setPillSize, ...forwardProps }) => {
    const classes = useStyles();
    const pillRef = useRef(null);

    useEffect(() => {
        const width = pillRef?.current?.offsetWidth || 0;
        if (parseInt(width) !== parseInt(storedWidth)) {
            setPillSize(quickFilter.id, width);
        }
    }, [pillRef, storedWidth, setPillSize, quickFilter]);

    return (
        <Box ref={pillRef} className={clsx(classes.pill, { [classes.hidden]: hidden })}>
            <QuickFilter className={classes.filter} label={quickFilter.attributes?.smartsearchname} {...forwardProps} />
        </Box>
    );
};

QuickFilterItem.propTypes = {
    quickFilter: PropTypes.object.isRequired,
    hidden: PropTypes.bool.isRequired,
    storedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setPillSize: PropTypes.func.isRequired,
};

QuickFilterItem.defaultProps = {
    storedWidth: '0',
};

export { QuickFilterItem };
