import httpClient from '../common/http';
import { API_TIMEOUT } from '../../constants';

/**
 * @typedef {import('.').GenericEndpointConfig} GenericEndpointConfig
 * @typedef {import('.').GenericEndpoints} GenericEndpoints
 * @typedef {import('.').ApiProduct} ApiProduct
 */

/**
 * Generic way to create http methods for a endpoint
 * @param {string} path
 * @param {httpClient} client
 * @param {GenericEndpointConfig} config
 * @returns {GenericEndpoints<ApiProduct>}
 */
const endpoint = (path, client, config = {}) => ({
    all: () => client.get(path, config),
    one: id => client.get(`${path}/${id}`, config),
    create: data => client.post(path, { data, ...config }),
    update: (id, data) => client.patch(`${path}/${id}`, { data, ...config }),
    delete: id => client.delete(`${path}/${id}`, { ...config }),
});

const api = 'https://mbproductapi-prod.vpc3.mnw.no/api/v1';

const client = new httpClient(api, API_TIMEOUT);

const product = endpoint('product', client);

const config = endpoint('config', client);
config.currencyConfig = () => config.one('currency_config');

export default {
    product,
    config,
};
