import React from 'react';
import ReactDOM from 'react-dom';
import { sentryInit } from '@mediabank/utils';

import { App } from './App';
import { CEP_EXT_VERSION, SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_IS_ACTIVE, SENTRY_RELEASE } from './constants/index';
import * as serviceWorker from './serviceWorker';

// Sentry initialization
if (SENTRY_IS_ACTIVE) {
    sentryInit({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: SENTRY_RELEASE,

        tagsDictionary: {
            // convert to `lower_snake` to match the Sentry convention for tags
            cep_ext_version: CEP_EXT_VERSION,
        },
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
