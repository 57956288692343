export default (theme, colors) => ({
    root: {
        '&$focused $notchedOutline': {
            borderColor: colors.focused[0.5],
            borderWidth: 1,
        },
        '&:hover $notchedOutline': {
            borderColor: colors.accentLight[0.4],
        },
        '&$disabled $notchedOutline': {
            borderColor: colors.accentLight[0.2],
        },
        '&$error $notchedOutline': {
            borderColor: colors.sysRed[1],
        },
    },
});
