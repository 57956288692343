import { makeStyles } from '@material-ui/core';

export const usePanelTabStyles = makeStyles(theme => ({
    panelContainer: {
        display: 'flex',
    },
    generalBtnStyle: {
        textTransform: 'uppercase',
        color: theme.custom.accentLight[0.6],
    },
    level1: {
        padding: theme.spacing(1.5, 2, 1.75, 2),
        '&:hover, &:focus': {
            color: theme.palette.text.primary,
        },
        '&:focus $bottomLine': {
            display: 'block',
            background: theme.custom.focused[0.5],
        },
    },
    level2: {
        border: '1px solid transparent',
        padding: theme.spacing(0.25, 0.55),
        margin: theme.spacing(1.5),
        '&:focus': {
            border: `1px solid ${theme.custom.focused[0.5]}`,
            background: 'transparent',
        },
        '&:hover': {
            background: theme.custom.accentLight[0.15],
            color: theme.palette.text.primary,
        },
    },

    level1Selected: {
        color: theme.custom.accentBright[1],
        '&:hover, &:focus': {
            color: theme.custom.accentBright[1],
        },
        '& $bottomLine, &:focus $bottomLine': {
            display: 'block',
            background: theme.custom.accentBright[1],
        },
    },
    level2Selected: {
        background: theme.custom.accentBright[1],
        color: theme.custom.productBase[1],
        '&:hover, &:focus': {
            background: theme.custom.accentBright[1],
            color: theme.custom.productBase[1],
            border: '1px solid transparent',
        },
    },

    bottomLine: {
        display: 'none',
        position: 'absolute',
        bottom: 0,
        left: theme.spacing(1.75),
        right: theme.spacing(1.75),
        height: '4px',
        borderTopRightRadius: theme.spacing(0.5),
        borderTopLeftRadius: theme.spacing(0.5),
    },
    hideButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginRight: theme.spacing(-0.5),
        '& svg': {
            fill: 'none',
            stroke: theme.palette.text.primary,
        },
    },
    hideButton: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.custom.accentLight[0.8],
        height: theme.spacing(3),
        width: theme.spacing(9),
        marginTop: theme.spacing(0.75),
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
    },
    breadcrumbsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(0.5),
    },
}));
