import { makeStyles } from '@mui/styles';
import { defaultFontFamily } from './fonts';

export const useGlobalStyles = theme =>
    makeStyles(() => ({
        '@global': {
            '*': { userSelect: 'none' },
            input: { userSelect: 'text' },
            textArea: { userSelect: 'text' },
            html: {
                fontSize: 10,
            },
            button: {
                fontFamily: defaultFontFamily,
            },
            body: {
                margin: 0,
                overflow: 'hidden',
            },
            'body, html, #root': {
                height: '100%',
            },
            '*::-webkit-scrollbar': {
                width: theme.spacing(1),
                height: theme.spacing(1),
            },
            '*::-webkit-scrollbar-track': {
                backgroundColor: theme.custom.accentDark[0.2],
                borderRadius: theme.spacing(0.8),
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: theme.custom.accentDark[0.2],
                borderRadius: theme.spacing(0.8),
            },
            '* svg': {
                fill: 'currentColor',
            },
        },
    }));
