import React from 'react';
import { useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { Box } from '@material-ui/core';
import { ContentLoading, makeStyles, ScrollableContainer } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { TABLE_DISPLAY_TYPES } from '../../constants';
import { SearchResultListGrid } from './SearchResultListGrid';
import { SearchResultListHeader } from './SearchResultListHeader';
import { SearchResultListRows } from './SearchResultListRows';

const gridRowViewDiff = '50px'; // height difference between grid view and row view
const footerSpace = '5px';

const height = (isRowView, searchTypeContentHeight) =>
    isRowView
        ? `calc(100% - (${gridRowViewDiff} + ${footerSpace} +
              ${searchTypeContentHeight}px +
              var(--searchTypeSelectorHeight) +
              var(--searchResultsHeaderHeight) +
                var(--footerHeight))
          )`
        : `calc(100% - (${footerSpace} +
            ${searchTypeContentHeight}px +
            var(--searchTypeSelectorHeight) +
            var(--searchResultsHeaderHeight) +
            var(--footerHeight))
          )`;

const useStyles = makeStyles(theme => ({
    searchResultListContainer: {
        height: ({ isRowView, searchTypeContentHeight }) => height(isRowView, searchTypeContentHeight),
        marginBottom: ({ isRowView }) => (isRowView ? `calc(${gridRowViewDiff} + ${footerSpace})` : footerSpace),
        paddingRight: theme.spacing(1),
    },
}));

const SearchResultList = ({
    displayType,
    importSelectedAssets,
    scrollableContainerRef,
    toggleSelectedAsset,
    onVisibilityChange,
    searchTypeContentHeight,
}) => {
    const assetCount = useSelector(state => state.assetList.assetCount);
    const isSearchAssetsLoading = useSelector(state => state.assetList.isSearchAssetsLoading);
    const isMoreAssetsAvailable = useSelector(state => state.assetList.isMoreAssetsAvailable);

    const isRowView = displayType === TABLE_DISPLAY_TYPES.row;
    const isGridView = displayType === TABLE_DISPLAY_TYPES.thumbnail;
    const classes = useStyles({ isRowView, searchTypeContentHeight });

    return (
        <Box className={classes.searchResultListContainer} data-testid="SearchResultList">
            {isRowView && <SearchResultListHeader />}
            <ScrollableContainer ref={scrollableContainerRef} pb={25} scrollableX={true} scrollBarPos={1}>
                {isRowView && (
                    <SearchResultListRows
                        importSelectedAssets={importSelectedAssets}
                        toggleSelectedAsset={toggleSelectedAsset}
                    />
                )}

                {isGridView && (
                    <SearchResultListGrid
                        importSelectedAssets={importSelectedAssets}
                        toggleSelectedAsset={toggleSelectedAsset}
                    />
                )}

                <VisibilitySensor
                    key={`loading-${assetCount}`}
                    active={!isSearchAssetsLoading && isMoreAssetsAvailable}
                    delayedCall={true}
                    onChange={onVisibilityChange}
                >
                    <Box data-testid="pager-pagination-list" height={140} pt={8}>
                        {isMoreAssetsAvailable ? <ContentLoading /> : ''}
                    </Box>
                </VisibilitySensor>
            </ScrollableContainer>
        </Box>
    );
};

SearchResultList.propTypes = {
    displayType: PropTypes.oneOf(['row', 'thumbnail']).isRequired,
    importSelectedAssets: PropTypes.func.isRequired,
    scrollableContainerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    searchTypeContentHeight: PropTypes.number,
    toggleSelectedAsset: PropTypes.func.isRequired,
    onVisibilityChange: PropTypes.func.isRequired,
};

SearchResultList.defaultProps = {
    scrollableContainerRef: () => null,
    searchTypeContentHeight: 0,
};

export { SearchResultList };
