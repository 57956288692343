import { decodeToken } from '@mediabank/utils';

import { TOKEN_STORAGE_KEY } from '../constants';

const setTokenInLocalStorage = token => {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
    triggerSetTokenEvent(token);
};

const clearTokenAndReload = () => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);

    triggerSetTokenEvent('');

    // NOTE: in order to see this log after the `reload()`,
    // you'll need to check "Preserve log" in Chrome DevTools
    console.info('clearTokenAndReload: OK');

    window.location.reload();
};

const triggerSetTokenEvent = token => {
    const event = new CustomEvent('SET:TOKEN', { token });
    window.dispatchEvent(event);
};

const getTokenFromLocalStorage = () => localStorage.getItem(TOKEN_STORAGE_KEY);

/**
 * @method getMinutesUntilTokenExpiration
 *
 * @return {number|null} the number of minutes until the current JWT expires
 *                       or `null` if we don't have a valid token
 */
const getMinutesUntilTokenExpiration = () => {
    const tokenStr = getTokenFromLocalStorage();
    const decodedTokenObj = decodeToken(tokenStr);

    const expiryInEpochSeconds = decodedTokenObj.exp;
    const nowInEpochSeconds = Math.floor(Date.now() / 1000);

    const secondsUntilExpiration = expiryInEpochSeconds - nowInEpochSeconds;
    const minutesUntilTokenExpiration = secondsUntilExpiration / 60;

    // returns a number with at most 2 decimals of precision
    return Math.round(minutesUntilTokenExpiration * 100) / 100;
};

export {
    clearTokenAndReload,
    decodeToken,
    getMinutesUntilTokenExpiration,
    getTokenFromLocalStorage,
    setTokenInLocalStorage,
    triggerSetTokenEvent,
};
