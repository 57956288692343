export default (theme, colors) => ({
    outlinedError: {
        color: colors.accentLight[1],
    },
    outlinedSuccess: {
        color: colors.accentLight[1],
    },
    outlinedInfo: {
        color: colors.accentLight[1],
    },
    outlinedWarning: {
        color: colors.accentLight[1],
    },
});
