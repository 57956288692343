import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

const SelectInput = props => <components.Input {...props} />;

SelectInput.propTypes = {
    selectProps: PropTypes.object.isRequired,
};

export default SelectInput;
