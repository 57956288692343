import React, { useState } from 'react';
import { Field } from 'react-final-form';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: ({ isError }) => ({
        top: isError ? theme.spacing(1) : 0,
    }),
}));

const DatePickerWrapper = ({
    input: { value, onChange }, // Injected by wrapper Field from react-final-from
    label,
    dateFormat,
    variant,
    onChange: userOnChange,
}) => {
    const [isError, setIsError] = useState(false);
    let tempValue = value;

    // temporary fix, for preventing an error on the dateInput
    if (value === 'DYNAMIC_DATE:date') {
        tempValue = '';
    }

    const onError = error => (error?.length > 0 ? setIsError(true) : setIsError(false));
    const classes = useStyles({ isError });

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                {...{ classes }}
                disableToolbar={false}
                format={dateFormat}
                inputVariant={variant}
                invalidDateMessage={`${tempValue} is not a valid date`}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                label={label}
                margin="none"
                value={tempValue || null}
                variant="inline"
                onChange={(date, dateString) => {
                    onChange(dateString, date);
                    userOnChange && userOnChange(dateString, date);
                }}
                onError={onError}
            />
        </MuiPickersUtilsProvider>
    );
};

DatePickerWrapper.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
    dateFormat: PropTypes.string,
    input: PropTypes.object,
    onChange: PropTypes.func,
};

DatePickerWrapper.defaultProps = {
    label: '',
    name: '',
    variant: 'outlined',
    dateFormat: 'yyyy-MM-dd',
    input: {},
    onChange: null,
};

const FormDatePickerField = ({ name, ...fieldProps }) => (
    <Field name={name} render={fieldRenderProps => <DatePickerWrapper {...fieldRenderProps} />} {...fieldProps} />
);

FormDatePickerField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    variant: PropTypes.string,
    dateFormat: PropTypes.string,
};

FormDatePickerField.defaultProps = {
    label: '',
    variant: 'outlined',
    dateFormat: 'yyyy-MM-dd',
};

// exported for testing purposes
export { DatePickerWrapper };

export default FormDatePickerField;
