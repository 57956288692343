import { setToken } from '@mediabank/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api';
import { getTokenFromLocalStorage } from '../../utils/jwt';
import {
    setTokenIsValidated,
    userLoginFailed,
    userLoginLoading,
    userLoginSucceeded,
    userLogoutSucceeded,
} from '../actions/user';
import { searchAssets } from './assetList';
import { fetchCustomerConfig, fetchQuickFilters, fetchSavedSearches } from './config';

const loginThunk = createAsyncThunk('user/login', async ({ email, password }, { dispatch }) => {
    dispatch(userLoginLoading(true));

    try {
        const tokenStr = await api.login({ email, password });

        // We have a token but it needs validating before it can be used
        const isTokenValid = tokenStr && !!(await api.validateToken(tokenStr));

        dispatch(userLoginLoading(false));

        if (!isTokenValid) {
            return dispatch(userLoginFailed('Invalid username or password'));
        }

        setToken(tokenStr);
        dispatch(userLoginSucceeded(tokenStr));
        dispatch(setTokenIsValidated(true));
        // load initial app data after a successful login
        dispatch(loadInitialAppDataThunk());

        return tokenStr;
    } catch (error) {
        if (error.status === 401) {
            dispatch(userLoginLoading(false));
            dispatch(userLoginFailed('Invalid username or password'));
        }
    } finally {
        dispatch(userLoginLoading(false));
    }
});

const tryAutoLoginThunk = createAsyncThunk('user/tryAutoLogin', async (payload, { dispatch }) => {
    const token = getTokenFromLocalStorage();

    if (token) {
        dispatch(userLoginSucceeded(token));
        setToken(token);
        // load initial app data after a successful auto login
        dispatch(loadInitialAppDataThunk());
    } else {
        dispatch(userLogoutSucceeded());
    }
});

const loadInitialAppDataThunk = createAsyncThunk('user/loadInitialAppData', async (payload, { dispatch }) => {
    await dispatch(searchAssets());
    await dispatch(fetchQuickFilters());
    await dispatch(fetchSavedSearches());
    await dispatch(fetchCustomerConfig());
});

export { loginThunk, tryAutoLoginThunk };
