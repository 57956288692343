import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as RemoveIcon } from '@mediabank/assets/icons/minus.svg';
import { ReactComponent as AddIcon } from '@mediabank/assets/icons/plus.svg';
import { Tooltip } from '@mediabank/uikit';
import cx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.custom.accentLight[0.6],
    },
    title: {
        cursor: 'pointer',
        padding: theme.spacing(1, 1.75),
        background: theme.custom.accentDark[0.6],
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&$open': {
            background: theme.custom.accentDark[0.6],
        },
        '&:hover': {
            background: theme.custom.accentDark[0.4],
        },
    },
    toggleIcon: {
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.8rem',
        lineHeight: '1.8rem',
        padding: theme.spacing(0.625, 0.75, 0.625, 0.5),
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        background: theme.custom.accentLight[0.05],
        '&:hover': {
            background: theme.custom.accentLight[0.03],
        },
    },
    content: {
        background: theme.custom.accentDark[0.6],
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
    },
    open: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    selected: {
        color: theme.palette.primary.main,
    },
    addIcon: {
        margin: theme.spacing(0.5),
        fill: 'none',
        stroke: 'currentColor',
    },
    minusIcon: {
        margin: `${theme.spacing(0.625)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    },
}));

const ExpandablePill = ({
    title,
    onClick,
    content,
    defaultExpanded,
    selected,
    addIconTooltipText,
    removeIconTooltipText,
}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(defaultExpanded);
    const handleToggleOpen = () => {
        setOpen(!open);
    };

    return (
        <div className={classes.root}>
            <Grid alignItems="center" className={cx(classes.header)} container={true} justify="space-between">
                <Grid
                    className={cx(classes.title, {
                        [classes.open]: open,
                        [classes.selected]: selected,
                    })}
                    data-test="expandablePillTitle"
                    item={true}
                    xs={true}
                    onClick={onClick}
                >
                    <Typography variant="body2">{title}</Typography>
                </Grid>
                <Grid
                    className={cx(classes.toggleIcon, { [classes.open]: open })}
                    item={true}
                    onClick={handleToggleOpen}
                >
                    {open ? (
                        <Tooltip data-cy="expandablePillRemoveIcon" title={removeIconTooltipText}>
                            <RemoveIcon className={classes.minusIcon} fontSize="inherit" />
                        </Tooltip>
                    ) : (
                        <Tooltip data-cy="expandablePillAddIcon" title={addIconTooltipText}>
                            <AddIcon className={classes.addIcon} fontSize="inherit" />
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
            {open && <div className={classes.content}>{content}</div>}
        </div>
    );
};

ExpandablePill.propTypes = {
    title: PropTypes.node.isRequired,
    content: PropTypes.node,
    onClick: PropTypes.func,
    defaultExpanded: PropTypes.bool,
    selected: PropTypes.bool,
    addIconTooltipText: PropTypes.string,
    removeIconTooltipText: PropTypes.string,
};

ExpandablePill.defaultProps = {
    content: '',
    onClick: () => {},
    defaultExpanded: false,
    selected: false,
    addIconTooltipText: '',
    removeIconTooltipText: '',
};

export default ExpandablePill;
