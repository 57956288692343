import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';
import cx from 'clsx';

import { TABLE_CONFIG } from '../../constants';
import { useSearchResultListCommonStyles } from './searchResultListCommonStyles';

export const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: 0,
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        color: theme.custom.accentLight[0.7],
    },
}));

const SearchResultListHeader = () => {
    const classes = useStyles();
    const searchResultListCommonClasses = useSearchResultListCommonStyles();

    return (
        <Box className={cx(searchResultListCommonClasses.row, classes.header)} data-testid="SearchResultListHeader">
            {TABLE_CONFIG.map((column, index) => (
                <Typography key={column.key} component="span" data-testid={`table-heading-${index + 1}`}>
                    {column.label}
                </Typography>
            ))}
        </Box>
    );
};

export { SearchResultListHeader };
