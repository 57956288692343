import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';

import LimitSelector from './LimitSelector';
import PageItem from './PageItem';
import ResultCount from './ResultCount';

const useStyles = makeStyles(theme => ({
    text: {
        paddingRight: theme.spacing(1),
    },
    root: {
        fontSize: '1.2rem',
    },
    pager: ({ showItemsCount, showItemsPerPageSelect }) => ({
        borderLeft: showItemsCount ? theme.palette.border.light : 'none',
        borderRight: showItemsPerPageSelect ? theme.palette.border.light : 'none',
        paddingRight: showItemsPerPageSelect ? theme.spacing(0.8) : 0,
        paddingLeft: showItemsCount ? theme.spacing(0.8) : 0,
        marginLeft: showItemsCount ? theme.spacing(0.8) : 0,
        marginRight: showItemsPerPageSelect ? theme.spacing(1.3) : 0,
    }),
}));

const Pager = ({
    count,
    size,
    page,
    itemsPerPage,
    itemsPerPageOptions,
    onPageChange,
    onRefresh,
    onItemsPerPageChange,
    showItemsPerPageSelect,
    showItemsCount,
    showSlimPager,
    showPagination,
    fullWidthLimitSelector,
}) => {
    const classes = useStyles({ showItemsCount, showItemsPerPageSelect });
    const itemExists = !!itemsPerPageOptions.find(item => item.value === itemsPerPage);

    if (!itemExists) {
        itemsPerPageOptions.push({ label: itemsPerPage, value: itemsPerPage });
    }

    itemsPerPageOptions.sort((a, b) => a.value - b.value);

    return (
        <Box alignItems="center" className={classes.root} display="flex">
            {showItemsCount && (
                <ResultCount count={count} itemsPerPage={itemsPerPage} page={page} onRefresh={onRefresh} />
            )}
            {showPagination && (
                <Box>
                    <Pagination
                        boundaryCount={1}
                        className={classes.pager}
                        count={Math.ceil(count / itemsPerPage)}
                        data-cy="resultsPager"
                        page={page}
                        renderItem={item => <PageItem showSlimPager={showSlimPager} {...item} />}
                        shape="rounded"
                        siblingCount={0}
                        size={size}
                        onChange={onPageChange}
                    />
                </Box>
            )}
            {showItemsPerPageSelect && (
                <LimitSelector
                    isFullWidth={fullWidthLimitSelector}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageOptions={itemsPerPageOptions}
                    onItemsPerPageChange={onItemsPerPageChange}
                />
            )}
        </Box>
    );
};

Pager.propTypes = {
    boundaryCount: PropTypes.number,
    count: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number,
    itemsPerPageOptions: PropTypes.array,
    onItemsPerPageChange: PropTypes.func,
    onPageChange: PropTypes.func.isRequired,
    onRefresh: PropTypes.func,
    page: PropTypes.number,
    showItemsCount: PropTypes.bool,
    showItemsPerPageSelect: PropTypes.bool,
    showSlimPager: PropTypes.bool,
    size: PropTypes.string,
    showPagination: PropTypes.bool,
    fullWidthLimitSelector: PropTypes.bool,
};

Pager.defaultProps = {
    boundaryCount: 1,
    itemsPerPage: 24,
    itemsPerPageOptions: [
        { label: 8, value: 8 },
        { label: 12, value: 12 },
        { label: 16, value: 16 },
        { label: 24, value: 24 },
        { label: 32, value: 32 },
        { label: 48, value: 48 },
        { label: 64, value: 64 },
    ],
    onItemsPerPageChange: () => null,
    onRefresh: () => null,
    page: 1,
    showItemsCount: true,
    showItemsPerPageSelect: true,
    showSlimPager: false,
    size: 'small',
    showPagination: true,
    fullWidthLimitSelector: false,
};

export default Pager;
