import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CloseTabIcon } from '@mediabank/assets/icons/close-tab.svg';
import PropTypes from 'prop-types';

const Snackbar = ({ title, subtitle, caption, type, autoHideDuration, children, isOpen, handleClose, ...rest }) => {
    const controlledContent = (
        <>
            {!title && !subtitle && !caption && (
                <Typography className="title" variant="subtitle2">
                    Unknown error
                </Typography>
            )}
            {title && (
                <Typography className="title" variant="subtitle2">
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography className="subtitle" variant="subtitle2">
                    {subtitle}
                </Typography>
            )}
            {caption && (
                <Typography className="caption" variant="subtitle2">
                    {caption}
                </Typography>
            )}
        </>
    );

    return (
        <MuiSnackbar autoHideDuration={autoHideDuration} className={type} open={isOpen} onClose={handleClose} {...rest}>
            <>
                {!children ? controlledContent : children}
                <CloseTabIcon className="close-icon" role="button" onClick={handleClose} />
            </>
        </MuiSnackbar>
    );
};

Snackbar.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    caption: PropTypes.string,
    type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    autoHideDuration: PropTypes.number,
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
};

Snackbar.defaultProps = {
    title: '',
    subtitle: '',
    caption: '',
    type: 'error',
    autoHideDuration: null,
    children: null,
    isOpen: false,
};

export default Snackbar;
