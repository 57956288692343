import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    <Route {...rest} render={props => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />)} />
);

ProtectedRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.func.isRequired,
};

export { ProtectedRoute };
