import { assetsService } from '@mediabank/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api';
import { DEFAULT_SEARCH_PARAMS } from '../../constants';
import { checkShouldForceLogout } from '../../utils/checkShouldForceLogout';
import {
    searchAssetsBySmartSearchIdSucceeded,
    searchAssetsFailed,
    searchAssetsLoading,
    searchAssetsSucceeded,
} from '../actions/assetList';

const searchAssets = createAsyncThunk('assets/search', async (params = {}, { dispatch }) => {
    dispatch(searchAssetsLoading(true));

    try {
        const { shouldReset, ...restParams } = params;

        const paramsMap = {
            ...DEFAULT_SEARCH_PARAMS,
            ...restParams,
        };

        const { data: assetData } = await assetsService.search(paramsMap);
        const { page } = paramsMap;

        dispatch(searchAssetsSucceeded({ assetData, page, shouldReset }));
        dispatch(searchAssetsLoading(false));

        return assetData;
    } catch (error) {
        if (checkShouldForceLogout(error)) {
            api.logout();

            return;
        }

        console.error(error);

        dispatch(searchAssetsFailed(error));
        dispatch(searchAssetsLoading(false));
    }
});

const searchAssetsByFilters = createAsyncThunk(
    'assets/searchAssetsByFilters',
    async ({ attributes, page = 1, shouldReset = false }, { dispatch }) => {
        // not every quick filter has actual 'keyword' to filter by
        const keyword = attributes.smartsearchtext || undefined;

        const params = {
            application: 'library',
            filters: attributes.smartsearchfilters,
            keyword,
            limit: 12,
            matchType: 'all',
            page,
        };

        dispatch(searchAssetsLoading(true));

        try {
            const { data: assetData } = await assetsService.search(params);
            const { page } = params;

            dispatch(searchAssetsSucceeded({ assetData, page, shouldReset }));
            dispatch(searchAssetsLoading(false));
        } catch (error) {
            if (checkShouldForceLogout(error)) {
                api.logout();

                return;
            }

            console.error(error);

            dispatch(searchAssetsFailed(error));
            dispatch(searchAssetsLoading(false));
        }
    }
);

const searchAssetsBySmartSearchId = createAsyncThunk(
    'assets/searchAssetsBySmartSearchId',
    async ({ smartSearchId, page = 1, shouldReset = false }, { dispatch, getState }) => {
        const {
            assetList: { assets: loadedAssets },
        } = getState();

        const params = {
            application: 'library',
            limit: 12,
            page,
            smartsearchids: [smartSearchId],
            offset: shouldReset || page === 1 ? 0 : loadedAssets.length,
        };

        dispatch(searchAssetsLoading(true));

        try {
            const { page } = params;
            const data = await api.searchAssets(params);

            // The legacy API gives back the assets in a slightly different format than the assetService
            // so we have to map the data to the same format to achieve consistency
            const mappedAssetData = {
                ...data,
                assets: data.assets.map(asset => ({
                    type: 'assets',
                    id: String(asset.assetid),
                    attributes: asset,
                })),
            };

            dispatch(searchAssetsBySmartSearchIdSucceeded({ assetData: mappedAssetData, page, shouldReset }));
            dispatch(searchAssetsLoading(false));
        } catch (error) {
            if (checkShouldForceLogout(error)) {
                api.logout();

                return;
            }

            console.error(error);

            dispatch(searchAssetsFailed(error));
            dispatch(searchAssetsLoading(false));
        }
    }
);

export { searchAssets, searchAssetsByFilters, searchAssetsBySmartSearchId };
