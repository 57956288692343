import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles, NotificationsContext } from '@mediabank/uikit';

import api from '../api';
import { SearchResultContainer } from '../containers/SearchResult.container';
import { SearchTypeSelectionContainer } from '../containers/SearchTypeSelection';
import { importFiles } from '../utils/cep';

const useStyles = makeStyles(theme => ({
    searchPageContainer: {
        height: '100%',
        minHeight: ({ searchTypeContentHeight }) =>
            `calc(${searchTypeContentHeight}px +
                var(--minAppHeight) -
                var(--accountContainerHeight) -
                var(--navigationContainerHeight)
            )`,
        overflow: 'hidden',
    },
    footerButtonSpacing: {
        marginLeft: theme.spacing(1),
    },
}));

const SearchPage = () => {
    const [searchTypeContentHeight, setSearchTypeContentHeight] = useState(0);

    const classes = useStyles({ searchTypeContentHeight });

    const { addToastError, addToastSuccess, addToastWarning } = useContext(NotificationsContext);

    const selectedAssetsById = useSelector(state => state.assetList.selectedAssetsById);

    const scrollableContainerRef = useRef(null);

    const setSearchTypeContent = height => {
        setSearchTypeContentHeight(height);
    };

    const getHardLink = async assetId => {
        try {
            const response = await api.createHardLink(assetId);

            if (response.error) {
                console.error(response.error);
            }

            return response;
        } catch (e) {
            addToastError({ title: e.message });

            return false;
        }
    };

    const importSelectedAssets = async assetId => {
        const selectedAssetIds = Object.keys(selectedAssetsById);

        // In case if there are no selected assets,
        // we just want to import the drag-and-dropped asset right away
        const assetIdsToImport = selectedAssetIds.length === 0 ? [assetId] : selectedAssetIds;

        const paths = await Promise.all(
            assetIdsToImport.map(async assetId => {
                const response = await getHardLink(assetId);

                if (!response) {
                    return false;
                }

                const { data = {} } = response || {};

                const windowsOSPath = data.unc_file_path;
                const macOSPath = data.macos_file_path;

                return { windowsOSPath, macOSPath };
            })
        );

        // TODO: when developers get the ability to test importing
        //       we should refine the error handling & messaging
        //       https://mbnepms.atlassian.net/browse/MBX-377

        const failed = paths.filter(p => p === false);
        const succeeded = paths.filter(p => p);

        if (succeeded.length) {
            return importFiles(succeeded, results => {
                if (failed.length) {
                    addToastWarning({
                        title: `Requesting ${succeeded.length}/${paths.length} files.`,
                        subtitle: `(${failed.length} contained an error)`,
                    });
                } else if (results > 0) {
                    addToastSuccess({ title: `Requesting ${results} files` });
                }
            });
        }
        // else, no files succeeded

        addToastError({
            title: 'Not importing any files.',
        });
    };

    return (
        <Box className={classes.searchPageContainer}>
            <SearchTypeSelectionContainer
                scrollableContainerRef={scrollableContainerRef}
                setSearchTypeContentHeight={setSearchTypeContent}
            />
            <SearchResultContainer
                importSelectedAssets={importSelectedAssets}
                scrollableContainerRef={scrollableContainerRef}
                searchTypeContentHeight={searchTypeContentHeight}
            />
        </Box>
    );
};

export { SearchPage };
