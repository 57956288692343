import { basicColors, commonColors } from '../common';
import { makeColors } from '../themeFunctions';

const neutral = {
    neutral000: '#ffffff',
    neutral100: '#fafafa',
    neutral200: '#f4f4f4',
    neutral300: '#f0f0f0',
    neutral400: '#e3e3e3',
    neutral500: '#d1d1d1',
    neutral600: '#bec0c1',
    neutral700: '#b0b5b3',
    neutral800: '#767676',
    neutral900: '#3d3d3d',
};

const palette = {
    ...commonColors,
    ...commonColors.sys,
    ...commonColors.download,
    ...commonColors.live,
    ...neutral,
    accentBright: makeColors('#02d3fa'),
    accentLight: makeColors('#3d3d3c'),
    accentMedium: makeColors('#1eb8d2'),
    accentDark: makeColors('#cccfd0'),
    productBase: makeColors(neutral.neutral000),
    springGreen: makeColors(basicColors.greenReady),
    lightContrast: neutral.neutral000,
    darkContrast: neutral.neutral900,
    borderLight: makeColors('#3d3d3c')[0.2],

    icon: {
        main: '#7b7a7a',
        hover: '#41c3da',
        disabled: '#b0b3b5',
        light: '#e9e9e9',
    },
    timeline: {
        // same as dark
        hoveredTab: neutral.neutral300,
        tab: neutral.neutral300,
        text: '#46274d',
        overlay: '#2b3563',
        video: '#004954',
        image: '#335943',
        audio: '#59542d',
        commentary: '#643932',
        soundWave: '#707070',
    },
    modal: {
        background: neutral.neutral000,
    },
    dialog: {
        background: neutral.neutral600,
    },
    dividers: {
        border: neutral.neutral400,
    },
};

export default palette;
