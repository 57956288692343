export default (theme, colors) => ({
    sizeLarge: {
        padding: theme.spacing(1),
    },
    label: {
        whiteSpace: 'nowrap',
        fontWeight: '500',
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
    },
    text: {
        background: 'transparent',
        color: theme.id === 'light' ? colors.accentMedium[1] : colors.accentBright[1],
        '&:hover': {
            background: 'transparent',
        },
        '&:active': {
            background: 'transparent',
        },
        '&:focus': {
            border: 'none',
        },
        '&:disabled': {
            color: colors.accentBright[1],
            opacity: 0.15,
        },
    },
    contained: {
        color: colors.accentLight[1],
        padding: theme.spacing(0.75, 1.5),
        border: 'solid 1px transparent',
        backgroundColor: colors.accentLight[0.15],
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: colors.accentLight[0.2],
            boxShadow: 'none',
        },
        '&:active': {
            backgroundColor: colors.accentMedium[1],
        },
        '&:focus': {
            border: `solid 1px ${colors.focused[1]}`,
        },
        '&:disabled': {
            backgroundColor: colors.accentLight[0.1],
            color: colors.accentLight[0.2],
        },
    },
    containedPrimary: {
        color: colors.accentLight[1],
        background: colors.accentMedium[1],
        '&:hover': {
            background: colors.accentDark[0.2],
        },
        '&:active': {
            background: colors.accentMedium[1],
        },
        '&:focus': {
            border: `solid 1px ${colors.focused[1]}`,
        },
        '&:disabled': {
            background: colors.accentMedium[1],
            opacity: 0.6,
        },
    },
});
