import { combineReducers } from '@reduxjs/toolkit';

import { userLogoutSucceeded } from '../actions/user';
import { assetList } from './assetList';
import { config } from './config';
import { form } from './form';
import { ui } from './ui';
import { user } from './user';

const appReducer = combineReducers({
    assetList,
    config,
    form,
    ui,
    user,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === userLogoutSucceeded.type) {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
