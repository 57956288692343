import { url } from './url';
import httpClient from '../common/http';

const ssoHeaders = {
    catch: 22,
};

const timeout = 8000;
const ssoApi = new httpClient(url, timeout);
export { ssoApi, ssoHeaders };
