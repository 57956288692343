import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSize } from 'react-use';
import { Box } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { QuickFilterItem } from './QuickFilterItem';

const useStyles = makeStyles(theme => ({
    filterPills: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        position: 'relative',
        height: '2.8rem',
    },
    loading: {
        height: theme.spacing(3.5),
        width: theme.spacing(20),
        borderRadius: theme.spacing(2),
    },
}));

const QuickFilterList = ({ activateFilter, pillsWidth, quickFilters, setPillsWidth }) => {
    const classes = useStyles();

    const [pillSizesById, setPillSizesById] = useState({});
    const [visibleQuickFilterCount, setVisibleQuickFilterCount] = useState(0);

    const activeQuickFilterId = useSelector(state => state.ui.searchAssets.searchTypes.quickFilter);
    const activeSearchType = useSelector(state => state.ui.searchAssets.activeSearchType);

    const setPillSize = (id, size) => {
        setPillSizesById({
            ...pillSizesById,
            [id]: size,
        });
    };

    const [sizedQuickFilterList, { width }] = useSize(
        () => (
            <Box className={classes.filterPills} data-testid="quickfilters-horizontal-list">
                {quickFilters.map((quickFilter, index) => {
                    const { id } = quickFilter;
                    const hidden = index >= visibleQuickFilterCount;
                    const isActive = activeQuickFilterId === id && activeSearchType === 'quickFilter';

                    return (
                        <QuickFilterItem
                            key={`quick-filter-item-${id}`}
                            hidden={hidden}
                            quickFilter={quickFilter}
                            selected={isActive}
                            setPillSize={setPillSize}
                            storedWidth={pillSizesById[id]}
                            onClick={() => activateFilter(quickFilter, isActive)}
                        />
                    );
                })}
            </Box>
        ),
        { width: '', height: '' }
    );

    useEffect(() => {
        if (width !== '') {
            const displayablePillsLengths = quickFilters
                .reduce((acc, item) => {
                    if (pillSizesById[item.id] !== 0) {
                        acc.push((acc[acc.length - 1] || 0) + pillSizesById[item.id]);
                    }

                    return acc;
                }, [])
                .filter(item => item <= width);

            const currentMaxWidth = Math.max(...displayablePillsLengths);
            if (pillsWidth !== currentMaxWidth) {
                setVisibleQuickFilterCount(displayablePillsLengths.length);
                setPillsWidth(currentMaxWidth);
            }
        }
    }, [pillSizesById, quickFilters, setPillsWidth, width, pillsWidth]);

    return <Box pr={1}>{sizedQuickFilterList}</Box>;
};

QuickFilterList.propTypes = {
    activateFilter: PropTypes.func.isRequired,
    pillsWidth: PropTypes.number.isRequired,
    quickFilters: PropTypes.array.isRequired,
    setPillsWidth: PropTypes.func.isRequired,
};

export { QuickFilterList };
