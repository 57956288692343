import React from 'react';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ChevronDown } from '@mediabank/assets/icons/chevron-down.svg';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    wrapper: ({ isFullWidth }) => ({
        width: isFullWidth ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
    text: {
        paddingRight: theme.spacing(1),
    },
}));

const useSelectStyles = makeStyles(() => ({
    select: {
        padding: 4,
        fontSize: '1.2rem',
    },
    icon: {
        top: 9,
    },
}));

const LimitSelector = ({ itemsPerPage, itemsPerPageOptions, onItemsPerPageChange, isFullWidth }) => {
    const classes = useStyles({ isFullWidth });
    const selectClasses = useSelectStyles();
    const itemExists = !!itemsPerPageOptions.find(item => item.value === itemsPerPage);

    if (!itemExists) {
        itemsPerPageOptions.push({ label: itemsPerPage, value: itemsPerPage });
    }

    itemsPerPageOptions.sort((a, b) => a.value - b.value);

    return (
        <div className={classes.wrapper}>
            <Box>
                <Typography className={classes.text} variant="inherit">
                    Items per page:
                </Typography>
            </Box>
            <Box style={{ marginLeft: 'auto' }}>
                <Select
                    classes={selectClasses}
                    data-cy="pagerItemsPerPage"
                    IconComponent={ChevronDown}
                    value={itemsPerPage}
                    variant="outlined"
                    onChange={onItemsPerPageChange}
                >
                    {itemsPerPageOptions.map(option => (
                        <MenuItem key={option.value} className={selectClasses.select} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </div>
    );
};

LimitSelector.propTypes = {
    itemsPerPage: PropTypes.number,
    onItemsPerPageChange: PropTypes.func.isRequired,
    itemsPerPageOptions: PropTypes.array,
    isFullWidth: PropTypes.bool.isRequired,
};

LimitSelector.defaultProps = {
    itemsPerPage: 25,
    itemsPerPageOptions: [
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 75, value: 75 },
        { label: 100, value: 100 },
    ],
};

export default LimitSelector;
