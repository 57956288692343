import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { Centered } from '@mediabank/uikit';

import { ErrorHandler } from '../components/ErrorHandler';
import { ImplicitCallback } from '../components/ImplicitCallback';
import { ProtectedRouteContainer } from '../containers/ProtectedRoute.container';
import { Page_Not_Found } from '../messages';
import { ExportPage } from '../pages/ExportPage';
import { LoginPage } from '../pages/LoginPage';
import { SearchPage } from '../pages/SearchPage';

export const NoMatch = () => (
    <Centered>
        <Typography color="textPrimary" variant="h5">
            {Page_Not_Found}
        </Typography>
    </Centered>
);

const AppRoutes = () => (
    <Box className="app">
        <ErrorHandler>
            <Switch>
                <Route component={LoginPage} path="/login" />
                <Route component={ImplicitCallback} path="/callback" />
                <ProtectedRouteContainer component={ExportPage} path="/export" />
                <ProtectedRouteContainer component={SearchPage} path="/search" />
                <ProtectedRouteContainer component={SearchPage} exact={true} path="/" />
                <Route component={NoMatch} />
            </Switch>
        </ErrorHandler>
    </Box>
);

export { AppRoutes };
