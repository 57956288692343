import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api';
import { checkShouldForceLogout } from '../../utils/checkShouldForceLogout';
import {
    formFetchFailed,
    formFetchSucceeded,
    metaValuesFetchFailed,
    metaValuesFetchStarted,
    metaValuesFetchSucceeded,
} from '../actions/form';
import { setIsFetchingForm } from '../actions/ui';

const fetchFormByKey = createAsyncThunk('form/fetchFormByKey', async (key = '', { dispatch }) => {
    dispatch(setIsFetchingForm(true));

    try {
        const response = await api.fetchForm({ key });

        dispatch(formFetchSucceeded(response));
    } catch (error) {
        if (checkShouldForceLogout(error)) {
            api.logout();

            return;
        }

        console.error(error);

        dispatch(formFetchFailed(error));
    } finally {
        dispatch(setIsFetchingForm(false));
    }
});

const fetchMetaValues = createAsyncThunk('form/fetchMetaValues', async (key = '', { dispatch }) => {
    // This is currently only for debugging
    // TODO implement loading state
    dispatch(metaValuesFetchStarted);

    try {
        const response = await api.fetchMetaValues(key);

        dispatch(metaValuesFetchSucceeded(response));
    } catch (error) {
        if (checkShouldForceLogout(error)) {
            api.logout();

            return;
        }

        console.error(error);

        dispatch(metaValuesFetchFailed(error.message));
    }
});

export { fetchFormByKey, fetchMetaValues };
