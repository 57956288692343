export default (theme, colors) => ({
    groupedContainedHorizontal: {
        marginRight: '0.1rem',
        '&:not(:last-child)': {
            borderRight: '1px solid unset',
        },
    },
    contained: {
        boxShadow: 'none',
    },
});
