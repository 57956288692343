import httpClient from '../common/http';

const url = process.env.REACT_APP_JOBS_API_URL;

const timeout = 8000;
const api = new httpClient(url, timeout);

let currentAuthToken = null;
const setToken = token => (currentAuthToken = token);

const jobsHeaders = () => ({
    /*  NOTE:
        When sending application/json as an Accept-header, stateCount and totalItems
        are not part of the response. In Job Monitor we need this data for the JobMonitorLink-component.
        This means that if we want to stop using Hydra, selector-methods will need to be created to parse this
        data from the JSON-response.
    */
    //Accept: contentTypeList.json, <- Decomment to turn off Hydra
    Authorization: `Bearer ${currentAuthToken}`,
});

const fetchJobs = () => api.get(`/jobs`, { headers: jobsHeaders() });
const fetchJobsWithPage = page => api.get(`/jobs?page=${page}`, { headers: jobsHeaders() });

export default { fetchJobs, fetchJobsWithPage, setToken };
