import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, PanelTab } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { SEARCH_TYPES } from '../../constants';

const useStyles = makeStyles(theme => ({
    searchTypeSelector: {
        height: 'var(--searchTypeSelectorHeight)',
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.custom.accentLight[0.2]}`,
        '& button': {
            margin: theme.spacing(0, 1.5),
        },
    },
}));

const SearchTypeSelector = ({ selectedSearchTypeId, setSelectedSearchTypeId }) => {
    const classes = useStyles();

    return (
        <Box className={classes.searchTypeSelector} data-testid="searchTypeSelector">
            {Object.values(SEARCH_TYPES).map(searchType => (
                <PanelTab
                    key={searchType.id}
                    label={searchType.text}
                    selected={searchType.id === selectedSearchTypeId}
                    variant="level2"
                    onClick={() => setSelectedSearchTypeId(searchType.id)}
                />
            ))}
        </Box>
    );
};

SearchTypeSelector.propTypes = {
    selectedSearchTypeId: PropTypes.number.isRequired,
    setSelectedSearchTypeId: PropTypes.func.isRequired,
};

export { SearchTypeSelector };
