import { decodeToken } from '@mediabank/utils';
import { createReducer } from '@reduxjs/toolkit';

import { getTokenFromLocalStorage } from '../../utils/jwt';
import {
    setToken,
    setTokenIsValidated,
    userLoginFailed,
    userLoginLoading,
    userLoginSucceeded,
    userLogoutSucceeded,
} from '../actions/user';

export const initialState = {
    error: null,

    // this will be `true` if we have a token in `localStorage`
    // when the app loads
    isUserLoginLoading: !!getTokenFromLocalStorage(),

    profile: null,
    token: getTokenFromLocalStorage(),
    tokenIsValidated: false,
};

const user = createReducer(initialState, {
    [setToken]: (state = initialState, { payload }) => {
        state.token = payload;
    },
    [setTokenIsValidated]: (state = initialState, { payload }) => {
        state.tokenIsValidated = payload;
    },
    [userLoginFailed]: (state = initialState, { payload }) => {
        state.error = payload;
    },
    [userLoginLoading]: (state = initialState, { payload }) => {
        state.isUserLoginLoading = payload;
    },
    [userLoginSucceeded]: (state = initialState, { payload }) => {
        state.error = null;
        state.token = payload;

        const decodedToken = decodeToken(payload);

        const { user = {} } = decodedToken || {};

        state.profile = user;
    },
    [userLogoutSucceeded]: (state = initialState) => {
        state.token = '';
        state.tokenIsValidated = false;
        state.profile = null;
    },
});

export { user };
