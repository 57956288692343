// import { createMuiTheme } from '@material-ui/core/styles' will fail tests for some reason
// eslint-disable-next-line no-restricted-imports
import { createTheme } from '@mui/material/styles';
import { themeProps as props, typography } from '../common';
import { rgba2rgb } from '../themeFunctions';
import overrides from '../overrides';
import defaultColors from '../light/colors';

const theme = createTheme();

// We need a defaultColors, because in test mode the colors would be undefined.
// Colors.* crashing the test process

export default (colors = defaultColors) => ({
    themeName: 'Light Theme',
    id: 'light',
    palette: {
        common: {
            white: colors.lightContrast,
            black: colors.productBase[1],
            tooltipText: colors.neutral300, // not used in the project
            loginColor: colors.loginPage.color,
            loginBackground: colors.loginPage.background,
            icon: colors.lightContrast,
        },
        primary: {
            main: colors.accentMedium[1],
            light: colors.accentBright[1],
            dark: colors.accentBright[1], // not used in the project
            contrastText: colors.contrastText, // not used in the project
        },
        secondary: {
            main: colors.sysGreen[1],
        },
        background: {
            paper: rgba2rgb(colors.productBase[1], colors.accentLight[1], 0.08),
            dateThumbnail: colors.liveGreen[1],
            default: colors.productBase[1],
            root: colors.accentDark[1],
            input: colors.accentDark[0.4],
            downloadProgress: colors.downloadProgress,
            downloadReady: colors.downloadReady,
            downloadWaiting: colors.downloadWaiting,
            downloadFailed: colors.downloadFailed,
        },
        text: {
            primary: colors.neutral900,
            secondary: colors.neutral800,
            tertiary: colors.accentLight[0.9],
            disabled: colors.neutral700,
            hover: colors.neutral800, // not used in the project
        },
        icon: {
            light: colors.neutral800,
            active: colors.accentMedium[1],
            hover: colors.neutral400,
            dark: colors.neutral300, // not used in the project
        },
        border: {
            light: `1px solid ${colors.borderLight}`,
            dark: colors.neutral700,
            hover: colors.neutral600, // not used in the project
            main: colors.neutral900,
            accent: colors.accentMedium[1],
        },
        action: {
            focus: colors.focused[1],
            hover: colors.accentLight[0.08],
        },
        warning: {
            main: colors.sysYellow[1],
        },
        error: {
            main: colors.sysRed[1],
        },
        info: {
            main: colors.sysBlue[1],
        },
        success: {
            main: colors.sysGreen[1],
        },
        outlined: {
            text: colors.accentMedium[1],
            background: colors.neutral300,
        },
        leftMenu: {
            background: colors.neutral600,
        },
        buttonBackground: {
            primary: colors.neutral600, // not used in the project
            search: colors.neutral300, // not used in the project
            hover: colors.neutral400, // not used in the project
        },
        buttonSecondary: {
            text: colors.neutral100,
            background: colors.neutral700,
        },
        opacity: {
            sidebar: 1, // not used in the project
        },
        type: 'light', // not used in the project
    },
    custom: colors,
    layout: {
        line: {
            height: '1px',
        },
    },
    props,
    typography,
    ...overrides(theme, colors),
});
