import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '1.2rem',
        padding: theme.spacing(0, 1.5),
        color: theme.custom.accentLight[0.6],
    },
}));

const SelectPlaceholder = ({ innerProps, children }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.root} color="textSecondary" {...innerProps}>
            {children}
        </Typography>
    );
};

SelectPlaceholder.defaultProps = {
    innerProps: {},
};

SelectPlaceholder.propTypes = {
    children: PropTypes.node.isRequired,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

export default SelectPlaceholder;
