import { MetaDatePicker, MetaInput, MetaSelect } from '../components/MetaForm';
/**
 * convert Adex form inputs to a format supported by semantic ui
 * @param {*} data
 */
export const getSemanticInputFromAdexInput = data => {
    const validators = data.validators || [];
    const requiredValidators = validators.filter(validator => validator.type === 'required');
    const numericValidators = validators.filter(validator => validator.type === 'number');
    const isRequired = !!requiredValidators.length;
    const isNumeric = !!numericValidators.length;
    const isHidden = data.type === 'Hidden';

    const { settings = {} } = data;

    const map = {
        Text: {
            element: 'Input',
            type: 'text',
            hidden: isHidden,
            name: data.name,
            label: data.label,
            tooltip: data.tooltip,
            required: isRequired,
            numeric: isNumeric,
            defaultValue: data.default,
        },
        Select: {
            element: 'Select',
            name: data.name,
            hidden: isHidden,
            defaultValue: data.default,
            endpoint: settings.preset === 'CLIENT_ASSET_META_KEYS' ? '/ajax/asset/get_metavalues.php' : '',
            required: isRequired,
            label: data.label,
            tooltip: data.tooltip,
            allowAdditions: allowAdditions(settings.preset),
            multiple: settings.viewType === 'multival',
            options: mapOptionsToSemantic(data.options || []),
            clearable: true,
        },
    };

    return map[data.type] || map.Text;
};

/**
 * Decides if a user should be able to create new options
 * @param {*} preset
 */
export const allowAdditions = preset => preset !== 'META_DATA';

/**
 * Returns correct form element depending on the given type
 * @param {*} type
 */
export const getFormElement = (element, name) => {
    // switch (type) {
    //     case 'Select':
    //         return MetaSelect;
    //     case 'Input':
    //     default:
    //         return MetaDatePicker;
    // }
    if (element === 'Select') {
        return MetaSelect;
    }
    if (element === 'Input' && name === 'date') {
        return MetaDatePicker;
    }

    return MetaInput;
};

/**
 * Since we have different format depending on if options are fetched from CustomKeyValues
 * or if they are defined directly in adex form we need to map them.
 * @param {Object or Array} options
 */
export const mapOptionsToSemantic = options =>
    !Array.isArray(options) ? mapCustomKeyValuesToSemantic(options) : mapAdexOptionsToSemantic(options);

export const mapAdexOptionsToSemantic = options =>
    options.map(option => ({ key: option.val, value: option.val, text: option.label }));

export const mapMetaValuesToSemantic = values => values.map(value => ({ key: value, value, text: value }));

export const mapCustomKeyValuesToSemantic = options => {
    const data = Object.entries(options)
        .filter(option => option[0] !== '-1')
        .map(option => ({ key: option[0], value: option[0], text: option[1] }));

    return data;
};
