import { makeStyles } from '@mediabank/uikit';

export const useSearchResultListCommonStyles = makeStyles(theme => ({
    row: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        minHeight: theme.spacing(5.75),
        border: `1px solid ${theme.palette.background.default}`,
        borderRadius: theme.spacing(0.75),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        backgroundColor: theme.custom.accentDark[0.4],
        '& > span': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            margin: theme.spacing(1.25),
            justifySelf: 'left',
            alignSelf: 'center',
            wordBreak: 'break-word',
            lineHeight: '1.17',
            fontSize: '12px',
            marginTop: 0,
            marginBottom: 0,
            '& > svg': {
                marginRight: theme.spacing(0.5),
                '&:last-of-type': {
                    marginRight: 0,
                },
            },
        },
    },
}));
