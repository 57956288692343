import React from 'react';
import { Paper, Typography } from '@material-ui/core/';
import { Close } from '@material-ui/icons/';
import cx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { makeStyles } from '../MakeStyles';

const useStyles = makeStyles(theme => ({
    base: {
        position: 'relative',
        minHeight: theme.spacing(8),
        width: ({ isCopyLinkMessage }) => (isCopyLinkMessage ? theme.spacing(53) : theme.spacing(33)),
        padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderRadius: `${theme.spacing(0.75)}px`,
        borderLeft: `${theme.spacing(0.75)}px solid transparent`,
        backgroundColor: theme.palette.background.paper,
    },
    icon: {
        position: 'absolute',
        right: theme.spacing(1.5),
        color: theme.palette.icon.light,
        cursor: 'pointer',
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: '1.4rem',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    subtitle: {
        color: theme.id === 'light' ? theme.palette.text.secondary : 'rgba(255, 255, 255, 0.7)',
        fontSize: '1.2rem',
        marginBottom: theme.spacing(2),
    },
    caption: {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '1.2rem',
    },
    error: {
        borderColor: theme.palette.error.main,
    },
    success: {
        borderColor: theme.palette.success.main,
    },
    info: {
        borderColor: theme.palette.info.main,
    },
    warning: {
        borderColor: theme.palette.warning.main,
    },
}));

const NotificationContent = ({ caption, notificationKey, subtitle, title, variant }) => {
    const isCopyLinkMessage = title?.indexOf('Link') !== -1;

    const classes = useStyles({ isCopyLinkMessage });

    const { closeSnackbar } = useSnackbar();

    const handleClose = () => closeSnackbar(notificationKey);

    return (
        <Paper className={cx(classes.base, classes[variant])} elevation={3}>
            <Close className={classes.icon} onClick={handleClose} />
            {title && <Typography className={classes.title}>{title}</Typography>}
            {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
            {caption && <Typography className={classes.caption}>{caption}</Typography>}
        </Paper>
    );
};

NotificationContent.defaultProps = {
    caption: '',
    subtitle: '',
    title: '',
};

NotificationContent.propTypes = {
    caption: PropTypes.string,
    notificationKey: PropTypes.number.isRequired,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.oneOf(['error', 'success', 'info', 'warning']).isRequired,
};

export { NotificationContent };
