import React from 'react';
import { IconButton as MuiIconButton, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0.5),
        fontSize: '1.4rem',
        lineHeight: 1,
        border: 'solid 1px transparent',
        color: theme.palette.text.primary,
        '&:active': {
            backgroundColor: theme.custom.accentDark[0.6],
        },
        '&:focus': {
            borderColor: theme.palette.action.focus,
        },
        '&:disabled': {
            color: theme.palette.text.primary,
            backgroundColor: props =>
                props.variant === 'light' ? theme.custom.accentLight[0.15] : theme.custom.accentDark[0.3],
            opacity: 0.15,
        },
    },
    light: {
        backgroundColor: theme.custom.accentLight[0.15],
        '&:hover': {
            backgroundColor: theme.custom.accentLight[0.2],
        },
        '&:disabled': {
            backgroundColor: theme.custom.accentLight[0.15],
        },
    },
    dark: {
        backgroundColor: theme.custom.accentDark[0.3],
        '&:hover': {
            backgroundColor: theme.custom.accentLight[0.05],
        },
        '&:disabled': {
            backgroundColor: theme.custom.accentDark[0.3],
        },
    },
    hollow: {
        padding: theme.spacing(0),
        fontSize: '1.8rem',
        '&:hover': {
            backgroundColor: theme.custom.accentLight[0.05],
        },
    },
    hollowDisabled: {
        padding: theme.spacing(0),
        fontSize: '1.8rem',
        color: theme.custom.accentLight[0.4],
    },
}));

const IconButton = React.forwardRef(
    ({ onClick, children, disabled, type, variant, className, ...forwardProps }, ref) => {
        const classes = useStyles();

        const onClickHandler = e => {
            if (onClick && !disabled) {
                onClick(e);
            }
        };

        return (
            <MuiIconButton
                ref={ref}
                className={cx(classes.root, className, {
                    [classes.light]: variant === 'light',
                    [classes.dark]: variant === 'dark',
                    [classes.hollow]: variant === 'hollow',
                    [classes.hollowDisabled]: variant === 'hollowDisabled',
                })}
                disabled={disabled}
                type={type}
                onClick={onClickHandler}
                {...forwardProps}
            >
                {children}
            </MuiIconButton>
        );
    }
);

IconButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    className: PropTypes.string,
    type: PropTypes.oneOf(['reset', 'button', 'submit']),
    variant: PropTypes.oneOf(['light', 'dark', 'hollow', 'hollowDisabled']),
};

IconButton.defaultProps = {
    onClick: () => null,
    look: 'default',
    disabled: false,
    className: '',
    type: 'button',
    variant: 'light',
};

export default IconButton;
