import { createMuiTheme } from '@material-ui/core';
import { mbTheme } from '@mediabank/theme';

import { premiereColors } from './colors';

const premiereTheme = { ...mbTheme[0] };

premiereTheme.overrides.MuiOutlinedInput.root = {
    ...premiereTheme.overrides.MuiOutlinedInput.root,
    '&$error $notchedOutline': {
        borderColor: premiereColors.sysRed,
    },
};

premiereTheme.overrides.MuiAlert.outlinedError = {
    ...premiereTheme.overrides.MuiAlert.outlinedError,
    '& .MuiAlert-icon': {
        color: premiereColors.sysRed,

        '& svg path': {
            fill: premiereColors.sysRed,
        },
    },
};

export default createMuiTheme(premiereTheme);
