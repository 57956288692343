export default (theme, colors) => ({
    root: {
        height: 0,
        color: colors.accentBright[1],
    },
    rail: {
        backgroundColor: colors.accentDark[0.5],
    },
    markLabel: {
        color: colors.accentLight[0.8],
    },
});
