import { createReducer, createSelector } from '@reduxjs/toolkit';

import { formFetchFailed, formFetchSucceeded, metaValuesFetchSucceeded, setFormValues } from '../actions/form';

export const initialState = {
    fieldsByName: {},
    formValues: {},
    schema: {},
    sections: {},
};

const form = createReducer(initialState, {
    [formFetchFailed]: state => {
        state.fieldsByName = {};
        state.schema = {};
        state.sections = {};
    },
    [formFetchSucceeded]: (state, { payload }) => {
        state.schema = payload.schema || {};
        state.sections = payload.sections || {};

        const { fields = [] } = payload.schema || {};

        const fieldsByName = {};
        const formValues = {};

        fields.forEach(field => {
            fieldsByName[field.name] = field;

            if (String(field.type).toLowerCase() === 'hidden') {
                formValues[field.name] = field.default;
            }
        });

        state.fieldsByName = fieldsByName;
        state.formValues = formValues;
    },
    [metaValuesFetchSucceeded]: (state, { payload }) => {
        state.fieldsByName[payload.name].options = payload.options;
    },
    [setFormValues]: (state, { payload }) => {
        state.formValues[payload.key] = Array.isArray(payload.value) ? payload.value.join(',') : payload.value;
    },
});

const fieldsByNameSelector = state => state.form.fieldsByName;
const formValuesSelector = state => state.form.formValues;

const requiredFieldsSelector = createSelector(fieldsByNameSelector, fieldsByName => {
    const requiredFields = [];

    Object.values(fieldsByName).forEach(fieldsObj => {
        if (
            Array.isArray(fieldsObj.validators) &&
            fieldsObj.validators.length &&
            fieldsObj.validators.some(el => typeof el === 'object' && el.type === 'required')
        ) {
            requiredFields.push(fieldsObj.name);
        }
    });

    return requiredFields;
});

const hasFilledRequiredFieldsSelector = createSelector(
    formValuesSelector,
    requiredFieldsSelector,
    (formValues, requiredFields) => requiredFields.every(field => formValues[field] && formValues[field] !== '')
);

export { form, hasFilledRequiredFieldsSelector };
