import audio_poster from '@mediabank/assets/icons/file-posters/audio_poster.png';
import csv from '@mediabank/assets/icons/file-posters/csv.png';
import doc from '@mediabank/assets/icons/file-posters/doc.png';
import json from '@mediabank/assets/icons/file-posters/json.png';
import key from '@mediabank/assets/icons/file-posters/key.png';
import other from '@mediabank/assets/icons/file-posters/other.png';
import pdf from '@mediabank/assets/icons/file-posters/pdf.png';
import ppt from '@mediabank/assets/icons/file-posters/ppt.png';
import txt from '@mediabank/assets/icons/file-posters/txt.png';
import xls from '@mediabank/assets/icons/file-posters/xls.png';
import xml from '@mediabank/assets/icons/file-posters/xml.png';
import zip from '@mediabank/assets/icons/file-posters/zip.png';

const mimeMap = {
    audio: audio_poster,
    other: other,
    unknown: other,
    'application/pdf': pdf,
    'application/msword': doc,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': doc,
    'application/excel': xls,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': xls,
    'application/vnd.ms-powerpoint': ppt,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ppt,
    'application/xml': xml,
    'application/x-iwork-keynote-sffkey': key,
    'application/json': json,
    'application/zip': zip,
    'text/plain': txt,
    'text/csv': csv,
    'unknown/unknown': other,
};

const getFallbackThumbnail = (mimeType, mimeTypeFull) => {
    if (mimeTypeFull && mimeMap[mimeTypeFull]) {
        return mimeMap[mimeTypeFull];
    }
    if (mimeType === 'audio') {
        return mimeMap.audio;
    }

    return other;
};

export { getFallbackThumbnail };
