import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import api from '../../api';
import { Sign_Out } from '../../messages';
import { userLoginLoading } from '../../store/actions/user';
import logo from './logo.png';

const useStyles = makeStyles(theme => ({
    logo: {
        maxHeight: theme.spacing(2.5),
    },
}));

const AccountBar = () => {
    const classes = useStyles();
    const userName = useSelector(state => (state.user.profile !== null ? state.user.profile.fullname : ''));
    const dispatch = useDispatch();

    const onSignOutButtonClick = () => {
        // ensure that we don't have an infinite loading spinner
        // on the "sign in" button when we return `/login`
        dispatch(userLoginLoading(false));

        api.logout();
    };

    return (
        <Box alignItems="center" display="flex" justifyContent="space-between">
            <img alt="logo" className={classes.logo} src={logo} />
            <Box alignItems="center" display="flex">
                <Typography variant="body2">{userName}</Typography>
                <Button
                    color="default"
                    data-testid="accountBar-sign-out-submit"
                    disabled={false}
                    size="medium"
                    variant="text"
                    onClick={onSignOutButtonClick}
                >
                    {Sign_Out}
                </Button>
            </Box>
        </Box>
    );
};

export { AccountBar };
