import { API_TIMEOUT } from '../../constants';
import { clientConfig } from '../clientConfig/clientConfig';
import httpClient, { contentTypeList } from '../common/http';

const timeout = API_TIMEOUT;
const api_version = '/api/v1/';
let currentAuthToken = null;

export const url = clientConfig.API_URL;

export function setToken(token) {
    currentAuthToken = token;
}

export const mediabankHeaders = () => ({
    contentType: contentTypeList.json,
    Authorization: `Bearer ${currentAuthToken}`,
});

export const getToken = () => currentAuthToken;

export const mediabankApi = new httpClient(url + api_version, timeout);
