export const Back_To_Login = 'Back to login';
export const Invalid_Form = 'This form is invalid';
export const Long_Text = 'The inserted text is too long!';
export const No_Assets_Header = 'No results found for';
export const No_Assets_Info = 'Please make sure your words are spelled correctly or use less or different keywords.';
export const No_Auth_SSO = 'No Auth token was returned from SSO service';
export const No_Export_Forms_Available = 'No export forms are available';
export const Only_White_Spaces = 'This field can not contain whitespace only!';
export const Page_Not_Found = 'Oops, not found!';
export const Partner_Sign_In = 'PARTNER SIGN IN';
export const Sign_In = 'SIGN IN';
export const Sign_Out = 'Sign Out';
export const Something_Wrong = 'Something went wrong!';
