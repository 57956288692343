import React from 'react';
import PropTypes from 'prop-types';

import Cube from './Cube';
import { usePanelLoadingStyles } from './styles';

const PanelLoading = ({ padding, size }) => {
    const classes = usePanelLoadingStyles({ padding });

    return (
        <div className={classes.root} data-cy="panelLoading" role="progressbar">
            <Cube size={size} />
        </div>
    );
};

PanelLoading.propTypes = {
    padding: PropTypes.string,
    size: PropTypes.number,
};

PanelLoading.defaultProps = {
    padding: null,
    size: 36,
};

export default PanelLoading;
