import { createAction } from '@reduxjs/toolkit';

export const fetchCustomerConfigFailed = createAction('CONFIG/FETCH/CUSTOMERCONFIG/FAILED');
export const fetchCustomerConfigLoading = createAction('CONFIG/FETCH/CUSTOMERCONFIG/LOADING');
export const fetchCustomerConfigSucceeded = createAction('CONFIG/FETCH/CUSTOMERCONFIG/SUCCEEDED');

export const fetchSavedSearchesFailed = createAction('CONFIG/FETCH/SAVEDSEARCHES/FAILED');
export const fetchSavedSearchesLoading = createAction('CONFIG/FETCH/SAVEDSEARCHES/LOADING');
export const fetchSavedSearchesSucceeded = createAction('CONFIG/FETCH/SAVEDSEARCHES/SUCCEEDED');

export const fetchQuickFiltersFailed = createAction('CONFIG/FETCH/QUICKFILTERS/FAILED');
export const fetchQuickFiltersLoading = createAction('CONFIG/FETCH/QUICKFILTERS/LOADING');
export const fetchQuickFiltersSucceeded = createAction('CONFIG/FETCH/QUICKFILTERS/SUCCEEDED');
