import React from 'react';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

import { Notifications } from './Notifications';

const AUTO_HIDE_DURATION_MS = 5000;

const NotificationsProvider = ({ children = null, customClasses }) => (
    <SnackbarProvider autoHideDuration={AUTO_HIDE_DURATION_MS} classes={customClasses} maxSnack={4}>
        <Notifications>{children}</Notifications>
    </SnackbarProvider>
);

NotificationsProvider.defaultProps = {
    customClasses: {},
};

NotificationsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    customClasses: PropTypes.object,
};

/**
 * @USAGE
 * #1: Wrap your app between the provider with care that <NotificationsProvider> must be  child of the <ThemeProvider> component:
 *      @EG <NotificationsProvider><MyNewApp/></NotificationsProvider>
 * #2: Once your app in the Provider scope, you can use the React @ContextAPI to access the notifications.
 *      @EG const { addToastSuccess } = useContext(NotificationsContext);
 *          addToastSuccess('Some success message');
 */

export { NotificationsProvider };
