import React from 'react';
import { ReactComponent as SearchMagnifierIcon } from '@mediabank/assets/icons/search-magnifier.svg';
import { ButtonBase, makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        '& svg': {
            height: '1rem',
            stroke: theme.palette.text.primary,
        },
        justifyContent: 'flex-start',
    },
}));

const SearchButton = props => {
    const { label, onClick } = props;
    const classes = useStyles();

    return (
        <ButtonBase {...props} className={classes.root} onClick={onClick}>
            <SearchMagnifierIcon />
            {label}
        </ButtonBase>
    );
};

SearchButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

SearchButton.defaultProps = {
    label: '',
};

export default SearchButton;
