import { commonColors, basicColors } from '../common';
import { makeColors } from '../themeFunctions';

const neutral = {
    neutral000: '#000000',
    neutral100: '#0C0E0E',
    neutral200: '#191C1D',
    neutral300: '#1C2020',
    neutral400: '#1C2020',
    neutral500: '#1F2324',
    neutral600: '#2A2E2F',
    neutral700: '#414445',
    neutral800: '#797B7C',
    neutral900: '#e9e9e9',
};

const palette = {
    ...commonColors,
    ...commonColors.sys,
    ...commonColors.download,
    ...commonColors.live,
    ...neutral,
    accentBright: makeColors('#55d6eb'),
    borderDark: 'rgba(81, 81, 81, 1)',
    scrollbarThumb: 'rgba(255, 255, 255, 0.2)',
    accentLight: makeColors(basicColors.white),
    accentMedium: makeColors('#008094'),
    accentDark: makeColors(neutral.neutral000),
    productBase: makeColors(neutral.neutral500),
    springGreen: makeColors(basicColors.greenReady),
    lightContrast: 'rgb(238,238,238)',
    darkContrast: neutral.neutral000,
    borderLight: makeColors(basicColors.white)[0.2],

    icon: {
        main: '#939191',
        hover: '#41c3da',
        disabled: '#b0b3b5',
        light: neutral.neutral900,
    },
    timeline: {
        hoveredTab: 'rgba(0, 0, 0, 0.1)',
        tab: '#151819',
        text: '#46274d',
        overlay: '#2b3563',
        video: '#004954',
        image: '#335943',
        audio: '#59542d',
        commentary: '#643932',
        soundWave: '#707070',
    },
    modal: {
        background: '#313536',
    },
    dialog: {
        background: neutral.neutral600,
    },
    dividers: {
        border: neutral.neutral000,
    },
};

export default palette;
