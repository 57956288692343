import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import Cube from './Cube';
import { useContentLoadingStyles } from './styles';

const ContentLoading = ({ size }) => {
    const classes = useContentLoadingStyles({ size });

    return (
        <Grid className={classes.root} container={true} data-testid="contentLoading" role="progressbar" spacing={1}>
            <Grid item={true}>
                <Cube delay={-750} size={18} />
            </Grid>
            <Grid item={true}>
                <Cube delay={-500} size={18} />
            </Grid>
            <Grid item={true}>
                <Cube delay={-250} size={18} />
            </Grid>
            <Grid item={true}>
                <Cube delay={0} size={18} />
            </Grid>
        </Grid>
    );
};

ContentLoading.propTypes = {
    size: PropTypes.number,
};

ContentLoading.defaultProps = {
    size: 24,
};

export default ContentLoading;
