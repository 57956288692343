import { createReducer } from '@reduxjs/toolkit';

import {
    searchAssetsBySmartSearchIdSucceeded,
    searchAssetsFailed,
    searchAssetsLoading,
    searchAssetsSucceeded,
    setSelectedAssetsById,
    toggleSelectedAsset,
} from '../actions/assetList';

export const initialState = {
    assetActivePage: 1,
    assetCount: 0,
    assets: [],
    isAssetsAvailable: false,
    isMoreAssetsAvailable: false,
    isSearchAssetsLoading: false,
    searchAssetsError: null,
    // this dictionary tracks the IDs of selected assets
    selectedAssetsById: {
        // '12345': true,
        // '67890': true,
    },
};

const assetList = createReducer(initialState, {
    [searchAssetsFailed]: (state, { payload }) => {
        state.assets = [];
        state.assetCount = 0;

        state.searchAssetsError = payload;
    },
    [searchAssetsLoading]: (state = initialState, { payload }) => {
        state.isSearchAssetsLoading = payload;
    },
    [searchAssetsSucceeded]: (state, { payload }) => {
        const { assetData, page = 1, shouldReset } = payload;

        const isAssetsAvailable = assetData.data.length > 0 || false;
        const assetCount = assetData.meta.noItems || 0;

        state.assets = shouldReset ? [...assetData.data] : state.assets.concat(assetData.data || []);
        state.isAssetsAvailable = isAssetsAvailable;
        state.assetCount = assetCount;
        state.isMoreAssetsAvailable = assetCount > state.assets.length;
        state.assetActivePage = page;
    },
    [searchAssetsBySmartSearchIdSucceeded]: (state, { payload }) => {
        const { assetData, page = 1, shouldReset } = payload;

        const isAssetsAvailable = assetData.assets.length > 0 || false;
        const assetCount = assetData.total || 0;

        state.assets = shouldReset ? [...assetData.assets] : state.assets.concat(assetData.assets || []);
        state.isAssetsAvailable = isAssetsAvailable;
        state.assetCount = assetCount;
        state.isMoreAssetsAvailable = assetCount > state.assets.length;
        state.assetActivePage = page;
    },
    [setSelectedAssetsById]: (state, { payload }) => {
        state.selectedAssetsById = payload;
    },
    [toggleSelectedAsset]: (state, { payload: assetId }) => {
        const isAlreadySelected = !!state.selectedAssetsById[assetId];

        if (isAlreadySelected) {
            delete state.selectedAssetsById[assetId];
        } else {
            state.selectedAssetsById[assetId] = true;
        }
    },
});

export { assetList };
