import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, PlaceholderLoading, QuickFilter } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { setActivePage, setSearchAssets } from '../../store/actions/ui';
import { getVisibleSavedSearches } from '../../store/selectors';
import { searchAssets, searchAssetsBySmartSearchId } from '../../store/thunks/assetList';

const useStyles = makeStyles(theme => ({
    loading: {
        height: theme.spacing(3.5),
        width: theme.spacing(20),
        borderRadius: theme.spacing(2),
    },
    savedSearchItem: {
        display: 'inline-block',
    },
}));

const SavedSearchesContainer = ({ scrollableContainerRef }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const activeSavedSearchId = useSelector(state => state.ui.searchAssets.searchTypes.savedSearch);
    const activeSearchType = useSelector(state => state.ui.searchAssets.activeSearchType);
    const isSavedSearchesLoading = useSelector(state => state.config.isSavedSearchesLoading);
    const savedSearches = useSelector(getVisibleSavedSearches);

    if (isSavedSearchesLoading) {
        return <PlaceholderLoading className={classes.loading} />;
    }

    if (savedSearches.length === 0) {
        return <Typography>You have no saved searches.</Typography>;
    }

    const activateSearch = (search, isActive) => {
        dispatch(setActivePage(1));

        if (isActive) {
            dispatch(searchAssets({ shouldReset: true }));
            dispatch(
                setSearchAssets({
                    key: 'savedSearch',
                    value: '',
                })
            );
        } else {
            dispatch(searchAssetsBySmartSearchId({ smartSearchId: search.smartsearchid, shouldReset: true }));
            dispatch(
                setSearchAssets({
                    key: 'savedSearch',
                    value: search.smartsearchid,
                })
            );
        }

        if (scrollableContainerRef.current) {
            // we can safely disable this eslint rule here, since we want to
            // work with the scroll position of an element
            // eslint-disable-next-line no-param-reassign
            scrollableContainerRef.current.scrollTop = 0;
        }
    };

    const savedSearchesList = savedSearches.map((savedSearch, index) => {
        const isActive = activeSavedSearchId === savedSearch.smartsearchid && activeSearchType === 'savedSearch';

        return (
            <Box
                key={`savedSearches-list-item-${savedSearch.smartsearchid}`}
                className={classes.savedSearchItem}
                data-testid="savedSearches-list-item"
                mb={1.5}
                mr={1}
            >
                <QuickFilter
                    label={savedSearch.Name}
                    selected={isActive}
                    onClick={() => activateSearch(savedSearch, isActive)}
                />
            </Box>
        );
    });

    return <Box>{savedSearchesList}</Box>;
};

SavedSearchesContainer.propTypes = {
    scrollableContainerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

SavedSearchesContainer.defaultProps = {
    scrollableContainerRef: () => null,
};

export { SavedSearchesContainer };
