import get from 'lodash/get';

import { EXPORT_FILE_METHODS } from '../containers/MetaForm/export.constants';
/**
 * Adobe CEP helper methods
 * @see https://github.com/Adobe-CEP/CEP-Resources
 * @requires CSInterface
 *   CSInterface should be accessible as a global for this to work
 */

/**
 * Class CSEvent.
 * You can use it to dispatch a standard CEP event.
 *
 * @param type	          Event type.
 * @param scope 	  The scope of event, can be "GLOBAL" or "APPLICATION".
 * @param appId 	  The unique identifier of the application that generated the event. Optional.
 * @param extensionId The unique identifier of the extension that generated the event. Optional.
 *
 * @return CSEvent object
 */
export class CSEvent {
    constructor(type, scope, appId, extensionId) {
        this.type = type;
        this.scope = scope;
        this.appId = appId;
        this.extensionId = extensionId;
    }
}

// Test to remove
export const dispatchEvent = () => {
    const event = new CSEvent('com.adobe.cep.test', 'APPLICATION');
    event.data = 'This is a test!';
    const csInterface = new window.CSInterface();
    csInterface.dispatchEvent(event);
};

/**
 * Imports file into premier
 */
export const dragImportFile = (syntheticEvent, extPath) => {
    const event = syntheticEvent.nativeEvent;
    const csInterface = new window.CSInterface();
    const OSVersion = csInterface.getOSInformation();

    if (extPath !== null) {
        if (OSVersion.indexOf('Windows') >= 0) {
            const sep = '\\\\';

            // eslint-disable-next-line no-param-reassign
            extPath = extPath.replace(/\//g, sep);

            console.log('Path is: ', extPath);
        }

        event.dataTransfer.setData('com.adobe.cep.dnd.file.0', extPath);
    }
};

/**
 * Checks if OS is windows
 */
export const isWindowsOS = () => {
    const csInterface = new window.CSInterface();
    const OSVersion = csInterface.getOSInformation();

    if (OSVersion.indexOf('Windows') >= 0) {
        return true;
    }

    return false;
};

/**
 * Returns the extension version as specified in manifest.xml
 */
export const getExtensionVersion = () => readManifestValue('attributes.Version.value');

/**
 * Reads the given property from extension manifest file
 * @param {*} propPath
 */
export const readManifestValue = propPath => {
    try {
        const csInterface = new window.CSInterface();
        let url = `file://${csInterface.getSystemPath(window.SystemPath.EXTENSION)}/CSXS/manifest.xml`;

        if (isWindowsOS()) {
            url = url.replace(/\//g, '\\\\');
        }

        const req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.send(null);
        const xmlDocument = req.responseXML;
        const extensionTags = xmlDocument ? xmlDocument.getElementsByTagName('Extension') : null;
        const extensionTag = extensionTags[0] || {};

        return get(extensionTag, propPath);
    } catch (e) {
        return false;
    }
};

/**
 * Imports a file into Premiere project
 * @param {*} windowsOSPath
 *   Path to file for windows OS
 * @param {*} macOSPath
 *   Path to file for mac OS
 * @param callback
 *   A callback function to execute when import is done
 */
export const importFile = (windowsOSPath, macOSPath, callback = result => {}) => {
    const csInterface = new window.CSInterface();
    let extPath = macOSPath;

    if (isWindowsOS()) {
        const sep = '\\\\';
        extPath = windowsOSPath.replace(/\\/g, sep);
    }

    csInterface.evalScript(`mediabank.importFiles(['${extPath}'])`, callback);
};

/**
 * Imports multiple files in one batch
 * @param {*} paths
 * @param {*} callback
 */
export const importFiles = (paths, callback = result => {}) => {
    const csInterface = new window.CSInterface();
    const isWindows = isWindowsOS();

    const parsedPaths = paths.map(({ windowsOSPath, macOSPath }) => {
        let extPath = macOSPath;

        if (isWindows) {
            extPath = windowsOSPath;
        }

        return extPath;
    });

    csInterface.evalScript(`mediabank.importFiles(${JSON.stringify(parsedPaths)})`, callback);
};

/**
 * Checks if there is an active sequence
 * @param callback
 */
export const hasActiveSequence = callback => {
    const csInterface = new window.CSInterface();
    csInterface.evalScript('mediabank.hasActiveSequence()', callback);
};

/**
 * Export file from Premiere to the given export folder
 *
 * @param {*} config
 *   Config for paths from Mediabank company config
 *
 * @param {string} exportFileMethod the version of `exportFile` to use
 *                 either 'directExportFile'
 *                 or 'queueExportFile'
 *
 * @param {*} outputFilename
 *
 * @returns {String} String containing error
 */
export const exportFile = ({ config, exportFileMethod, outputFilename, setIsExporting }) => {
    const presetFile = config.export_presets_file;
    const presetPath = config.export_presets_path;
    let exportPath = isWindowsOS() ? config.export_unc_path : config.macos_export_path;

    if (!presetFile) {
        throw new Error('export_presets_file is not set in config');
    }

    const presetFullPath = getPresetPath(presetFile, presetPath);

    if (!exportPath) {
        throw new Error('Export path is not set in config');
    }

    if (isWindowsOS()) {
        const sep = '\\\\';
        exportPath = config.export_unc_path.replace(/\\/g, sep);
    }

    let scriptToEval = '';

    if (exportFileMethod === EXPORT_FILE_METHODS.directExportFile) {
        // the "new" export method
        scriptToEval = `mediabank.directExportFile('${presetFullPath}', '${exportPath}', '${outputFilename}')`;
    } else {
        // else, exportFileMethod === 'queueExportFile'

        // the "legacy" export method
        scriptToEval = `mediabank.queueExportFile('${presetFullPath}', '${exportPath}', '${outputFilename}')`;
    }

    console.log('cep.js exportFile :: scriptToEval:', scriptToEval);

    const csInterface = new window.CSInterface();

    csInterface.evalScript(scriptToEval, result => {
        if (setIsExporting) {
            setIsExporting(false);
        }
        console.log('Result of evalScript', result);
    });
};

export const getPresetPath = (file = 'example.epr', path) => {
    if (path) {
        if (isWindowsOS()) {
            const sep = '\\\\';

            // eslint-disable-next-line no-param-reassign
            path = path.replace(/\\/g, sep);
        }

        return path + file;
    } else {
        const csInterface = new window.CSInterface();
        let path = csInterface.getSystemPath(window.SystemPath.EXTENSION);
        let sep = '/';
        // The path always comes back with '/' path separators. Windows needs '\\'.
        if (isWindowsOS()) {
            sep = '\\\\';
            path = path.replace(/\//g, sep);
        }

        return `${path}${sep}payloads${sep}${file}`;
    }
};

/**
 * when in a CEP context,
 * returns an object with the version of
 * CEP integrated by the user's instance of Adobe Premiere
 *
 * EXAMPLE: {"major":"10","minor":"1","micro":"0"}
 */
export const getCurrentApiVersion = () => {
    if (!window.CSInterface) {
        return;
    }

    const csInterface = new window.CSInterface();

    let versionObj;

    try {
        versionObj = csInterface.getCurrentApiVersion();
    } catch (err) {
        console.log(err);
    }

    return versionObj;
};
