import React from 'react';
import {
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    Divider,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ReactComponent as CloseIcon } from '@mediabank/assets/icons/prefilter.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        color: theme.custom.accentLight[0.9],
        fontWeight: 500,
        textAlign: 'center',
    },
}));

const DialogTitle = ({ children, onClose, ...forwardProps }) => {
    const classes = useStyles();

    return (
        <>
            <MuiDialogTitle
                className={clsx(classes.root, forwardProps.classes?.root)}
                disableTypography={true}
                {...forwardProps}
            >
                <Typography className={clsx(classes.title, forwardProps.styles?.title)} variant="h6">
                    {children}
                </Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={clsx(classes.closeButton, forwardProps.styles?.closeButton)}
                        size="small"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <Divider light={true} />
        </>
    );
};

DialogTitle.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

DialogTitle.defaultProps = {
    onClose: undefined,
};

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        justifyContent: 'space-between',
    },
}))(MuiDialogActions);

const Dialog = ({ size, children, ...forwardProps }) => (
    <MuiDialog maxWidth={size} {...forwardProps} disableBackdropClick={true} disableEscapeKeyDown={true}>
        {children}
    </MuiDialog>
);

Dialog.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'lg']),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

Dialog.defaultProps = {
    size: 'sm',
};

export { Dialog, DialogTitle, DialogContent, DialogActions };
