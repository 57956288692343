import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    next: ({ disabled }) => ({
        display: 'flex',
        alignItems: 'center',
        color: disabled ? theme.palette.text.disabled : 'inherit',
        cursor: disabled ? 'not-allowed' : 'pointer',
    }),
    labelNext: {
        paddingLeft: theme.spacing(1),
    },
}));

const NextItem = ({ onClick, disabled, page, type, showSlimPager }) => {
    const classes = useStyles({ disabled });

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {type === 'next' && showSlimPager && <span>{page - 1}</span>}
            <div className={classes.next} onClick={disabled ? () => {} : onClick}>
                <NavigateNextIcon />
            </div>
        </div>
    );
};

NextItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    page: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    showSlimPager: PropTypes.bool,
};

NextItem.defaultProps = {
    disabled: false,
    showSlimPager: false,
};

export default NextItem;
