import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ReactComponent as FaSyncAlt } from '@mediabank/assets/icons/font-awesome/fa-sync-alt.svg';
import { makeStyles, PanelTab } from '@mediabank/uikit';

import api from '../../api/index';

const tabs = [
    { id: 1, text: 'Import', link: '/search' },
    { id: 2, text: 'Export', link: '/export' },
];

const useStyles = makeStyles(theme => ({
    navigationTabs: {
        borderBottom: `1px solid ${theme.custom.accentLight[0.2]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    syncIcon: {
        height: '15px',
        cursor: 'pointer',
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
            transition: 'opacity 0.2s',
        },
    },
}));

const NavigationTabs = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleNavigation = link => {
        history.push(link);
    };

    const onSyncClick = () => {
        // temporary debugging
        api.refreshTokenIfNeeded();

        window.location.reload();
    };

    return (
        <Box className={classes.navigationTabs} mb={2}>
            <Box data-testid="NavigationTabs">
                {tabs.map(tab => (
                    <PanelTab
                        key={tab.id}
                        label={tab.text}
                        selected={window.location.pathname === tab.link}
                        variant="level1"
                        onClick={() => handleNavigation(tab.link)}
                    />
                ))}
            </Box>
            <FaSyncAlt className={classes.syncIcon} onClick={onSyncClick} />
        </Box>
    );
};

export { NavigationTabs };
