import { clientConfig } from '../clientConfig/clientConfig';
import httpClient, { contentTypeList } from '../common/http';
const url = clientConfig.JWT_URL;

const timeout = 8000;

const tokenHeaders = {
    contentType: contentTypeList.json,
    ...(clientConfig.DEPLOYMENT_ENV === 'dev' && { 'x-api-dev': true }),
};

const api = new httpClient(url, timeout);

const authWithCred = async ({ email, password }) => {
    const response = await api.post('/login', {
        data: {
            email,
            password,
        },
        headers: tokenHeaders,
    });

    return response;
};

const refresh = async ({ token }) => {
    const response = await api.get('/refresh', {
        headers: {
            ...tokenHeaders,
            token,
        },
    });
    return response;
};

const validate = async ({ token }) => {
    const response = await api.get('/', {
        headers: {
            ...tokenHeaders,
            token,
        },
    });
    return response;
};

export default {
    authWithCred,
    refresh,
    validate,
};
