import React from 'react';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';

const usePanelStyles = makeStyles(theme => ({
    toggleButton: ({ isHidden, toggleButtonPosition }) => {
        const styles = {
            display: 'flex',
            position: 'absolute',
            color: theme.palette.text.secondary,
            textTransform: 'uppercase',
            zIndex: isHidden ? 2 : 1,
            padding: 2,
            backgroundColor: isHidden ? theme.palette.background.root : 'transparent',
            borderRadius: toggleButtonPosition === 'left' ? '0px 4px 4px 0px' : '4px 0px 0px 4px',
            marginTop: 7,
            cursor: 'pointer',
            alignItems: 'center',
            fontWeight: 500,

            '& svg': {
                color: theme.palette.text.primary,
            },
        };

        if ((isHidden && toggleButtonPosition === 'right') || (!isHidden && toggleButtonPosition === 'left')) {
            styles.left = 0;
        } else if ((!isHidden && toggleButtonPosition === 'right') || (isHidden && toggleButtonPosition === 'left')) {
            styles.right = 0;
        }

        return styles;
    },
    root: ({ panelBorder }) => ({
        position: 'relative',
        '&:not(:last-child)': {
            borderRight: !panelBorder ? `3px solid ${theme.palette.background.root}` : panelBorder,
        },
    }),
    content: ({ isHidden }) => ({
        display: isHidden ? 'none' : 'block',
        width: '100%',
    }),
    expand: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            display: 'none',
            overflow: 'hidden',
        },
        '&:hover div': {
            display: 'inline',
            paddingRight: '5px',
        },
    },
}));

const CollapsiblePanel = ({ children, width, onClick, name, toggleButtonPosition, panelBorder }) => {
    const isHidden = !width;
    const classes = usePanelStyles({ isHidden, toggleButtonPosition, panelBorder });
    const ChevronCollapse = toggleButtonPosition === 'left' ? ChevronRight : ChevronLeft;
    const ChevronExpand = toggleButtonPosition === 'left' ? ChevronLeft : ChevronRight;

    return (
        <Box className={classes.root} style={{ width: `${width}%` }}>
            {name && (
                <Box className={classes.toggleButton} data-testid="toggle-button" onClick={onClick}>
                    {width ? (
                        <Tooltip title={<Typography>{`Hide ${name}`}</Typography>}>
                            <ChevronCollapse />
                        </Tooltip>
                    ) : (
                        <Box className={classes.expand}>
                            <ChevronExpand /> <Box>{name}</Box>
                        </Box>
                    )}
                </Box>
            )}
            <Box className={classes.content}>{children}</Box>
        </Box>
    );
};

CollapsiblePanel.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    name: PropTypes.string,
    toggleButtonPosition: PropTypes.oneOf(['left', 'right']),
    panelBorder: PropTypes.string,
};

CollapsiblePanel.defaultProps = {
    onClick: () => null,
    name: '',
    toggleButtonPosition: 'right',
    panelBorder: '',
    width: 0,
};

export default CollapsiblePanel;
