import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography } from '@material-ui/core';
import { ContentLoading, makeStyles, NotificationsContext, Select } from '@mediabank/uikit';
import cx from 'clsx';

import api from '../../api';
import { MetaForm } from '../../components/MetaForm';
import { Invalid_Form, No_Export_Forms_Available } from '../../messages';
import { setFormValues } from '../../store/actions/form';
import { setActiveForm } from '../../store/actions/ui';
import { hasFilledRequiredFieldsSelector } from '../../store/reducers/form';
import { isFormInvalidSelector } from '../../store/reducers/ui';
import { getExportFormSelectValues, getFields, getPremiereProPanelConfig } from '../../store/selectors';
import { fetchFormByKey } from '../../store/thunks/form';
import { exportFile, hasActiveSequence } from '../../utils/cep';
import { EXPORT_FILE_METHODS } from './export.constants';

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        color: 'rgba(255, 255, 255, 0.85)',
    },
    box: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        position: 'relative',
    },
    formSelector: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        '& .MuiFormLabel-root': {
            opacity: '0.7',
            marginBottom: theme.spacing(0.5),
        },
        '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.custom.accentBright[1],
        },
    },
    messageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        lineHeight: '3rem',
        fontSize: '2.5rem',
        color: '#808080',
    },
    exportFormLoadingContainer: {
        position: 'absolute',
        width: '100%',
        top: theme.spacing(37.5),
    },
    disabled: {
        opacity: 0.8,
    },
}));

const MetaFormContainer = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { addToastError, addToastSuccess } = useContext(NotificationsContext);

    const [formInputError, setFormInputError] = useState([]);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    // this determines which of the two export methods we should use
    const [exportFileMethod, setExportFileMethod] = useState(EXPORT_FILE_METHODS.DEFAULT);
    const [isExporting, setIsExporting] = useState(false);

    const activeForm = useSelector(state => state.ui.activeForm);
    const exportFormValues = useSelector(getExportFormSelectValues);
    const fields = useSelector(getFields);
    const formValues = useSelector(state => state.form.formValues);
    const isCustomerConfigLoading = useSelector(state => state.config.isCustomerConfigLoading);

    const isFormInvalid = useSelector(isFormInvalidSelector);

    const isFetchingForm = useSelector(state => state.ui.isFetchingForm);
    const hasFilledRequiredFields = useSelector(hasFilledRequiredFieldsSelector);
    const premiereConfig = useSelector(getPremiereProPanelConfig);

    const handleSelect = useCallback(
        async formKey => {
            await dispatch(fetchFormByKey(formKey));
            dispatch(setActiveForm(formKey));
        },
        [dispatch]
    );

    useEffect(() => {
        // Add CSXS listeners
        addCSXSEventListener();
    }, []);

    // This useEffect hook will preselect the first form
    // if only 1 form exists for the user
    useEffect(() => {
        if (!activeForm && exportFormValues.length === 1) {
            const formKey = exportFormValues[0].value;
            handleSelect(formKey);
        }
        // it's OK to disable this because we only want
        // to make the API call on component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addCSXSEventListener = () => {
        try {
            const csInterface = new window.CSInterface();

            csInterface.addEventListener('com.mediabank.debugInfo', data => {
                console.log('From host application: ', data);
            });

            csInterface.addEventListener('com.mediabank.jobProgress', data => {
                // TODO: 2. Update progress stuff
                console.log('Progress: ', data);
            });

            csInterface.addEventListener('com.mediabank.jobError', data => {
                // TODO: 5. Remove progress, we do not not what cause the error
                console.log('Error: ', data);
            });

            csInterface.addEventListener('com.mediabank.jobCanceled', data => {
                // TODO: 3. Remove progress stuff
                console.log('Canceled: ', data);
            });

            csInterface.addEventListener('com.mediabank.jobCompleted', data => {
                // TODO: 4. Show completed and remove progress stuff
                console.log('Completed: ', data);
            });

            csInterface.addEventListener('com.mediabank.jobQueued', data => {
                // TODO: 1. Show some progress stuff
                console.log('Queued: ', data);
            });
        } catch (err) {
            console.error(err);
            console.info('addCSXSEventListener :: not running in CEP context could not add listeners');
        }
    };

    const getConfigForActiveForm = () => {
        // Check if config format is new or legacy
        if (exportFormValues.length) {
            const { export_forms = [] } = premiereConfig || {};

            const config = export_forms;

            return config.find(configEntry => configEntry.adex_form === activeForm);
        }

        return premiereConfig;
    };

    const handleSubmit = () => {
        setIsExporting(true);
        const validationErrors = getValidationErrors();

        // exit early if the form is invalid
        if (validationErrors.length) {
            setIsExporting(false);
            setFormInputError(validationErrors);

            return;
        }

        const title = formValues.Title;
        const activeConfig = getConfigForActiveForm();

        const { file_extension } = activeConfig || {};

        // exit early if we have no file extension
        if (!file_extension) {
            setIsExporting(false);
            addToastError({
                title: 'The key file_extension is not set in PremiereProPanelConfig',
            });

            return;
        }

        const filename = `PanelExport.${file_extension}`;
        const meta = { ...formValues, Filename: filename };

        delete meta.title;

        try {
            hasActiveSequence(hasActive => {
                // exit early if there is no active sequence
                if (hasActive === 'null') {
                    setIsExporting(false);
                    addToastError({
                        title: 'No active sequence.',
                    });

                    return;
                }

                setIsSubmittingForm(true);

                api.createAsset(title, meta).then(onCreateAssetSuccess).catch(onCreateAssetError);
            });
        } catch (err) {
            setIsExporting(false);
            setIsSubmittingForm(false);

            console.error('handleSubmit', err);
        }

        function onCreateAssetSuccess(response) {
            // Axios' `data`
            const { data = {} } = response || {};

            // the API response is an object containing a `data` key
            const { assetid } = data.data;

            if (!assetid) {
                addToastError({
                    title: 'Could not create asset in Mediabank',
                });
                setIsExporting(false);
                setIsSubmittingForm(false);

                return;
            }

            try {
                exportFile({ config: activeConfig, exportFileMethod, outputFilename: assetid, setIsExporting });

                const title = `Asset created in Mediabank with assetid ${assetid}.`;

                const subtitle = (() => {
                    const subtitlePrefix = 'Starting export job';
                    let subtitleSuffix = '...';

                    if (exportFileMethod === EXPORT_FILE_METHODS.directExportFile) {
                        subtitleSuffix = ' via Premiere Pro...';
                    } else if (exportFileMethod === EXPORT_FILE_METHODS.queueExportFile) {
                        subtitleSuffix = ' via Media Encoder...';
                    }

                    return `${subtitlePrefix}${subtitleSuffix}`;
                })();

                addToastSuccess({ title, subtitle });

                setIsSubmittingForm(false);

                dispatch(setFormValues({}));
            } catch (err) {
                setIsExporting(false);
                addToastError({
                    title: 'Error was thrown from extend script while exporting file.',
                    subtitle: 'Check console for more info.',
                });

                console.error('onCreateAssetSuccess', err);
            }
        }

        function onCreateAssetError(err) {
            console.error('onCreateAssetError', err);

            addToastError({
                title: 'Error creating asset',
            });

            setIsSubmittingForm(false);
        }
    };

    /**
     * Validates form, currently only `required` is supported
     */
    const getValidationErrors = () => {
        const validationErrors = [];

        fields.forEach(field => {
            if (field.required && !formValues[field.name]) {
                validationErrors.push({ field: field.name, message: `${field.name} is required.` });
            }

            if (field.numeric && formValues[field.name] && isNaN(formValues[field.name])) {
                validationErrors.push({ field: field.name, message: `${field.name} can only have numeric values.` });
            }
        });

        return validationErrors;
    };

    const isFormLoading = isCustomerConfigLoading || isFetchingForm;
    const shouldRenderForm = !isFormLoading && activeForm && !isFormInvalid;
    const hasNoFormsAvailable = !isFormLoading && !activeForm && exportFormValues && exportFormValues.length === 0;

    // if only 1 form exists, our user shouldn't need to use the form <Select />
    const shouldFormSelectorBeDisabled = exportFormValues.length === 1;

    return (
        <>
            <Box className={classes.header}>
                <Typography data-testid="metaFormContainer-header-text" variant="body2">
                    Export sequence to Mediabank
                </Typography>
            </Box>
            <Divider variant="fullWidth" />
            {isFormLoading && (
                <Box className={classes.exportFormLoadingContainer} data-testid="metaFormContainer-export-from-loading">
                    <ContentLoading />
                </Box>
            )}
            <Box className={classes.box}>
                {!!exportFormValues.length && (
                    <Box className={classes.formSelector}>
                        <Select
                            className={cx({
                                [classes.disabled]: shouldFormSelectorBeDisabled,
                            })}
                            data-testid="metaFormContainer-export-from-metaSelect"
                            defaultValue={activeForm}
                            isDisabled={shouldFormSelectorBeDisabled}
                            label="Export form"
                            name="rangeMode"
                            options={exportFormValues}
                            placeholder="-- Please select form --"
                            value={activeForm}
                            onChange={handleSelect}
                        />
                    </Box>
                )}
                {!isFormLoading && isFormInvalid && (
                    <Box className={classes.messageContainer} data-testid="invalid-form-message">
                        {Invalid_Form}
                    </Box>
                )}
                {hasNoFormsAvailable && (
                    <Box className={classes.messageContainer} data-testid="no-forms-available-message">
                        {No_Export_Forms_Available}
                    </Box>
                )}
                {shouldRenderForm && (
                    <MetaForm
                        fields={fields}
                        formInputError={formInputError}
                        handleSubmit={handleSubmit}
                        hasFilledRequiredFields={hasFilledRequiredFields}
                        isExporting={isExporting}
                        setExportFileMethod={setExportFileMethod}
                        setFormValues={setFormValues}
                    />
                )}
                {isSubmittingForm && (
                    <Box
                        className={classes.exportFormLoadingContainer}
                        data-testid="metaFormContainer-export-from-loading"
                    >
                        <ContentLoading />
                    </Box>
                )}
            </Box>
        </>
    );
};

export { MetaFormContainer };
