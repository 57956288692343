import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
    },
}));

/**
 * Utility component for centering anything.
 */
const Centered = ({ children }) => {
    const classes = useStyles();

    return (
        <Grid alignContent="center" alignItems="center" className={classes.root} container={true} justify="center">
            <Grid item={true}>{children}</Grid>
        </Grid>
    );
};

Centered.propTypes = {
    children: PropTypes.element.isRequired,
};

export default Centered;
