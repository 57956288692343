import { createAction } from '@reduxjs/toolkit';

export const formFetchFailed = createAction('FORM/FETCH/FAILED');
export const formFetchSucceeded = createAction('FORM/FETCH/SUCCEEDED');

export const metaValuesFetchFailed = createAction('METAVALUES/FETCH/FAILED');
export const metaValuesFetchStarted = createAction('METAVALUES/FETCH/STARTED');
export const metaValuesFetchSucceeded = createAction('METAVALUES/FETCH/SUCCEEDED');

export const setFormValues = createAction('FORM/SET/VALUES');
