import { rgba2rgb } from '../themeFunctions';

export default (theme, colors) => ({
    root: {
        color: 'white',
        borderLeft: '2px solid transparent',
        padding: `${theme.spacing(1.5)} ${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(2)}`,
        borderRadius: theme.spacing(0.75),
        backgroundColor: rgba2rgb(colors.productBase[1], colors.accentLight[1], 0.08),
        display: 'block',
        '&.warning': {
            borderLeft: `6px solid ${colors.sysYellow[1]}`,
        },
        '&.error': {
            borderLeft: `6px solid ${colors.sysRed[1]}`,
        },
        '&.info': {
            borderLeft: `6px solid ${colors.sysBlue[1]}`,
        },
        '&.success': {
            borderLeft: `6px solid ${colors.sysGreen[1]}`,
        },
        '& > .close-icon': {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            cursor: 'pointer',
        },
        '& > .title': {
            color: colors.accentLight[1],
            lineHeight: '2rem',
        },
        '& > .subtitle': {
            color: colors.accentLight[0.7],
            fontSize: '1.2rem',
            marginBottom: theme.spacing(2),
        },
        '& > .caption': {
            color: colors.accentLight[0.7],
            fontSize: '1.2rem',
        },
    },
});
