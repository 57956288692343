import httpClient, { contentTypeList } from '../common/http';
import jwtDecode from 'jwt-decode';
import { getToken } from '../mediabank';
import { clientConfig } from '../clientConfig/clientConfig';

const getDefaultHeader = () => {
    const { tagsApiToken } = jwtDecode(getToken());

    return {
        contentType: contentTypeList.json,
        'TAGS-API-Token': tagsApiToken,
    };
};

const timeout = 8000;
const tagsApi = new httpClient(clientConfig.TAGS_API_URL, timeout);

export { getDefaultHeader, tagsApi };
