import React from 'react';
import { Box, Divider, ListItemText, makeStyles, MenuItem } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';

import { NestedMenuItem } from './NestedMenuItem';

const itemStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    spacing: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: theme.spacing(3),
        marginTop: '-2px',
    },
    itemText: {
        paddingRight: theme.spacing(1),
    },
}));

const ContextMenuItem = React.forwardRef(
    ({ children, disabled, label, showDivider, itemIcon, menuPosition, onClick }, ref) => {
        const itemClasses = itemStyles();

        const labelCore = (
            <Box className={itemClasses.wrapper}>
                {itemIcon}
                <ListItemText className={itemClasses.itemText} primary={label} />
            </Box>
        );

        const onItemClick = event => {
            event.stopPropagation();
            onClick(event);
        };

        return (
            <>
                {children ? (
                    <NestedMenuItem
                        data-cy="contextMenuItemElement"
                        isParentMenuOpen={!!menuPosition}
                        label={labelCore}
                        rightIcon={
                            <Box pl={2.5}>
                                <ArrowRightIcon />
                            </Box>
                        }
                        onClick={onItemClick}
                    >
                        {children}
                    </NestedMenuItem>
                ) : (
                    <MenuItem ref={ref} data-cy="contextMenuItemElement" disabled={disabled} onClick={onItemClick}>
                        {labelCore}
                    </MenuItem>
                )}
                {showDivider && <Divider />}
            </>
        );
    }
);

ContextMenuItem.defaultProps = {
    children: null,
    menuPosition: null,
    showDivider: false,
    itemIcon: null,
    disabled: false,
    onClick: () => {},
};

ContextMenuItem.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,
    showDivider: PropTypes.bool,
    itemIcon: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    menuPosition: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            top: PropTypes.number.isRequired,
            left: PropTypes.number.isRequired,
        }),
        PropTypes.object,
    ]),
    disabled: PropTypes.bool,
};

export default ContextMenuItem;
