import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, InputLabel, TextField, Typography } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from '@mediabank/assets/icons/error-circle.svg';
import { makeStyles } from '@mediabank/uikit';
import { isNotOnlyWhitespace } from '@mediabank/utils';
import PropTypes from 'prop-types';

import { Long_Text, Only_White_Spaces } from '../../messages';
import { RequiredFieldIndicator } from './RequiredFieldIndicator';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        '& .MuiFormLabel-root': {
            fontSize: '12px',
            color: 'white',
            opacity: '0.7',
            marginBottom: theme.spacing(0.5),
        },
        '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.custom.accentBright[1],
        },
        '& .MuiFormControl-marginNormal': {
            width: '100%',
            marginTop: theme.spacing(0),
        },
        '& .MuiOutlinedInput-root': {
            height: theme.spacing(3),
            background: 'rgb(28, 32, 32)',
        },
    },
    helperTextRow: {
        display: 'flex',
        alignItems: 'center',
    },
    helperText: {
        marginLeft: theme.spacing(1),
        color: theme.custom.accentLight[0.8],
    },
}));
const useInputStyles = makeStyles(theme => ({
    root: {
        fontSize: '12px',
    },
}));

const MetaInput = ({ formInputError, label, name, required, setFormValues }) => {
    const classes = useStyles();
    const inputClasses = useInputStyles();

    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState(null);
    const [userInput, setUserInput] = useState('');

    useEffect(() => {
        setErrorMessage(formInputError.filter(item => item.field === name));
    }, [formInputError, name]);

    const hasErrorMessage = errorMessage !== null && errorMessage.length > 0;

    const onChange = event => {
        const value = event.currentTarget.value || '';
        const name = event.currentTarget.name || '';

        setUserInput(value);

        if (hasErrorMessage && event) {
            setErrorMessage(null);
        }

        if ((isNotOnlyWhitespace(value) || value === '') && value.length < 75) {
            dispatch(setFormValues({ key: name, value }));
        } else {
            value.length === 0
                ? setErrorMessage(null)
                : setErrorMessage(value.length > 74 ? [{ message: Long_Text }] : [{ message: Only_White_Spaces }]);
        }
    };

    return (
        <Box className={classes.root}>
            <InputLabel>
                {label}
                {!!required && <RequiredFieldIndicator />}
            </InputLabel>
            <TextField
                data-testid="metaInput-export-form-input"
                error={hasErrorMessage}
                InputProps={{ classes: inputClasses }}
                margin="normal"
                name={name}
                placeholder={`[${label}]`}
                value={userInput}
                variant="outlined"
                onChange={onChange}
            />
            {hasErrorMessage && (
                <Box className={classes.helperTextRow}>
                    <ErrorIcon />
                    <Typography className={classes.helperText} variant="body2">
                        {errorMessage[0].message}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

MetaInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    formInputError: PropTypes.array.isRequired,
    required: PropTypes.bool.isRequired,
    setFormValues: PropTypes.func.isRequired,
};

export { MetaInput };
