import 'regenerator-runtime/runtime.js';

const errorResponseInterceptor = async error => {
    if (error.response) {
        switch (error.response.status) {
            case 400:
                console.warn(error.response.status, 'Error in server response');
                break;
            case 401:
                // TODO: How to handle token expiration?
                console.warn(error.response.status, 'Unauthorized');
                break;
            case 403:
                console.warn(error.response.status, 'Permission not allowed');
                break;
            case 404:
                console.warn(error.response.status, 'API Route is Missing or Undefined');
                break;
            case 405:
                console.warn(error.response.status, 'API Route Method Not Allowed');
                break;
            case 422:
                console.warn(error.response.status, 'Unprocessable Entity');
                break;
            case 500:
                console.warn(error.response.status, 'Server Error');
                break;
            default:
                console.warn(error.response.status, 'Unspecified error');
                break;
        }
    } else if (error.request) {
        console.error('Unable to send request', error.request.status);
    } else {
        console.warn('Error', error.message);
    }
    return Promise.reject(error);
};

export default errorResponseInterceptor;
