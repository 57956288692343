import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, InputLabel } from '@material-ui/core';
import { DatePicker, makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        '& .MuiFormLabel-root': {
            fontSize: '12px',
            color: 'white',
            opacity: '0.7',
            marginBottom: theme.spacing(0.5),
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '&.MuiPopover-paper': {
            left: '32px !important',
        },
        '& .MuiOutlinedInput-root': {
            height: theme.spacing(3),
            background: 'rgb(28, 32, 32) !important',
        },
        '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.custom.accentBright[1],
        },
    },
}));
const useInputStyles = makeStyles(theme => ({
    root: {
        fontSize: '12px',
    },
}));

const MetaDatePicker = ({ initialDate, label, name, setFormValues }) => {
    const classes = useStyles();
    const inputClasses = useInputStyles();

    const dispatch = useDispatch();
    const [dateValue, setDateValue] = useState(initialDate || new Date());

    const handleOnChange = val => {
        setDateValue(val);
        dispatch(setFormValues({ key: name, value: val }));
    };

    const commonProps = {
        isDateTime: true,
        autoOk: true,
        views: ['date'],
        format: 'yyyy-MM-dd',
    };

    return (
        <Box className={classes.root}>
            <InputLabel>{label}</InputLabel>
            <DatePicker
                {...commonProps}
                data-testid="advancedSearch-dateRange-main"
                InputProps={{ classes: inputClasses }}
                value={dateValue}
                onChange={handleOnChange}
            />
        </Box>
    );
};

MetaDatePicker.defaultProps = {
    initialDate: null,
};

MetaDatePicker.propTypes = {
    // optional prop for Jest
    initialDate: PropTypes.instanceOf(Date),

    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    setFormValues: PropTypes.func.isRequired,
};

export { MetaDatePicker };
