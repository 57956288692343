import React from 'react';
import { FormControlLabel, makeStyles, Radio } from '@material-ui/core';
import cx from 'clsx';
import PropTypes from 'prop-types';

const useRadioStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 0.75, 0, 0),
        '&:hover&$checked, &:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focusVisible > span:after': {
            position: 'absolute',
            borderRadius: '50%',
            width: '1.8rem',
            height: '1.8rem',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.custom.focused[0.5],
            content: '""',
        },
    },
    checked: {},
}));

const useIconStyles = makeStyles(theme => ({
    checked: {},
    icon: {
        borderRadius: '50%',
        width: '1.6rem',
        height: '1.6rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: 'initial',
        borderColor: theme.custom.accentLight[0.3],
        '$checked &': {
            borderColor: theme.custom.accentLight[0.3],
        },
        'input:disabled ~ &': {
            borderColor: theme.custom.accentLight[0.1],
        },
        'input:hover ~ &:before': {
            display: 'block',
            borderRadius: '50%',
            margin: '0.2rem',
            width: '1.0rem',
            height: '1.0rem',
            background: theme.custom.accentLight[0.1],
            content: '""',
        },
    },
    checkedIcon: {
        borderColor: theme.custom.accentLight[0.3],
        '&:before, input:hover ~ &:before': {
            display: 'block',
            borderRadius: '50%',
            margin: '0.2rem',
            width: '1.0rem',
            height: '1.0rem',
            background: theme.palette.primary.light,
            content: '""',
        },
        'input:hover ~ &:before': {
            background: theme.palette.primary.light,
        },
    },
}));

const useLabelStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        color: theme.custom.accentLight[0.75],
        paddingRight: theme.spacing(4),
        '&$disabled $label': {
            color: theme.custom.accentLight[0.25],
            opacity: 0.3,
        },
    },
    disabled: {},
    label: {
        fontSize: '1.2rem',
        color: theme.custom.accentLight[0.75],
        fontWeight: 400,
    },
}));

const RadioButton = ({ value, label, ...forwardProps }) => {
    const radioClasses = useRadioStyles();
    const iconClasses = useIconStyles();
    const labelClasses = useLabelStyles();

    const controlElement = (
        <Radio
            checkedIcon={<span className={cx(iconClasses.icon, iconClasses.checkedIcon)} />}
            classes={radioClasses}
            icon={<span className={cx(iconClasses.icon)} />}
            value={value}
        />
    );

    return label ? (
        <FormControlLabel classes={labelClasses} control={controlElement} label={label} {...forwardProps} />
    ) : (
        controlElement
    );
};

RadioButton.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
};

RadioButton.defaultProps = {
    label: '',
};

export default RadioButton;
