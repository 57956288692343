import React from 'react';
import { FormControl, FormLabel, makeStyles, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';

const useLabelStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
        fontSize: '1.2rem',
        '&$focused': {
            color: theme.palette.text.primary,
        },
    },
    focused: {},
}));

const RadioButtonGroup = ({ children, name, value, onChange, legend, ...forwardProps }) => {
    const labelClasses = useLabelStyles();

    const handleChange = event => {
        onChange(event.target.value);
    };

    return (
        <FormControl>
            {legend && <FormLabel classes={labelClasses}>{legend}</FormLabel>}
            <RadioGroup name={name} value={value} onChange={handleChange} {...forwardProps}>
                {children}
            </RadioGroup>
        </FormControl>
    );
};

RadioButtonGroup.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    legend: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

RadioButtonGroup.defaultProps = {
    value: undefined,
    name: '',
    legend: undefined,
    onChange: () => null,
};

export default RadioButtonGroup;
