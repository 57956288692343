import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { rgba2rgb } from '@mediabank/theme';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: props => props.maxInputWidth || undefined,
        position: 'absolute',
        zIndex: 10,
        // marginTop: theme.spacing(0.25),
        left: 0,
        right: 0,
        background: rgba2rgb(theme.custom.productBase[1], theme.custom.accentDark[1], 0.2),
        border: `1px solid ${theme.custom.accentLight[0.2]}`,
        borderRadius: theme.shape.borderRadius,
    },
}));

const SelectMenu = ({ innerProps, children }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root} square={true} {...innerProps} data-cy="selectMenu">
            {children}
        </Paper>
    );
};

SelectMenu.propTypes = {
    children: PropTypes.element.isRequired,
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};

export default SelectMenu;
