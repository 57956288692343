// https://mbnepms.atlassian.net/browse/MBX-414
// import { createSelector } from '@reduxjs/toolkit';

import { getSemanticInputFromAdexInput } from '../../utils/form';

// TODO: wrap this in `createSelector` because it includes a loop in `.filter`
export const getVisibleSavedSearches = state => {
    const savedSearches = state.config.savedSearches || [];

    return savedSearches.filter(search => !search.hidden);
};

// TODO: wrap this in `createSelector` because it includes a loop in `.find`
export const getActiveSearchData = state => {
    const { activeSearchType } = state.ui.searchAssets;
    const activeSearchId = state.ui.searchAssets.searchTypes[activeSearchType];

    if (activeSearchType === 'savedSearch') {
        return state.config.savedSearches.find(savedSearch => savedSearch.smartsearchid === activeSearchId);
    }

    // at this point we know that the activeSearchType will be quickFilter
    return state.config.quickFilters.find(quickFilter => quickFilter.attributes.smartsearchid === activeSearchId);
};

export const getConfigByKey = (state, key) => {
    const { customerConfig } = state.config;

    return customerConfig[key] || null;
};

export const getPremiereProPanelConfig = state => {
    try {
        return JSON.parse(getConfigByKey(state, 'PremiereProPanelConfig'));
    } catch (err) {
        console.error(err);
    }
};

export const getExportForms = state => {
    const config = getPremiereProPanelConfig(state) || {};

    return Array.isArray(config.export_forms) ? config.export_forms : [];
};

/** Form selectors */
export const getFields = state => {
    const fields = state.form.schema.fields || [];

    return fields.map(field => getSemanticInputFromAdexInput(field));
};

export const getExportFormSelectValues = state => {
    const configs = getExportForms(state);

    return configs.map(config => ({
        label: config.adex_form_name || config.adex_form,
        value: config.adex_form,
    }));
};

// TODO: wrap this in `createSelector` because it includes a loop in `.find`
/**
 *  The returned value either will be the smartsearchtext property of the active quick filter or the string
 * the user has entered into the input field.
 * @return {string} returns the string to be displayed in the UI (SearchBox input)
 */
export const getSearchBoxInputValue = state => {
    const { activeSearchType } = state.ui.searchAssets;

    if (activeSearchType === 'quickFilter') {
        const activeSearchId = state.ui.searchAssets.searchTypes[activeSearchType];

        const activeQuickFilter = state.config.quickFilters.find(
            quickFilter => quickFilter.attributes.smartsearchid === activeSearchId
        );

        const keyword = activeQuickFilter.attributes.smartsearchtext;

        if (keyword) {
            return keyword;
        }
    }

    const keywordSearchValue = state.ui.searchAssets.searchTypes.keywordSearch;

    return keywordSearchValue;
};
