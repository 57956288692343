// import { createMuiTheme } from '@material-ui/core/styles' will fail tests for some reason
// eslint-disable-next-line no-restricted-imports
import { createTheme } from '@mui/material/styles';
import { rgba2rgb } from '../themeFunctions';
import defaultColors from '../dark/colors';
import overrides from '../overrides';
import { defaultFontFamily } from '../fonts';

const theme = createTheme();

// We need a defaultColors, because in test mode the colors would be undefined.
// Colors.* crashing the test process

export default (colors = defaultColors) => ({
    themeName: 'Dark Theme',
    id: 'dark',
    palette: {
        common: {
            white: colors.accentLight[1],
            black: colors.productBase[1],
            tooltipText: colors.const.tooltipText,
            loginColor: colors.loginPage.color,
            loginBackground: colors.loginPage.background,
            icon: colors.neutral800,
        },
        primary: {
            main: colors.accentMedium[1],
            light: colors.accentBright[1],
            dark: colors.accentBright[0.5],
            contrastText: colors.contrastText,
        },
        secondary: {
            main: colors.sysGreen[1],
        },
        background: {
            paper: rgba2rgb(colors.productBase[1], colors.accentLight[1], 0.08),
            dateThumbnail: colors.liveGreen[1],
            default: colors.productBase[1],
            root: colors.accentDark[1],
            input: colors.accentDark[0.4],
            downloadProgress: colors.downloadProgress,
            downloadReady: colors.downloadReady,
            downloadWaiting: colors.downloadWaiting,
            downloadFailed: colors.downloadFailed,
        },
        text: {
            primary: colors.accentLight[1],
            secondary: colors.accentBright[1],
            tertiary: colors.accentLight[0.6],
            disabled: colors.neutral800,
            hover: colors.accentLight[0.9],
        },
        icon: {
            light: colors.accentLight[0.6],
            active: colors.accentBright[1],
            hover: colors.accentDark[0.6],
            dark: colors.neutral200,
        },
        border: {
            light: `1px solid ${colors.borderLight}`,
            dark: colors.neutral700,
            hover: colors.neutral700,
            main: colors.neutral000,
            accent: colors.accentMedium[1],
        },
        buttonBackground: {
            primary: colors.neutral600,
            search: colors.neutral600,
            hover: colors.neutral700,
        },
        buttonSecondary: {
            text: colors.neutral100,
            background: colors.neutral700,
        },
        outlined: {
            text: colors.accentBright[1],
            background: colors.accentDark[0.6],
        },
        action: {
            focus: colors.focused[1],
        },
        warning: {
            main: colors.sysYellow[1],
        },
        error: {
            main: colors.sysRed[1],
        },
        leftMenu: {
            background: colors.accentDark[1],
        },
        info: {
            main: colors.sysBlue[1],
        },
        success: {
            main: colors.sysGreen[1],
        },
        opacity: {
            sidebar: 0.4,
        },
        type: 'dark',
    },
    custom: colors,
    layout: {
        line: {
            height: '3px',
        },
    },
    typography: {
        fontFamily: defaultFontFamily,
        fontSize: 10,
        htmlFontSize: 10,
        h1: {
            fontSize: '2.4rem',
            lineHeight: '2.4rem',
        },
        h2: {
            fontSize: '2.2rem',
            lineHeight: '2.2rem',
        },
        h3: {
            fontSize: '2.0rem',
            lineHeight: '2.0rem',
        },
        h4: {
            fontSize: '1.8rem',
            lineHeight: '1.8rem',
        },
        h5: {
            fontSize: '1.6rem',
            lineHeight: '1.6rem',
        },
        h6: {
            fontSize: '1.4rem',
            lineHeight: '1.4rem',
            fontWeight: 'normal',
        },
        subtitle1: {
            fontSize: '1.4rem',
            lineHeight: '1.4rem',
        },
        subtitle2: {
            fontSize: '1.4rem',
            lineHeight: '1.4rem',
        },
        body1: {
            fontSize: '1.4rem',
            lineHeight: '1.4rem',
        },
        body2: {
            fontSize: '1.2rem',
            lineHeight: '1.2rem',
        },
        button: {
            fontSize: '1.2rem',
            lineHeight: '1.2rem',
            textTransform: 'none',
        },
        caption: {
            fontSize: '1.0rem',
        },
        overline: {
            fontSize: '1.0rem',
        },
    },
    props: {
        MuiButton: {
            disableRipple: true,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    ...overrides(theme, colors),
});
