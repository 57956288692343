import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from '@material-ui/core';
import { filterDataSet } from '@mediabank/utils';
import PropTypes from 'prop-types';

const TextFieldWrapper = ({
    input: { name, onChange, value, ...restInput },
    autoComplete,
    meta,
    onChange: userOnChange,
    ...rest
}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
    const { dataSet, nonDataSetProps } = filterDataSet(rest);

    return (
        <TextField
            {...nonDataSetProps}
            autoComplete={autoComplete}
            error={showError}
            helperText={showError ? meta.error || meta.submitError : undefined}
            inputProps={{ ...restInput, ...dataSet }}
            name={name}
            value={value}
            onChange={event => {
                onChange(event.target.value);
                userOnChange && userOnChange(event.target.value);
            }}
        />
    );
};

TextFieldWrapper.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func,
};

TextFieldWrapper.defaultProps = {
    autoComplete: 'on',
    onChange: null,
};

const FormTextField = props => <Field component={TextFieldWrapper} {...props} />;

export { TextFieldWrapper };

export default FormTextField;
