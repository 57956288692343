import { createReducer, createSelector } from '@reduxjs/toolkit';

import { resetSearchAssets, setActiveForm, setActivePage, setIsFetchingForm, setSearchAssets } from '../actions/ui';

const SEARCH_TYPES = {
    activeSearchType: '',
    searchTypes: {
        keywordSearch: '',
        quickFilter: '',
        savedSearch: '',
    },
};

export const initialState = {
    activeForm: '',
    assetActivePage: 1,
    assetLimit: 12,
    isFetchingForm: false,
    searchAssets: SEARCH_TYPES,
    selectedAssets: [],
};

const ui = createReducer(initialState, {
    [resetSearchAssets]: state => {
        state.searchAssets = {
            activeSearchType: '',
            searchTypes: {
                keywordSearch: '',
                quickFilter: '',
                savedSearch: '',
            },
        };
    },
    [setActiveForm]: (state, { payload }) => {
        state.activeForm = payload;
    },
    [setActivePage]: (state, { payload }) => {
        state.assetActivePage = payload;
    },
    [setIsFetchingForm]: (state, { payload }) => {
        state.isFetchingForm = payload;
    },
    [setSearchAssets]: (state, { payload }) => {
        const { key, value } = payload;

        state.searchAssets = {
            activeSearchType: key,
            searchTypes: {
                ...state.searchAssets.searchTypes,
                [key]: value,
            },
        };
    },
});

const activeFormSelector = state => state.ui.activeForm;
const formSelector = state => state.form;

const isFormInvalidSelector = createSelector(activeFormSelector, formSelector, (activeForm, form) => {
    if (activeForm) {
        const { schema = {} } = form || {};

        if (!schema.name) {
            return true;
        }
    }

    return false;
});

export { ui, isFormInvalidSelector };
