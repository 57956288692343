import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';

import { APP_VERSION, CEP_API_VERSION, CEP_EXT_VERSION } from '../../constants';

const useStyles = makeStyles(theme => ({
    appInfo: {
        fontSize: theme.typography.caption.fontSize,
        opacity: 0.7,

        // override `user-select: none;` and ensure that our user
        // can select the app version and copy it to their clipboard
        userSelect: 'text',
    },
}));

// display app info at the footer of the login screen
// EXAMPLE: "Version 1.1.13-1138 | Extension 1.8 | CEP 10.1.0"
const APP_INFO_TEXT = `Version ${APP_VERSION} • Extension ${CEP_EXT_VERSION} • CEP ${CEP_API_VERSION}`;

const AppInfo = () => {
    const classes = useStyles();

    return (
        <Typography className={classes.appInfo} component="span" data-testid="appInfo-version">
            {APP_INFO_TEXT}
        </Typography>
    );
};

export { AppInfo };
