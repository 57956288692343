import { useEffect, useRef, useState } from 'react';

const useCountLines = ({ fontSize = 12 }) => {
    const [lines, setLines] = useState(1);

    const linesRef = useRef(null);

    const { current = null } = linesRef || {};

    useEffect(() => {
        const { offsetHeight = 0, style = {} } = current || {};

        const elementHeight = offsetHeight;
        const lineHeight = parseInt(style.lineHeight);
        const currentLines = (elementHeight / lineHeight - 3) / fontSize;
        setLines(currentLines);
    }, [current, fontSize]);

    return [linesRef, lines];
};
export { useCountLines };

/**
 * @useCountLines is counting the used lines in the @html element.
 *
 * @USAGE /In a functional component scope/:
 *
 * const [linesRef, lines] = useCountLines({ fontSize: 12 }) // fontSize is optional, and the default value is 12.
 *
 */
