import React from 'react';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { AccountBar } from './AccountBar';
import { NavigationTabs } from './NavigationTabs';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        height: '100vh',
        // spaces variables
        '--accountContainerHeight': '40px',
        '--footerHeight': '50px',
        '--minAppHeight': '550px',
        '--navigationContainerHeight': '56px',
        '--searchTypeSelectorHeight': '32px',
        '--searchTypeContentHeight': '60px',
        '--searchResultsHeaderHeight': '32px',
    },
    container: {
        height: '100%',
        minWidth: '322px',
    },
    accountContainer: {
        height: 'var(--accountContainerHeight)',
    },
    navigationContainer: {
        height: 'var(--navigationContainerHeight)',
    },
    resultContainer: {
        height: 'calc(100% - (var(--accountContainerHeight) + var(--navigationContainerHeight)))',
    },
}));

const MainLayout = ({ children }) => {
    const classes = useStyles();

    return (
        <Container className={classes.mainContainer} disableGutters={true} maxWidth={false}>
            <Box className={classes.container} pl={2.25} pr={2.25} pt={2.25}>
                <Box className={classes.accountContainer}>
                    <AccountBar />
                </Box>
                <Box className={classes.navigationContainer}>
                    <NavigationTabs />
                </Box>
                <Box className={classes.resultContainer}>{children}</Box>
            </Box>
        </Container>
    );
};

MainLayout.defaultProps = {
    children: <Box />,
};

MainLayout.propTypes = {
    children: PropTypes.node,
};

export { MainLayout };
