import React, { useRef } from 'react';
import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import truncateMiddle from 'truncate-middle';

const useQuickFilterStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1.2, 2),
        backgroundColor: theme.palette.outlined.background,
        color: theme.custom.accentLight[0.9],
        borderRadius: theme.spacing(2),
        fontWeight: 400,
        whiteSpace: 'nowrap',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.custom.accentDark[0.6],
            color: theme.custom.accentLight[0.9],
            border: `1px ${theme.custom.accentLight[0.2]} solid`,
        },
        '&:focus': {
            border: `1px ${theme.custom.focused[0.5]} solid`,
            color: theme.custom.accentLight[0.9],
        },
        border: '1px transparent solid',
    },
    outlined: {
        '&:focus': {
            borderColor: theme.custom.accentBright[0.9],
            color: theme.palette.outlined.text,
        },
        '&:hover': {
            borderColor: theme.custom.accentBright[0.9],
            color: theme.palette.outlined.hover,
        },
        borderColor: theme.custom.accentBright[1],
        color: theme.palette.outlined.text,
    },
}));

const useTooltipStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.custom.accentLight[0.6],
        fontWeight: 400,
        fontSize: '1.2rem',
        border: `1px solid ${theme.custom.accentLight[0.1]}`,
    },
    tooltipPlacementBottom: {
        marginTop: theme.spacing(0.5),
    },
}));

const QuickFilter = ({ className, onClick, label, selected, maxLabelLength }) => {
    const quickFilterClasses = useQuickFilterStyles();
    const tooltipClasses = useTooltipStyles();
    const buttonRef = useRef(null);

    const onClickHandler = () => {
        onClick(!selected);
    };

    const truncatedLabel = truncateMiddle(label, maxLabelLength - 15, 10, ' ... ');
    const truncated = label !== truncatedLabel;

    const filterButton = (
        <Button
            ref={buttonRef}
            classes={quickFilterClasses}
            className={className}
            data-cy="quickFilterButtonElement"
            data-test="quickFilterButtonElement"
            size={'small'}
            variant={selected ? 'outlined' : undefined}
            onClick={onClickHandler}
        >
            <Typography variant="body2">{truncatedLabel}</Typography>
        </Button>
    );

    if (truncated) {
        return (
            <Tooltip classes={tooltipClasses} enterDelay={10} placement="bottom" title={label}>
                {filterButton}
            </Tooltip>
        );
    } else {
        return <>{filterButton}</>;
    }
};

QuickFilter.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    selected: PropTypes.bool.isRequired,
    maxLabelLength: PropTypes.number,
    className: PropTypes.string,
};

QuickFilter.defaultProps = {
    maxLabelLength: 38,
    className: '',
};

export default QuickFilter;
