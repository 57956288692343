import { makeStyles } from '@material-ui/core';
import { rgba2rgb } from '@mediabank/theme';

export const useCustomSelectStyles = makeStyles(theme => ({
    container: {
        '& .customSelectStyles__control': {
            minWidth: 130,
            height: theme.spacing(3),
            minHeight: theme.spacing(3),
            backgroundColor: rgba2rgb(theme.custom.productBase[1], theme.custom.accentDark[1], 0.1),
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.custom.accentLight[0.2],
            boxShadow: 'none',
            cursor: 'pointer',

            '&:focus': {
                borderColor: theme.custom.accentBright[1],
                borderWidth: 1,
            },

            '&:hover': {
                borderColor: theme.custom.accentLight[0.4],
                borderWidth: 1,
            },
        },

        '& .customSelectStyles__indicators': {
            height: theme.spacing(3),
        },

        '& .customSelectStyles__menu': {
            background: rgba2rgb(theme.custom.productBase[1], theme.custom.accentDark[1], 0.2),
            border: `1px solid ${theme.custom.accentLight[0.2]}`,
            borderRadius: theme.shape.borderRadius,
        },

        '& .customSelectStyles__option': {
            padding: theme.spacing(0.625, 1.25),
            color: theme.custom.accentLight[0.8],
            fontSize: '1.2rem',
            lineHeight: '1.4rem',

            '&--is-selected, &--is-focused': {
                background: theme.custom.accentLight[0.2],
                color: theme.palette.text.primary,
            },

            '&:hover': {
                background: theme.custom.accentLight[0.1],
            },
        },

        '& .customSelectStyles__value-container': {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            padding: 0,
            overflow: 'hidden',

            '& > p': {
                height: theme.spacing(3),
            },
        },

        '& .customSelectStyles__multi-value': {
            height: theme.spacing(2),
            margin: theme.spacing(0, 0, 1, 1),
            backgroundColor: theme.custom.accentMedium[0.5],
            border: '1px solid transparent',
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.text.primary,
            fontSize: '1.2rem',

            '&__label': {
                padding: theme.spacing(0.125, 0.125, 0.125, 0.875),
                color: theme.palette.text.primary,
            },

            '&__remove': {
                '& svg': {
                    borderRadius: '50%',
                    backgroundColor: theme.custom.accentLight[0.3],
                },

                '&:hover': {
                    backgroundColor: 'unset',
                    color: theme.palette.text.primary,
                },
            },
        },
    },
}));
