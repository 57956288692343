import { mediabankApi as api, mediabankHeaders } from '..';

const getActive = async () => {
    const response = await api.get('/smartsearches/active', {
        headers: mediabankHeaders(),
    });
    return response;
};

const createOne = async payload => {
    const response = await api.post('/smartsearches', {
        headers: mediabankHeaders(),
        data: payload,
    });
    return response;
};

const getOne = async ({ id }) => {
    const response = await api.get(`/smartsearches/${id}`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getAll = async () => {
    const response = await api.get('/smartsearches', {
        headers: mediabankHeaders(),
    });
    return response;
};

const getAllQuickfilters = async () => {
    const response = await api.get('/smartsearches/quickfilters', {
        headers: mediabankHeaders(),
    });
    return response;
};

const deleteOne = async ({ id }) => {
    const response = await api.delete(`/smartsearches/${id}`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const updateOne = async payload => {
    const { id } = payload.data.attributes;
    delete payload.data.attributes.id;
    const response = await api.patch(`/smartsearches/${id}`, {
        headers: mediabankHeaders(),
        data: payload,
    });
    return response;
};

const sendSubscriptionSettings = async (id, attributes) => {
    const data = {
        type: 'smartsearches/notifications',
        attributes: {
            webhook: 'webhook',
            ...attributes,
        },
    };

    const response = await api.put(`/smartsearches/${id}/notifications`, {
        headers: mediabankHeaders(),
        data: { data },
    });

    return response;
};

export default {
    createOne,
    updateOne,
    getOne,
    getAll,
    getAllQuickfilters,
    deleteOne,
    getActive,
    sendSubscriptionSettings,
};
