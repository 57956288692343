import React from 'react';
import { ButtonBase, Grid, Typography } from '@material-ui/core';
import { ReactComponent as ViewModuleIcon } from '@mediabank/assets/icons/grid-view.svg';
import { ReactComponent as ViewList } from '@mediabank/assets/icons/row-view.svg';
import { makeStyles } from '@mediabank/uikit';
import cx from 'clsx';
import PropTypes from 'prop-types';

import { TABLE_DISPLAY_TYPES } from '../../constants';

const useStyles = makeStyles(theme => ({
    searchResultsHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 'var(--searchResultsHeaderHeight)',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.custom.accentLight[0.2]}`,
        padding: theme.spacing(1.5),
        paddingTop: 0,
        marginBottom: '4px',
    },
    inactiveIcon: {
        opacity: 0.2,
    },
}));

const SearchResultHeader = ({ assetCount, displayType, isLoading, nrOfAssets, onDisplayTypeChange }) => {
    const classes = useStyles();

    const resultCountContent = (() => {
        if (isLoading) {
            return 'Loading results...';
        }

        if (assetCount === 0) {
            return 'No results';
        }

        const singularOrPlural = assetCount > 1 ? 'results' : 'result';

        return `${nrOfAssets.toLocaleString()} of ${assetCount.toLocaleString()} ${singularOrPlural}`;
    })();

    return (
        <Grid className={classes.searchResultsHeader} container={true} data-testid="SearchResultsHeader">
            <Grid item={true}>
                <Grid alignItems="center" container={true} spacing={1}>
                    <Grid item={true}>
                        <Typography data-testid="resultCountContent" variant="body2">
                            {resultCountContent}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item={true}>
                <Grid alignItems="center" container={true} justify="flex-end" spacing={1}>
                    <Grid data-testid="icon-button__thumbnail-view" item={true}>
                        <ButtonBase
                            className={cx({
                                [classes.inactiveIcon]: assetCount < 1 || displayType !== TABLE_DISPLAY_TYPES.thumbnail,
                            })}
                            disabled={assetCount < 1}
                            id={`change-display-type-${TABLE_DISPLAY_TYPES.thumbnail}`}
                            onClick={() => onDisplayTypeChange(TABLE_DISPLAY_TYPES.thumbnail)}
                        >
                            <ViewModuleIcon fill="#ffffff" />
                        </ButtonBase>
                    </Grid>
                    <Grid data-testid="icon-button__row-view" item={true}>
                        <ButtonBase
                            className={cx({
                                [classes.inactiveIcon]: assetCount < 1 || displayType !== TABLE_DISPLAY_TYPES.row,
                            })}
                            disabled={assetCount < 1}
                            id={`change-display-type-${TABLE_DISPLAY_TYPES.row}`}
                            onClick={() => onDisplayTypeChange(TABLE_DISPLAY_TYPES.row)}
                        >
                            <ViewList fill="#ffffff" />
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

SearchResultHeader.propTypes = {
    assetCount: PropTypes.number.isRequired,
    displayType: PropTypes.oneOf(['row', 'thumbnail']).isRequired,
    isLoading: PropTypes.bool.isRequired,
    nrOfAssets: PropTypes.number.isRequired,
    onDisplayTypeChange: PropTypes.func.isRequired,
};

export { SearchResultHeader };
