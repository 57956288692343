import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Button, Form, makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { EXPORT_FILE_METHODS } from '../../containers/MetaForm/export.constants';
import { getFormElement } from '../../utils/form';

const useStyles = makeStyles(theme => ({
    export: {
        display: 'flex',
        justifyContent: 'center',
    },
    exportButton: {
        width: theme.spacing(12.5),
        height: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        background: theme.custom.accentMedium[1],
        '&:hover': {
            background: theme.custom.accentMedium[1],
        },
    },
}));

const MetaForm = ({
    fields,
    formInputError,
    handleSubmit,
    hasFilledRequiredFields,
    isExporting,
    setExportFileMethod,
    setFormValues,
}) => {
    const classes = useStyles();
    const [isDirectExport, setIsDirectExport] = useState(false);

    return (
        <Form data-testid="metaForm-export-form" onSubmit={handleSubmit}>
            {fields
                .filter(field => !field.hidden)
                .map(field => {
                    // Default values from hidden fields are set by the forms reducer, so removed from view
                    const Field = getFormElement(field.element, field.name);

                    return (
                        <Field
                            {...field}
                            key={field.name}
                            data-testid="metaForm-export-form-field"
                            formInputError={formInputError}
                            setFormValues={setFormValues}
                        />
                    );
                })}
            <Box className={classes.export}>
                <Button
                    className={classes.exportButton}
                    data-testid="exportForm-submit-directExportFile"
                    disabled={!hasFilledRequiredFields || isExporting}
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        setExportFileMethod(EXPORT_FILE_METHODS.directExportFile);
                        setIsDirectExport(true);
                    }}
                >
                    {isExporting && isDirectExport ? <CircularProgress color={'primary'} size={20} /> : 'Export'}
                </Button>
                <Button
                    className={classes.exportButton}
                    data-testid="exportForm-submit-queueExportFile"
                    disabled={!hasFilledRequiredFields || isExporting}
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        setExportFileMethod(EXPORT_FILE_METHODS.queueExportFile);
                        setIsDirectExport(false);
                    }}
                >
                    {isExporting && !isDirectExport ? <CircularProgress color={'primary'} size={20} /> : 'Add to Queue'}
                </Button>
            </Box>
        </Form>
    );
};

MetaForm.propTypes = {
    fields: PropTypes.array.isRequired,
    formInputError: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasFilledRequiredFields: PropTypes.bool.isRequired,
    isExporting: PropTypes.bool.isRequired,
    setExportFileMethod: PropTypes.func.isRequired,
    setFormValues: PropTypes.func.isRequired,
};

export { MetaForm };
