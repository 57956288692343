export default (theme, colors) => ({
    root: {
        width: '2rem',
        height: '2rem',
        fontSize: '2rem',
        position: 'relative',
        marginRight: theme.spacing(1),
        zIndex: 1,
        color: colors.accentLight[0.75],
        '&:hover, &:hover&$checked': {
            backgroundColor: 'initial',
        },
        '&$checked': {
            color: colors.accentBright[1],
            '&:focus': {
                color: colors.focused[0.5],
            },
        },
        $disabled: {
            opacity: 0.25,
        },
        '&:focus': {
            color: colors.focused[0.5],
        },
    },
    colorSecondary: {
        '&$checked': {
            color: colors.accentBright[1],
            '&:focus': {
                color: colors.focused[0.5],
            },
        },
        $disabled: {
            opacity: 0.25,
        },
        '&:focus': {
            color: colors.focused[0.5],
        },
    },
});
