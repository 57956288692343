import { makeColors } from '../themeFunctions';

const palette = {
    neutral000: '#000000',
    neutral100: '#0C0E0E',
    neutral200: '#191C1D',
    neutral300: '#1C2020',
    neutral400: '#1C2020',
    neutral500: '#1F2324',
    neutral600: '#2A2E2F',
    neutral700: '#414445',
    neutral800: '#797B7C',
    neutral900: '#e9e9e9',
    accentBright: makeColors('#55d6eb'),
    accentLight: makeColors('#ffffff'),
    borderLight: 'rgba(255, 255, 255, 0.2)',
    scrollbarThumb: 'rgba(255, 255, 255, 0.2)',
    accentMedium: makeColors('#008094'),
    accentDark: makeColors('#000000'),
    productBase: makeColors('#1f2324'),
    focused: makeColors('#ffcc42'),
    sysBlue: makeColors('#517aff'),
    liveRed: makeColors('#b80000'),
    liveGreen: makeColors('#00404a'),
    sysYellow: makeColors('#efc100'),
    sysRed: makeColors('#f5263c'),
    sysGreen: makeColors('#32ff7b'),
    mBoxInfo: makeColors('#e0245e'),
    springGreen: makeColors('#62ff9a'),
    contrastText: 'rgb(22, 24, 25)',
    lightContrast: 'rgb(256, 256, 256)',
    darkContrast: 'rgb(0, 0, 0)',
    downloadProgress: '#EFC100',
    downloadReady: '#62FF9A',
    downloadWaiting: '#414445',
    downloadFailed: '#FC5466',
    const: {
        tooltipText: '#ffffff',
    },
    loginPage: {
        color: '#3d3d3d',
        background: '#ffffff',
    },
    icon: {
        main: '#939191',
        hover: '#41c3da',
        disabled: '#b0b3b5',
        light: '#e9e9e9',
    },
    timeline: {
        hoveredTab: 'rgba(0, 0, 0, 0.1)',
        tab: '#151819',
        text: '#46274d',
        overlay: '#2b3563',
        video: '#004954',
        image: '#335943',
        audio: '#59542d',
        commentary: '#643932',
        soundWave: '#707070',
    },
    modal: {
        background: '#313536',
    },
    dialog: {
        background: '#2A2E2F',
    },
    dividers: {
        border: '#000000',
    },
};

export default palette;
