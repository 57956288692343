import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SearchTypeContent, SearchTypeSelector } from '../../components/SearchTypeSelection';

const SearchTypeSelectionContainer = ({ scrollableContainerRef, setSearchTypeContentHeight }) => {
    const [selectedSearchTypeId, setSelectedSearchTypeId] = useState(1);

    return (
        <>
            <SearchTypeSelector
                selectedSearchTypeId={selectedSearchTypeId}
                setSelectedSearchTypeId={setSelectedSearchTypeId}
            />
            <SearchTypeContent
                scrollableContainerRef={scrollableContainerRef}
                selectedSearchTypeId={selectedSearchTypeId}
                setSearchTypeContentHeight={setSearchTypeContentHeight}
            />
        </>
    );
};

SearchTypeSelectionContainer.propTypes = {
    setSearchTypeContentHeight: PropTypes.func,
    scrollableContainerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

SearchTypeSelectionContainer.defaultProps = {
    scrollableContainerRef: () => null,
    setSearchTypeContentHeight: () => null,
};

export { SearchTypeSelectionContainer };
