import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as RefreshIcon } from '@mediabank/assets/icons/refresh-right.svg';
import cx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginBottom: 1,
        height: 13,
        width: 22,
        cursor: 'pointer',
        fill: 'none',
    },
}));

const ResultCount = ({ count, page, itemsPerPage, textPrefix, classes, onRefresh }) => {
    const from = (page - 1) * itemsPerPage + 1;
    const to = page * itemsPerPage;
    const localClasses = useStyles();

    return (
        <Box className={cx(localClasses.root, classes)}>
            <Typography variant="inherit">
                {textPrefix} {from}-{to > count ? count : to} of {count}
            </Typography>
            <RefreshIcon className={localClasses.icon} data-cy={'resultCount-refreshIcon'} onClick={onRefresh} />
        </Box>
    );
};

ResultCount.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    onRefresh: PropTypes.func,
    textPrefix: PropTypes.node,
    classes: PropTypes.string,
};

ResultCount.defaultProps = {
    textPrefix: '',
    classes: '',
    onRefresh: () => null,
};

export default ResultCount;
