import { tokenService } from '@mediabank/client';
import { decodeToken } from '@mediabank/utils';
import axios from 'axios';

import {
    AUTH_ENDPOINT,
    DEV_MODE,
    LEGACY_MEDIABANK_ENDPOINT,
    MAP_ENDPOINT,
    TOKEN_REFRESH_BUFFER_IN_MINUTES,
} from '../constants';
import {
    clearTokenAndReload,
    getMinutesUntilTokenExpiration,
    getTokenFromLocalStorage,
    setTokenInLocalStorage,
} from '../utils/jwt';

/**
 * @class ServerError
 *   Class for handling server errors
 */
class ServerError {
    constructor(responseObj = {}, isDevMode = false) {
        this.status = responseObj.status;
        this.message = responseObj.message;

        if (isDevMode) {
            console.log(responseObj);
        }
    }
}

// NOTE: the use of `throw` here has been problematic
//       we may wish to reconsider it
const handleError = error => {
    if (typeof error.response !== 'object') {
        throw new ServerError({ status: 0, message: 'Network error' }, DEV_MODE);
    }

    const { data = {}, status, statusText } = error.response;

    throw new ServerError(
        {
            status,
            message: data.error || statusText,
        },
        DEV_MODE
    );
};

const axiosInstance = axios.create({
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

/**
 * Singleton for api requests
 * @throws ServerError
 */
const api = {
    /**
     * Fetches token for mediabank api access
     * @returns string - a JWT token string on success or '' on failure
     */
    login: async ({ email, password }) => {
        const postBody = {
            email: email.trim(),
            password: password.trim(),
        };

        const headers = api._getRequestHeaders();

        if (DEV_MODE) {
            // the JWT service defaults to production mode
            // adding this header activates dev mode
            headers['x-api-dev'] = 'true';
        }

        const axiosConfig = {
            headers,
        };

        try {
            const { data = {} } = await axiosInstance.post(`${AUTH_ENDPOINT}/login`, postBody, axiosConfig);
            const { token: tokenStr } = data;

            setTokenInLocalStorage(tokenStr);

            return tokenStr;
        } catch (err) {
            handleError(err);

            return '';
        }
    },

    /**
     * Destroy mediabank session
     */
    logout: async () => {
        /**
         * if our backend API takes too long to respond to
         * `/login.php?logout=1`, then we'll abort & proceed
         * with clearing the user's token from `localStorage`
         * and reloading the app to clear `state`
         *
         * if our `finally` block reloads the app
         * within `TIME_TO_WAIT_FOR_API`, this timeout callback
         * will never be reached
         */
        const TIME_TO_WAIT_FOR_API = 2500;
        setTimeout(() => {
            clearTokenAndReload();
        }, TIME_TO_WAIT_FOR_API);

        try {
            await api.fetchLegacy('/login.php', { logout: 1 }, 'get');
        } catch (err) {
            handleError(err);
        } finally {
            // clear the user's token from `localStorage`
            // and reload the app to ensure no user data is still in `state`
            clearTokenAndReload();
        }
    },

    /**
     * @method refreshToken
     *
     * make a GET request to the `/refresh/` API endpoint
     * receive a new token and put it in `localStorage`
     *
     * NOTE: this is really the _same_ token we received with `api.login()`
     *       it just has an updated `exp` (expiry) property
     *
     * @see https://jwt.mediabank.me/doc/#api-Auth-Refresh
     */
    refreshToken: async () => {
        const URL = `${AUTH_ENDPOINT}/api/v1/refresh/`;

        const headers = api._getRequestHeaders();

        const hasValidAuthHeader =
            headers.authorization &&
            typeof headers.authorization === 'string' &&
            headers.authorization.startsWith('Bearer ');

        if (!hasValidAuthHeader) {
            // don't make the "refresh" API call if we don't have a valid token
            return;
        }

        if (DEV_MODE) {
            // the JWT service defaults to production mode
            // adding this header activates dev mode
            headers['x-api-dev'] = 'true';
        }

        let response = {};

        try {
            const axiosConfig = {
                headers,
            };

            response = await axiosInstance.get(URL, axiosConfig);
        } catch (err) {
            handleError(err);

            return;
        }

        // put the new JWT in `localStorage`
        if (response.data && typeof response.data === 'object' && response.data.token) {
            const { token: tokenStr } = response.data;

            setTokenInLocalStorage(tokenStr);

            const minutesUntilTokenExpiration = getMinutesUntilTokenExpiration();

            console.info({ minutesUntilTokenExpiration });
        }
    },

    /**
     * If our JWT expires in less than X minutes, call the `/refresh/` endpoint
     */
    refreshTokenIfNeeded: async () => {
        const minutesUntilTokenExpiration = getMinutesUntilTokenExpiration();

        // temporary debugging
        console.log({ minutesUntilTokenExpiration });

        if (minutesUntilTokenExpiration > TOKEN_REFRESH_BUFFER_IN_MINUTES) {
            // our token's expiry still has plenty of time left, so bail
            return;
        }

        await api.refreshToken();
    },

    /**
     * Search method for filter searches
     */
    searchAssets: params => api.fetchLegacy('/ajax/asset/search.php', params),

    /**
     * Fetches a web form
     */
    fetchForm: params => api.fetchLegacy('/components/adex/form/ajax/get_form.php', params, 'get'),

    /**
     * Fetches saved searches from legacy backend
     */
    fetchSavedSearches: () =>
        // TODO: Make new endpoint that returns only savedSearches
        api.fetchLegacy('/ajax/config/get_config.php'),
    /**
     * Fetches customer config
     */
    fetchCustomerConfig: () =>
        // TODO: Make new endpoint that returns only customerConfig
        api.fetchLegacy('/ajax/config/get_config.php'),
    /**
     * Creates hardlink to a file for import in premier
     */
    createHardLink: assetId =>
        api._doGet(`${LEGACY_MEDIABANK_ENDPOINT}/ajax/premier/create_hardlink.php`, {
            assetid: assetId,
        }),

    createAsset: (title, metadata) => {
        // return Promise.resolve({ data: { data: { assetid: 666 } } });
        return mapApiFetch('post', '/asset/', { title, metadata: JSON.stringify(metadata) });

        /**
         * Executes a request against the "MAP_ENDPOINT" / map API
         *
         * NOTE: `mapApiFetch` was previously a shared method that is now private to `createAsset`
         *        once we can safely test `createAsset`, then this helper can be removed
         *        https://github.com/nepworldwide/obelix/pull/287/
         */
        function mapApiFetch(type = 'get', url, params = {}, headers = {}) {
            let promise;

            if (type === 'post') {
                promise = api._doPost(`${MAP_ENDPOINT}${url}`, params, headers);
            } else {
                promise = api._doGet(`${MAP_ENDPOINT}${url}`, params, headers);
            }

            promise.catch(err => {
                const { status } = err;

                console.error('mapApiFetch', { status });

                if (status === 401) {
                    clearTokenAndReload();
                }
            });

            return promise;
        }
    },

    /**
     * calls the JWT endpoint with a `token` request header
     * gets a `200` if it's valid, otherwise a `401`
     *
     * @param {string} token
     * @returns {bool} `true` if our API says it's valid, otherwise `false`
     */
    validateToken: async token => {
        if (!token || typeof token !== 'string') {
            return false;
        }

        try {
            const res = await tokenService.validate({ token });

            if (res.status === 200) {
                return true;
            }
        } catch (err) {
            handleError(err);
        }

        return false;
    },

    /**
     * Fetches metavalues for the given key
     * @param {} key
     */
    fetchMetaValues(key) {
        const params = {
            application: 'library',
            key,
            action: 'values',
        };

        return api.fetchLegacy('/ajax/asset/get_metavalues.php', params, 'get');
    },

    /**
     * Return requests headers
     *
     * @returns {object} a "headers" object whose keys map to HTTP request headers
     */
    _getRequestHeaders() {
        const tokenStr = getTokenFromLocalStorage();

        const headers = {};

        if (tokenStr) {
            const decodedTokenObj = decodeToken(tokenStr);

            if (decodedTokenObj.apiToken) {
                headers['map-api-token'] = decodedTokenObj.apiToken;
                headers.authorization = `Bearer ${tokenStr}`;
            }
        }

        return headers;
    },

    /**
     * "Private" helper function to make a POST request
     * Don't call this method outside of this file!
     *
     * @param {*} url
     * @param {*} params
     * @param {*} additionalHeaders
     * @param {*} postAsFormData
     */
    async _doPost(url, params = {}, additionalHeaders = {}, postAsFormData = true) {
        const additionalHeadersClone = { ...additionalHeaders };

        let urlParams = params;

        if (postAsFormData) {
            // Make axios post as Form Data with content-type application/x-www-form-urlencoded
            urlParams = new URLSearchParams();

            Object.keys(params).forEach(param => {
                if (Array.isArray(params[param])) {
                    params[param].forEach(val => urlParams.append(`${param}[]`, val));
                } else {
                    urlParams.append(param, params[param]);
                }
            });
        } else {
            additionalHeadersClone['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
        }

        const axiosConfig = {
            headers: {
                ...api._getRequestHeaders(),
                ...additionalHeadersClone,
            },
        };

        try {
            const response = await axiosInstance.post(url, urlParams, axiosConfig);

            return response;
        } catch (err) {
            handleError(err);
        }
    },

    /**
     * "Private" helper function to make a GET request
     * Don't call this method method outside of this file!
     *
     * @param {String} url
     * @param {Object} params
     */
    async _doGet(url, params = {}, additionalHeaders = {}) {
        const axiosConfig = {
            params,
            headers: {
                ...api._getRequestHeaders(),
                ...additionalHeaders,
            },
        };

        try {
            const response = await axiosInstance.get(url, axiosConfig);

            return response;
        } catch (err) {
            handleError(err);
        }
    },

    /**
     * Makes a request to legacy endpoints
     * @param {String} url
     * @param {Object} params
     * @param {String} type
     */
    async fetchLegacy(url, params, type = 'post') {
        let response;

        if (type === 'post') {
            response = await api._doPost(`${LEGACY_MEDIABANK_ENDPOINT}${url}`, params);
        } else {
            response = await api._doGet(`${LEGACY_MEDIABANK_ENDPOINT}${url}`, params);
        }

        const { data = {} } = response || {};

        if (data.status === 'OK') {
            // get legacy data from axios, yes it's correct with data.data axios format + mediabank format
            return data.data;
            // Update this when we get a 40X status for non authorized requests
        }

        // what our API responds with, even with a `200 / OK` response!
        const KNOWN_RESPONSE = 'Not logged in or session timed out';

        if (data.error === KNOWN_RESPONSE) {
            const { status, statusText } = response;

            console.error({ KNOWN_RESPONSE, status, statusText });
        }

        return false;
    },
};

export default api;
