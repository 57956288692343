import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../api/index';
import { MainLayout } from '../components/MainLayout';
import { ProtectedRoute } from '../components/ProtectedRoute';

// because we reload the browser when our user signs out of the app,
// we don't have to worry about the refresh call continuing to happen
// when the app is on the login screen
let intervalId;

const ProtectedRouteContainer = ({ ...routeProps }) => {
    useEffect(() => {
        // check our token every 5 minutes
        // and make a "refresh" request if needed
        const INTERVAL_MILLIS = 5 * 60 * 1000;

        if (intervalId) {
            clearInterval(intervalId);
        }

        intervalId = setInterval(() => {
            api.refreshTokenIfNeeded();
        }, INTERVAL_MILLIS);
    }, []);

    const error = useSelector(state => state.user.error);
    const token = useSelector(state => state.user.token);
    const tokenIsValidated = useSelector(state => state.user.tokenIsValidated);

    const isAuthenticated = () => !!token && tokenIsValidated;

    return (
        <>
            <MainLayout>
                <ProtectedRoute
                    {...routeProps}
                    error={error}
                    isAuthenticated={isAuthenticated}
                    token={token}
                    tokenIsValidated={tokenIsValidated}
                />
            </MainLayout>
        </>
    );
};

export { ProtectedRouteContainer };
