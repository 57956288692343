import { makeStyles } from '@material-ui/core';

export const usePanelLoadingStyles = makeStyles(() => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: ({ padding }) => padding,
    },
}));

export const useContentLoadingStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export const useCircleLoadingStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    top: {
        color: theme.custom.accentLight[0.05],
    },
    bottom: {
        color: theme.palette.primary.light,
        animationDuration: '1s',
        position: 'absolute',
    },
}));

export const usePlayerLoadingStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loading: {
        paddingLeft: theme.spacing(1.5),
    },
    image: {
        width: 108 / 2,
        height: 110 / 2,
        transform: 'rotate(0deg)',
        animation: '$rotate 1.6s linear infinite',
    },
    '@keyframes rotate': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}));

export const usePlaceholderLoadingStyles = makeStyles(theme => ({
    placeholder: {
        backgroundColor: theme.custom.accentDark[0.6],
        opacity: 0.1,
        height: ({ height }) => theme.spacing(height || 0),
        width: ({ width }) => (isNaN(width) ? width : theme.spacing(width)),
        borderRadius: ({ borderRadius }) => theme.spacing(borderRadius || 0),
        animation: '$pulse 1.6s ease-in-out infinite',
    },
    '@keyframes pulse': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 0.6,
        },
        '100%': {
            opacity: 0,
        },
    },
}));

export const useCubeStyles = makeStyles(theme => ({
    scene: {
        display: 'inline-block',
        width: ({ size }) => size,
        height: ({ size }) => size,
        perspective: ({ size }) => size * 5,
    },
    cube: {
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        transformStyle: 'preserve-3d',
        transition: 'transform 6s',
        animation: '$rotateCube 6s infinite',
        animationDelay: ({ delay }) => delay,
    },
    cubeFace: {
        color: 'white',
        width: 'inherit',
        height: 'inherit',
        position: 'absolute',
        border: `1px solid ${theme.custom.accentLight[0.8]}`,
        borderRadius: ({ size }) => size / 8,
    },
    cubeFaceFront: {
        transform: ({ size }) => `translate3d(0, 0, ${size / 2}px)`,
    },
    cubeFaceBack: {
        transform: ({ size }) => `rotateY(180deg) translate3d(0, 0, ${size / 2}px)`,
    },
    cubeFaceLeft: {
        transform: ({ size }) => `rotateY(-90deg) translate3d(0, 0, ${size / 2}px)`,
    },
    cubeFaceRight: {
        transform: ({ size }) => `rotateY(90deg) translate3d(0, 0, ${size / 2}px)`,
    },
    cubeFaceTop: {
        transform: ({ size }) => `rotateX(90deg) translate3d(0, 0, ${size / 2}px)`,
    },
    cubeFaceBottom: {
        transform: ({ size }) => `rotateX(-90deg) translate3d(0, 0, ${size / 2}px)`,
    },

    '@keyframes rotateCube': {
        '0%': {},
        '17%': {
            transform: 'rotateX(-90deg)',
        },
        '34%': {
            transform: 'rotateX(-90deg) rotateZ(90deg)',
        },
        '51%': {
            transform: 'rotateX(-90deg) rotateZ(90deg) rotateY(90deg)',
        },
        '68%': {
            transform: 'rotateX(-90deg) rotateZ(180deg) rotateY(90deg)',
        },
        '85%': {
            transform: 'rotateX(-180deg) rotateZ(180deg) rotateY(90deg)',
        },
        '100%': {
            transform: 'rotateX(-180deg) rotateZ(180deg) rotateY(180deg)',
        },
    },
}));
