import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Something_Wrong } from '../../messages';

const ErrorHandler = ({ children }) => {
    const [errorOccurred] = useState(false);

    try {
        // TODO implement any error handling we would like to add
    } catch (error) {
        // Disabling this until we have a proper way of showing the errors
        // setErrorOccurred(true)
        // logErrorToMyService(error, info)
    }

    return (
        <>
            {errorOccurred && <h1>{Something_Wrong}</h1>}
            {children}
        </>
    );
};

ErrorHandler.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { ErrorHandler };
