import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
// this is the one and only place where we should test the `<ThemeProvider>` in snapshot tests
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles, NotificationsProvider } from '@mediabank/uikit';

import { AppRoutes } from './routes/AppRoutes';
import store from './store';
// if changes are made to the `uikit` theme, they should show up in our snapshot diffs
import theme from './theme';

// We want to add extra margin bottom for the notifications
// container element so they don't overlap the footer
const useStyles = makeStyles(() => ({
    containerRoot: {
        marginBottom: 70,
    },
}));

const App = () => {
    const classes = useStyles();

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <NotificationsProvider customClasses={classes}>
                        <AppRoutes />
                    </NotificationsProvider>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
};

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store;
}

export { App };
