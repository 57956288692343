import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@material-ui/core';
import { ContentLoading, makeStyles, SearchBox } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { QuickFilterList, QuickFilterPopper } from '../components/QuickFilter';
import { resetSearchAssets, setActivePage, setSearchAssets } from '../store/actions/ui';
import { getSearchBoxInputValue } from '../store/selectors';
import { searchAssets, searchAssetsByFilters } from '../store/thunks/assetList';

const useStyles = makeStyles(theme => ({
    divider: {
        margin: theme.spacing(0.25, 2, 0.25, 2),
    },
    focused: {
        borderColor: theme.custom.accentBright[1],
    },
    pillsContainer: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '4px',
        },
        position: 'relative',
        paddingRight: theme.spacing(3.5),
    },
}));

const SearchBoxContainer = ({ scrollableContainerRef }) => {
    const classes = useStyles();

    const [pillsWidth, setPillsWidth] = useState(0);

    const activeSearchType = useSelector(state => state.ui.searchAssets.activeSearchType);
    const isQuickFiltersLoading = useSelector(state => state.config.isQuickFiltersLoading);
    const quickFilters = useSelector(state => state.config.quickFilters);
    const searchTextValue = useSelector(state => state.ui.searchAssets.searchTypes.keywordSearch);

    const searchBoxInputValue = useSelector(getSearchBoxInputValue);

    const dispatch = useDispatch();

    useEffect(() => {
        if (activeSearchType === 'savedSearch') {
            dispatch(searchAssets({ keyword: searchTextValue, shouldReset: true }));
            dispatch(
                setSearchAssets({
                    key: 'keywordSearch',
                    value: searchTextValue,
                })
            );
        }
    }, [activeSearchType, dispatch, searchTextValue]);

    const handleSearch = searchText => {
        const params = {
            keyword: searchText,
            shouldReset: true,
        };

        dispatch(searchAssets(params));

        if (!searchText) {
            dispatch(searchAssets({ shouldReset: true }));
        }

        dispatch(
            setSearchAssets({
                key: 'keywordSearch',
                value: searchText,
            })
        );

        if (scrollableContainerRef.current) {
            // we can safely disable this eslint rule here, since we want to
            // work with the scroll position of an element
            // eslint-disable-next-line no-param-reassign
            scrollableContainerRef.current.scrollTop = 0;
        }

        // to test Sentry error handling, enter any combination of
        // "Sentry", "error", & ("test"|"check") into the search box
        const searchTextLowerCased = searchText.toLowerCase();
        if (
            searchTextLowerCased.includes('sentry') &&
            searchTextLowerCased.includes('error') &&
            (searchTextLowerCased.includes('test') || searchTextLowerCased.includes('check'))
        ) {
            console.log(window.foo['This is a test error message for Sentry']);
        }
    };

    const activateFilter = (filter, isActive) => {
        dispatch(setActivePage(1));

        if (isActive) {
            dispatch(searchAssets({ shouldReset: true }));
            dispatch(resetSearchAssets());
        } else {
            dispatch(searchAssetsByFilters({ attributes: filter.attributes, shouldReset: true }));
            dispatch(
                setSearchAssets({
                    key: 'quickFilter',
                    value: filter.id,
                })
            );
        }

        // we can safely disable this eslint rule here, since we want to
        // work with the scroll position of an element
        // eslint-disable-next-line no-param-reassign
        scrollableContainerRef.current.scrollTop = 0;
    };

    return (
        <>
            <SearchBox
                customClasses={{ focused: classes.focused }}
                placeholder="Search Mediabank"
                value={searchBoxInputValue}
                onSearch={handleSearch}
            />
            {isQuickFiltersLoading && <ContentLoading />}
            {quickFilters.length > 0 && (
                <>
                    <Divider
                        className={classes.divider}
                        data-testid="quickfilters-divider"
                        flexItem={true}
                        light={true}
                        orientation="vertical"
                    />
                    <Box className={classes.pillsContainer} data-testid="quickfilters-container">
                        <QuickFilterList
                            activateFilter={activateFilter}
                            pillsWidth={pillsWidth}
                            quickFilters={quickFilters}
                            setPillsWidth={setPillsWidth}
                        />
                        {quickFilters.length > 1 && (
                            <QuickFilterPopper activateFilter={activateFilter} quickFilters={quickFilters} />
                        )}
                    </Box>
                </>
            )}
        </>
    );
};

SearchBoxContainer.propTypes = {
    scrollableContainerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

SearchBoxContainer.defaultProps = {
    scrollableContainerRef: () => null,
};

export { SearchBoxContainer };
