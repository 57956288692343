import * as Sentry from '@sentry/react';

/**
 * `tagsDictionary` is an optional collection of key-value pairs
 *                  for tags that are known at the time `sentryInit` is called
 *                  ideally, on app startup
 */
const sentryInit = ({ dsn, environment, release, tagsDictionary = {} }) => {
    // exit early if the Sentry `Data Source Name` is not available
    if (!dsn || !dsn.startsWith('https://')) {
        return;
    }

    Sentry.init({
        dsn,
        environment,
        release,
    });

    if (Object.keys(tagsDictionary).length) {
        Sentry.setTags({
            ...tagsDictionary,
        });
    }
};

const setUserInformationsForSentry = ({ company_id, company_name, userid }) => {
    Sentry.setUser({ id: userid });

    Sentry.setTags({
        'company.name': company_name,
        'company.id': company_id,
    });
};

export { Sentry, sentryInit, setUserInformationsForSentry };
