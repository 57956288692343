import { createReducer } from '@reduxjs/toolkit';

import {
    fetchCustomerConfigFailed,
    fetchCustomerConfigLoading,
    fetchCustomerConfigSucceeded,
    fetchQuickFiltersFailed,
    fetchQuickFiltersLoading,
    fetchQuickFiltersSucceeded,
    fetchSavedSearchesFailed,
    fetchSavedSearchesLoading,
    fetchSavedSearchesSucceeded,
} from '../actions/config';

export const initialState = {
    customerConfig: {},
    customerConfigError: null,
    isCustomerConfigLoading: true,

    savedSearches: [],
    savedSearchesError: null,
    isSavedSearchesLoading: true,

    quickFilters: [],
    quickFiltersError: null,
    isQuickFiltersLoading: true,
};

const config = createReducer(initialState, {
    [fetchCustomerConfigFailed]: (state, { payload }) => {
        state.customerConfigError = payload;
    },
    [fetchCustomerConfigLoading]: (state = initialState, { payload }) => {
        state.isCustomerConfigLoading = payload;
    },
    [fetchCustomerConfigSucceeded]: (state, { payload }) => {
        state.customerConfig = payload;
    },

    [fetchSavedSearchesFailed]: (state, { payload }) => {
        state.savedSearchesError = payload;
    },
    [fetchSavedSearchesLoading]: (state = initialState, { payload }) => {
        state.isSavedSearchesLoading = payload;
    },
    [fetchSavedSearchesSucceeded]: (state, { payload }) => {
        const savedSearches = payload || [];

        state.savedSearches = savedSearches;
    },

    [fetchQuickFiltersFailed]: (state, { payload }) => {
        state.quickFiltersError = payload;
    },
    [fetchQuickFiltersLoading]: (state = initialState, { payload }) => {
        state.isQuickFiltersLoading = payload;
    },
    [fetchQuickFiltersSucceeded]: (state, { payload }) => {
        const quickFilters = payload || [];

        state.quickFilters = quickFilters;
    },
});

export { config };
