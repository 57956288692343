import httpClient from '../common/http';

let currentAuth = null;

export const setAuth = (username, password) => {
    currentAuth = { username: username, password: password };
};

const baseUrl = process.env.FILESERVICE_API_URL;

const api = new httpClient(baseUrl, 8000);
const fileServiceClient = {
    fileExists: async ({ filePath }) => {
        const res = await api.get('/file/fileexists', {
            params: {
                file_path: filePath,
                // not sending these params isn't an option...
                fail_if_exists: '',
                fail_if_not_exists: '',
            },
            auth: currentAuth,
        });
        return res.data.output.exists;
    },
    hardlink: async ({ source, destination, overwrite = false }) =>
        await api.post('/file/hardlink', {
            data: {
                source_file_path: source,
                destination_file_path: destination,
                allow_overwrite: overwrite,
            },
            auth: currentAuth,
        }),
};

export default fileServiceClient;
