import Checkbox from './Checkbox';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import CssBaseline from './CssBaseline';
import IconButton from './IconButton';
import Snackbar from './Snackbar';
import Alert from './Alert';
import FormControlLabel from './FormControlLabel';
import Divider from './Divider';
import Slider from './Slider';
import OutlinedInput from './OutlinedInput';
import PickersCalendarHeader from './PickersCalendarHeader';

const overrides = {
    Checkbox,
    Button,
    ButtonGroup,
    CssBaseline,
    IconButton,
    Snackbar,
    Alert,
    FormControlLabel,
    Divider,
    Slider,
    OutlinedInput,
    PickersCalendarHeader,
};

export default (theme, colors) => ({
    overrides: {
        ...Object.entries(overrides).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [`Mui${key}`]: value(theme, colors),
            }),
            {}
        ),
    },
});
