import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { NoAssetsFound, SearchResultHeader, SearchResultList } from '../components/SearchResult';
import { TABLE_DISPLAY_TYPES } from '../constants';
import { setSelectedAssetsById, toggleSelectedAsset } from '../store/actions/assetList';
import { getActiveSearchData } from '../store/selectors';
import { searchAssets, searchAssetsByFilters, searchAssetsBySmartSearchId } from '../store/thunks/assetList';

const useStyles = makeStyles(theme => ({
    footerButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'var(--footerHeight)',
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
    },
    footerButtonSpacing: {
        marginLeft: theme.spacing(1),
    },
}));

const SearchResultContainer = ({ importSelectedAssets, scrollableContainerRef, searchTypeContentHeight }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const activeSearchType = useSelector(state => state.ui.searchAssets.activeSearchType);
    const activeSearchData = useSelector(getActiveSearchData);
    const assetActivePage = useSelector(state => state.assetList.assetActivePage);
    const assetCount = useSelector(state => state.assetList.assetCount);
    const isMoreAssetsAvailable = useSelector(state => state.assetList.isMoreAssetsAvailable);
    const isSearchAssetsLoading = useSelector(state => state.assetList.isSearchAssetsLoading);
    const nrOfAssets = useSelector(state => state.assetList.assets.length);
    const searchText = useSelector(state => state.ui.searchAssets.searchTypes.keywordSearch);
    const selectedAssetsById = useSelector(state => state.assetList.selectedAssetsById);

    const [displayType, setDisplayType] = useState(TABLE_DISPLAY_TYPES.thumbnail);

    const handleToggleSelectedAsset = assetId => {
        dispatch(toggleSelectedAsset(assetId));
    };

    const onVisibilityChange = isVisible => {
        const shouldDispatch = isVisible && isMoreAssetsAvailable && !isSearchAssetsLoading;

        if (!shouldDispatch) {
            return;
        }

        switch (activeSearchType) {
            case 'quickFilter':
                dispatch(
                    searchAssetsByFilters({
                        attributes: activeSearchData.attributes,
                        page: assetActivePage + 1,
                    })
                );
                break;

            case 'savedSearch':
                dispatch(
                    searchAssetsBySmartSearchId({
                        smartSearchId: activeSearchData.smartsearchid,
                        page: assetActivePage + 1,
                    })
                );
                break;
            // keyword search is the default
            default:
                dispatch(
                    searchAssets({
                        keyword: searchText,
                        page: assetActivePage + 1,
                    })
                );
                break;
        }
    };

    const onDisplayTypeChange = displayType => {
        if (scrollableContainerRef.current) {
            // we can safely disable this eslint rule here, since we want to
            // work with the scroll position of an element
            // eslint-disable-next-line no-param-reassign
            scrollableContainerRef.current.scrollTop = 0;
        }

        setDisplayType(displayType);
    };

    if (assetCount === 0 && !isSearchAssetsLoading) {
        return <NoAssetsFound />;
    }

    const clearSelectedItems = () => {
        dispatch(setSelectedAssetsById({}));
    };

    const numberOfSelectedAssets = Object.keys(selectedAssetsById).length;
    const shouldButtonsBeDisabled = numberOfSelectedAssets === 0;

    // "Import Selected" or "Import (3) Selected"
    const importButtonText = `Import ${numberOfSelectedAssets ? `(${numberOfSelectedAssets})` : ''} Selected`;

    return (
        <>
            <SearchResultHeader
                assetCount={assetCount}
                displayType={displayType}
                isLoading={isSearchAssetsLoading}
                nrOfAssets={nrOfAssets}
                onDisplayTypeChange={displayType => onDisplayTypeChange(displayType)}
            />
            <SearchResultList
                displayType={displayType}
                importSelectedAssets={importSelectedAssets}
                scrollableContainerRef={scrollableContainerRef}
                searchTypeContentHeight={searchTypeContentHeight}
                toggleSelectedAsset={handleToggleSelectedAsset}
                onVisibilityChange={onVisibilityChange}
            />
            <Box className={classes.footerButtons} data-testid="footerButtons">
                <Button
                    color="default"
                    data-testid="ClearSelectedButton"
                    disabled={shouldButtonsBeDisabled}
                    size="large"
                    variant="contained"
                    onClick={clearSelectedItems}
                >
                    Clear Selected
                </Button>
                <Button
                    className={classes.footerButtonSpacing}
                    color="primary"
                    data-testid="ImportSelectedButton"
                    disabled={shouldButtonsBeDisabled}
                    size="large"
                    variant="contained"
                    onClick={importSelectedAssets}
                >
                    {importButtonText}
                </Button>
            </Box>
        </>
    );
};

SearchResultContainer.propTypes = {
    importSelectedAssets: PropTypes.func.isRequired,
    scrollableContainerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    searchTypeContentHeight: PropTypes.number,
};

SearchResultContainer.defaultProps = {
    scrollableContainerRef: () => null,
    searchTypeContentHeight: 0,
};

export { SearchResultContainer };
