import { createAction } from '@reduxjs/toolkit';

// TOKEN
export const setToken = createAction('USER/TOKEN/SET');
export const setTokenIsValidated = createAction('USER/TOKENISVALIDATED/SET');

// LOGIN
export const userLoginFailed = createAction('USER/LOGIN/FAILED');
export const userLoginLoading = createAction('USER/LOGIN/LOADING');
export const userLoginSucceeded = createAction('USER/LOGIN/SUCCEEDED');

// LOGOUT
export const userLogoutSucceeded = createAction('USER/LOGOUT/SUCCEEDED');

export const logout = () => dispatch => {
    dispatch(userLogoutSucceeded);
};
