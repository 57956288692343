import { createAction } from '@reduxjs/toolkit';

// SET
export const setActiveForm = createAction('UI/ACTIVE_FORM/SET');
export const setActivePage = createAction('UI/ACTIVE_PAGE/SET');

export const setIsFetchingForm = createAction('UI/IS_FETCHING_FORM/SET');

export const setSearchAssets = createAction('UI/SEARCH_ASSETS/SET');
export const resetSearchAssets = createAction('UI/SEARCH_ASSETS/RESET');
