import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ContentLoading, makeStyles, NotificationsContext } from '@mediabank/uikit';
import { setUserInformationsForSentry } from '@mediabank/utils';

import api from '../api';
import { AppInfo } from '../components/AppInfo';
import { LoginForm } from '../components/LoginForm';
import {
    CEP_EXT_VERSION,
    isSupportedExtensionVersion,
    PARTNER_LOGIN_URL,
    SENTRY_IS_ACTIVE,
    SSO_VENDORS_URL,
} from '../constants';
import { setTokenIsValidated } from '../store/actions/user';
import { loginThunk, tryAutoLoginThunk } from '../store/thunks/user';
import { clearTokenAndReload, getTokenFromLocalStorage, triggerSetTokenEvent } from '../utils/jwt';

const AFTER_LOGIN_REDIRECT = '/search';

/**
 * Replace "ORIGIN" in the PARTNER_LOGIN_URL template.
 *
 * The partner sign-in link has a `redirect_to` query-string
 * that tells the SSO service where to send the user after logging in.
 *
 * We want them to come back here, but "here" can be different depending
 * on the environment (staging, production, et cetera).
 */
export const partnerLoginUrl = PARTNER_LOGIN_URL.replace('{ORIGIN}', window.location.origin);

const useStyles = makeStyles(theme => ({
    loginPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(4),
        minHeight: '450px',
        minWidth: '400px',
    },
}));

const LoginFormContainer = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const error = useSelector(state => state.user.error);

    // We need the token from redux store, to check if we need to show a loader
    const hasTokenInRedux = useSelector(state => !!state.user.token);

    const userProfile = useSelector(state => state.user.profile);

    const isUserLoginLoading = useSelector(state => state.user.isUserLoginLoading);

    const [showError, setShowError] = useState(error !== null);

    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [sentryIsUpdated, setSentryIsUpdated] = useState(false);
    const [vendors, setVendors] = useState([]);

    const disableBtnSubmit = password === '' || userName === '' || isUserLoginLoading || !isSupportedExtensionVersion;

    const { addToastError } = useContext(NotificationsContext);

    useEffect(() => {
        const fetchVendors = async () => {
            const response = await fetch(SSO_VENDORS_URL);

            if (response.status === 200) {
                const data = await response.json();
                setVendors(data?.filter(item => item.premierPanel));
            } else {
                addToastError({
                    persist: false,
                    title: 'Fetch error',
                    subtitle: 'Could not fetch partner list',
                });
            }
        };
        fetchVendors();
    }, [addToastError]);

    useEffect(() => {
        const tryAutoLogin = () => dispatch(tryAutoLoginThunk());

        window.addEventListener('SET:TOKEN', tryAutoLogin);
    }, [dispatch]);

    useEffect(() => {
        if (isSupportedExtensionVersion) {
            return;
        }
        // else, the user has an unsupported version of the extension

        const minimumExtensionVersion = '2.0';

        addToastError({
            persist: true,
            title: 'Login disabled',
            subtitle: `Extension Version ${CEP_EXT_VERSION} is no longer supported, update to Version ${minimumExtensionVersion} or higher required.`,
        });
    }, [addToastError]);

    useEffect(() => {
        async function validateToken() {
            const token = getTokenFromLocalStorage();

            if (token) {
                triggerSetTokenEvent(token);

                try {
                    // We have a token but it needs validating before it can be used
                    const isTokenValid = !!(await api.validateToken(token));

                    if (isTokenValid) {
                        dispatch(setTokenIsValidated(true));
                        history.push(AFTER_LOGIN_REDIRECT);
                    } else {
                        clearTokenAndReload();
                    }
                } catch (err) {
                    if (err.status === 401) {
                        clearTokenAndReload();
                    }
                    // NOTE: we're silently swallowing
                    //       non-401 errors here!
                }
            }
        }
        validateToken();
    }, [dispatch, history]);

    const handleSubmit = async () => {
        try {
            // this rename is because of the payload we need to send
            // in our `api.login` POST request to the AUTH_ENDPOINT
            const email = userName;

            const { payload: token } = await dispatch(loginThunk({ email, password }));

            if (token) {
                history.push(AFTER_LOGIN_REDIRECT);
            } else {
                setShowError(true);
                setPassword('');
            }
        } catch (error) {
            console.error('caught error in login comp', error);
        }
    };

    // Setting Sentry user informations
    if (SENTRY_IS_ACTIVE && userProfile && !sentryIsUpdated) {
        const { company_id, company_name, userid } = userProfile;

        setUserInformationsForSentry({ company_id, company_name, userid });

        // set the flag that prevents us from
        // calling the above function more than once
        setSentryIsUpdated(true);
    }

    if (isUserLoginLoading && hasTokenInRedux) {
        return (
            <Box display="flex" height="100vh" overflow="hidden">
                <ContentLoading />
            </Box>
        );
    }

    return (
        <Box className={classes.loginPageContainer}>
            <LoginForm
                disableBtnPartner={!isSupportedExtensionVersion}
                disableBtnSubmit={disableBtnSubmit}
                error={error || ''}
                isUserLoginLoading={isUserLoginLoading}
                partnerLoginUrl={partnerLoginUrl}
                password={password}
                setPassword={setPassword}
                setShowError={setShowError}
                setUserName={setUserName}
                showError={showError}
                userName={userName}
                vendors={vendors}
                onSubmit={handleSubmit}
            />
            <AppInfo />
        </Box>
    );
};

const LoginFormContainerWithRouter = withRouter(LoginFormContainer);

export { LoginFormContainerWithRouter };
