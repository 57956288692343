import React from 'react';
import { Box, Input, Typography } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from '@mediabank/assets/icons/error-circle.svg';
import { filterDataSet } from '@mediabank/utils';
import PropTypes from 'prop-types';

import { useInputFieldStyles, useInputStyles } from './style';

const InputField = ({
    multiline,
    onChange,
    type,
    value,
    placeholder,
    disabled,
    label,
    variant,
    fullWidth,
    required,
    helperText,
    error,
    ...rest
}) => {
    const inputClasses = useInputStyles({ isFilled: variant === 'filled' });
    const inputFieldClasses = useInputFieldStyles({ disabled });
    const onInputChange = event => {
        onChange && onChange(event.target.value);
    };
    const { dataSet, nonDataSetProps } = filterDataSet(rest);
    const displayHelperText = helperText || (required && error && 'Required field');

    return (
        <>
            <div className={inputFieldClasses.container}>
                {label && (
                    <Box alignItems="center" display="flex" pb={fullWidth ? 1 : 0} pr={1}>
                        <Typography variant="body2">{label}</Typography>
                        <span className={inputFieldClasses.requireIndicator}>{required ? '*' : ''}</span>
                    </Box>
                )}
                <Input
                    classes={inputClasses}
                    disabled={disabled}
                    disableUnderline={true}
                    error={error}
                    fullWidth={fullWidth}
                    multiline={multiline}
                    placeholder={placeholder}
                    required={required}
                    type={type}
                    value={value}
                    onChange={onInputChange}
                    {...nonDataSetProps}
                    {...dataSet}
                />
            </div>
            {displayHelperText && (
                <div className={inputFieldClasses.helperTextRow}>
                    {error && <ErrorIcon />}

                    <Typography className={inputFieldClasses.helperText} variant="body2">
                        {displayHelperText}
                    </Typography>
                </div>
            )}
        </>
    );
};

InputField.propTypes = {
    type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
    onChange: PropTypes.func,
    multiline: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    error: PropTypes.bool,
};

InputField.defaultProps = {
    type: 'text',
    onChange: () => null,
    multiline: false,
    value: undefined,
    placeholder: 'Enter...',
    disabled: false,
    label: '',
    variant: 'hollow',
    fullWidth: undefined,
    required: undefined,
    helperText: undefined,
    error: undefined,
};

export default InputField;
