import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';

import { useCubeStyles } from './styles';

const Cube = ({ size, delay }) => {
    const classes = useCubeStyles({ size, delay });

    return (
        <div className={classes.scene}>
            <div className={classes.cube}>
                <div className={cx(classes.cubeFace, classes.cubeFaceFront)} />
                <div className={cx(classes.cubeFace, classes.cubeFaceBack)} />
                <div className={cx(classes.cubeFace, classes.cubeFaceLeft)} />
                <div className={cx(classes.cubeFace, classes.cubeFaceRight)} />
                <div className={cx(classes.cubeFace, classes.cubeFaceTop)} />
                <div className={cx(classes.cubeFace, classes.cubeFaceBottom)} />
            </div>
        </div>
    );
};

Cube.propTypes = {
    size: PropTypes.number,
    delay: PropTypes.number,
};

Cube.defaultProps = {
    size: 20,
    delay: 0,
};

export default Cube;
