/**
 * There's a chance that we made a request with an expired token.
 * This can happen when a user logs out of their account in another browser.
 *
 * On page refresh, they might be able can make it past the login in screen
 * with the recently-expired token.  But, calls to the search APIs will fail.
 *
 * This function will check to see if we should force the user to log in again
 * if any requests fail with a status code 401 - "Unauthorized"
 */
const checkShouldForceLogout = axiosError => {
    const { message = '', response } = axiosError || {};

    if (response && response.status === 401) {
        // Request failed with status code 401
        console.log('checkShouldForceLogout', { message });

        return true;
    }

    return false;
};

export { checkShouldForceLogout };
